import Axios from 'axios';

const initialiseInterceptor = () => {
    if (typeof window !== 'undefined') {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage?.getItem('access_token')}`;
        Axios.interceptors.request.use(
            (config) => {
                return config;
            },
            (error) => {
                return Promise.reject(error);
            },
        );

        Axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                console.log("error",error)

                if (error.response && (error.response.status == 401 || error.response.status == 403)) {
                    localStorage?.removeItem('access_token');

                    if (window.location.pathname !== '/login') {
                        window.location.replace('/login');
                    }
                    return Promise.reject(error);
                } else {
                    return Promise.reject(error);
                }
            },
        );
    }
};

export default initialiseInterceptor;
