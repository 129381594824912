import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import services from '../../services';
import { toast } from 'react-toastify';

const Automation = ({ onHide, show, boardName, gmailUsers, editData, editId, getAllAutomation }) => {
    const [boardSelect, setBoardSelect] = useState('');
    const [boardColumnSelect, setBoardColumnSelect] = useState('');
    const [selectGmailUser, setSelectGmailUser] = useState('');

    const [selectedBoardColumn, setSelectedBoardColumn] = useState([]);

    useEffect(() => {
        if (boardSelect) {
            getSELECTED_BOARD(boardSelect);
        }
    }, [boardSelect]);
    const getSELECTED_BOARD = async (boardSelect) => {
        try {
            const { data } = await services.board.SELECTED_BOARD(boardSelect);
            setSelectedBoardColumn(data);
            setBoardColumnSelect('');
        } catch {
            console.error(e);
        }
    };

    const handleCreate = async () => {
        const data = {
            when: { gmailUserId: selectGmailUser },
            condition: { boardListId: boardColumnSelect, boardId: boardSelect },
        };

        try {
            const response = !editData
                ? await services.automations.CREATE_AUTOMATIONS(data)
                : await services.automations.UPDATE_AUTOMATIONS(editId, data);
            console.log(response, 'createSave');
            editData && getAllAutomation();
            onHide();
        
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        console.log(JSON.parse(editData?.when), 'editData');
        setBoardSelect(editData?.boardId);
        setBoardColumnSelect(editData?.boardListId);

        const JsonParse = JSON.parse(editData?.when);

        setSelectGmailUser(JsonParse?.gmailUserId);
    }, [editData]);

    return (
        <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="text-2xl" style={{ fontWeight: 'bold' }}>
                    Automations
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="bg-gray-100">
                    <div className="container mx-auto p-4">
                        <div className="bg-white rounded-lg shadow-md p-6">
                            <h2 className="text-2xl font-bold mb-4">Create a new automation</h2>
                            <p className="text-gray-600 underline">Learn more about automations</p>
                            <hr />
                            <div className="grid grid-cols-1 gap-4 mt-10">
                                <div className="col-span-2" style={{ display: 'flex' }}>
                                    <label
                                        className="block mb-2 text-gray-700"
                                        for="when"
                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    >
                                        When
                                    </label>
                                    <select
                                        id="when"
                                        className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                        style={{ width: '40%' }}
                                    >
                                        <option value="item_completed">New Mail</option>
                                    </select>
                                </div>

                                <div className="grid grid-cols-3 gap-4">
                                    <div className="col-span-1 flex">
                                        <label
                                            className="block mb-2 text-gray-700"
                                            for="if"
                                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                        >
                                            If
                                        </label>

                                        <select
                                            id="if"
                                            className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                            onChange={(e) => setBoardSelect(e.target?.value, 'kklklklkl')}
                                            value={boardSelect}
                                        >
                                            <option value="" disabled>
                                                Select Board
                                            </option>
                                            {boardName &&
                                                boardName?.length &&
                                                boardName?.map((item) => (
                                                    <option key={item?.value} value={item?.value}>
                                                        {item?.label}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className="col-span-1 flex">
                                        <select
                                            id="is"
                                            className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                        >
                                            <option value="Is">Is</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>
                                    <div className="col-span-1">
                                        <select
                                            id="board"
                                            className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                            value={boardColumnSelect}
                                            onChange={(e) => setBoardColumnSelect(e.target.value)}
                                        >
                                            <option value="" disabled>
                                                Select BoardColumn
                                            </option>
                                            {selectedBoardColumn &&
                                                selectedBoardColumn?.length > 0 &&
                                                selectedBoardColumn?.map((item, i) => (
                                                    <option key={i} value={item?.id}>
                                                        {item?.coloum}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-span-2">
                                    <button className="  text-blue-500 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                        + Add Condition
                                    </button>
                                </div>
                                <div className="grid grid-cols-3 gap-4">
                                    <div className="col-span-1 flex">
                                        <label
                                            className="block mb-2 text-gray-700"
                                            for="then"
                                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                        >
                                            Then
                                        </label>
                                        <select
                                            id="then"
                                            className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                        >
                                            <option value="assign_to">assign it to</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>
                                    <div className="col-span-2 flex">
                                        <select
                                            id="option"
                                            className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                            value={selectGmailUser}
                                            onChange={(e) => setSelectGmailUser(e.target.value)}
                                        >
                                            <option value="option1" disabled>
                                                Select Gmail User
                                            </option>

                                            {gmailUsers &&
                                                gmailUsers?.length > 0 &&
                                                gmailUsers?.map((item) => (
                                                    <option value={item?.value}>{item?.label}</option>
                                                ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <button className="text-blue-500 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                        + Add Action
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="flex justify-end mt-6">
                    <button className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2">
                        Cancel
                    </button>
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={() => {
                            if (!boardSelect || !boardColumnSelect || !selectGmailUser) {
                                toast.error(
                                    !boardSelect
                                        ? 'Please Select Board'
                                        : !boardColumnSelect
                                        ? 'Please Select Board Column'
                                        : 'Please Select Gmail User',
                                );
                                return;
                            } else {
                                handleCreate();
                            }
                        }}
                    >
                        Create
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default Automation;
