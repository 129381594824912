import React from 'react';
import Editor from './Editor';
import './Template.css';

const TemplatePrev = ({ showModal, handleClose, selectedPrevData, handleEditorChange }) => {
    const handleBackdropClick = (e) => {
        if (e.target.classList.contains('modal')) {
            handleClose();
        }
    };

    return (
        <div>
            {showModal && (
                <div
                    className="modal fade show"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                    style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
                    onClick={handleBackdropClick}
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close custom-close"
                                    onClick={handleClose}
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Editor
                                    selectedPrevData={selectedPrevData}
                                    handleEditorChange={handleEditorChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TemplatePrev;
