import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import moment from 'moment';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const InboxData = ({ item, getTaskMailIndbox, onCheckboxClick, isShiftPressedMail, setIsShiftPressedMail }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isSelectedMail, setIsSelectedMail] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [isUnread, setIsUnread] = useState(item?.isUnread || false);
    const navigate = useNavigate();
    const [{ isDragging }, drag] = useDrag(
        {
            type: !isShiftPressedMail ? 'task' : 'childTask',
            item: !isShiftPressedMail
                ? { id: item?.gmailMessageId, gmailMessage: item?.gmailMessageId, isShiftPressed: isShiftPressedMail }
                : { isShiftPressed: isShiftPressedMail, item, taskForCreate: true },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        },
        [isShiftPressedMail],
    );
    const handleClick = async (event) => {
        if (
            event.target.closest('.bi.bi-trash') ||
            event.target.closest('.bi.bi-file-arrow-down') ||
            event.target.closest('.bi.bi-clock')
        ) {
            return;
        }

        setIsSelectedMail(!isSelectedMail);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/email/emailStatus/${item?.gmailMessageId}`,
            );
            setIsUnread(response?.data?.messageRead === 'unread');
        } catch (error) {
            console.error('Error fetching email status:', error);
        }
        updateStatusTask(item?.gmailMessageId, 'read');
        navigate('/mailinbox', { state: { selectedMail: item } });
    };

    const deleteTaskMail = async (id) => {
        const data = {
            taskstatus: 'inProgress',
        };
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/email/deletemail/${id}`, {
                data,
            });
            if (response) {
                getTaskMailIndbox();
            }
        } catch (error) {
            console.error('Error deleting task email:', error);
        }
    };

    const updateStatusTask = async (gmailMessageId, status) => {
        const data = {
            messageRead: status,
        };
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/email/updatedMessageRead/${gmailMessageId}`,
                data,
            );
            if (response) {
                getTaskMailIndbox();
            }
        } catch (error) {
            console.error('Error updating task status:', error);
        }
    };

    const handleCheckboxChange = () => {
        onCheckboxClick();
    };

    const handleContextMenu = (event) => {
        event.preventDefault();
        setPopupPosition({ x: event.clientX, y: event.clientY });
        setShowPopup(true);
    };

    const handlePopupClose = () => {
        setShowPopup(false);
    };

    const handleMarkAsUnread = () => {
        updateStatusTask(item?.gmailMessageId, 'unread');
        setIsUnread(true);
        handlePopupClose();
    };

    const handleMarkAsRead = () => {
        updateStatusTask(item?.gmailMessageId, 'read');
        setIsUnread(false);
        handlePopupClose();
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.popup')) {
                handlePopupClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="" ref={(node) => drag(node)} style={{ isDragging }}>
            <div
                onClick={(e) => handleClick(e)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onContextMenu={handleContextMenu}
                style={{
                    // borderLeft: '5px solid #1B72E8',
                    borderLeft: `5px solid ${item?.GmailUser?.colour}`,
                    backgroundColor: isHovered ? '#fafafa' : 'white',
                    opacity: isDragging ? 0.5 : 1,
                }}
                className={`max-w-sm p-[1rem]  shadow hover:bg-[#FAFAFA]  ${
                    item.messageRead == 'unread' ? 'active-bg' : '#fff'
                }`}
            >
                <div className="grid grid-cols-[8%_auto] items-start gap-1">
                    <div className="h-[26px]">
                        <div
                            onMouseEnter={() => setIsShiftPressedMail(true)}
                            onMouseLeave={() => setIsShiftPressedMail(false)}
                            data-no-popup
                            style={{ cursor: 'pointer' }}
                        >
                            <img src="assets/copy.svg" alt="copy" />
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                className="!shadow-none text-[15px]"
                                onChange={handleCheckboxChange}
                                onClick={(e) => e.stopPropagation()}
                            />
                        </div>
                    </div>
                    <div className="relative">
                        <div className="grid grid-cols-[70%_auto]  items-center text-black ">
                            <h2
                                className={`text-ellipse h-[26px] text-[13.6px] ${
                                    item.messageRead == 'unread' ? 'font-bold text-[13.6px]' : 'font-normal'
                                }`}
                            >
                                {item?.subject}
                            </h2>
                            <div className="h-[26px]">
                                {!isHovered && (
                                    <div className="flex justify-end items-center ">
                                        <span
                                            className={`date-time text-black ${
                                                item.messageRead == 'unread'
                                                    ? 'font-bold text-[11.56px]'
                                                    : 'font-normal'
                                            }`}
                                        >
                                            {item?.createdAt
                                                ? moment(item.createdAt).format('MMM DD, YYYY hh:mm A')
                                                : ''}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="grid grid-cols-[70%_auto]  items-center text-ellipsis !text-[13.6px]">
                            <h6 className={`text-black ${item.messageRead == 'unread' ? 'font-bold' : 'font-normal'}`}>
                                {item?.name}
                            </h6>
                            <div className="flex gap-[8px] justify-end items-center">
                                <div className="circle-user circle-none text-white">
                                    {item?.name?.charAt(0)?.toUpperCase() || ''}
                                </div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="12"
                                    fill="currentColor"
                                    className="bi bi-star"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showPopup && (
                <div
                    className="popup absolute z-10 p-1 bg-[#f5f5f5] border shadow-lg rounded-[4px] !text-[11.56px]"
                    style={{ top: popupPosition.y, left: popupPosition.x }}
                >
                    {item?.messageRead == 'unread' && (
                        <button onClick={handleMarkAsRead} className="block px-2 py-1 text-black">
                            Mark as Read
                        </button>
                    )}
                    {item?.messageRead == 'read' && (
                        <button onClick={handleMarkAsUnread} className="block px-2 py-1 text-black">
                            Mark as Unread
                        </button>
                    )}
                    <button onClick={() => deleteTaskMail(item?.id)} className="block px-2 py-1 text-red-500">
                        Delete
                    </button>
                </div>
            )}
        </div>
    );
};

InboxData.propTypes = {
    item: PropTypes.object.isRequired,
    getTaskMailInbox: PropTypes.func.isRequired,
    onCheckboxClick: PropTypes.func.isRequired,
    getTaskMailIndbox: PropTypes.func.isRequired,
};

export default InboxData;
