import React, { useEffect, useState } from 'react';
import Column from './Column.js';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { AddColumnCall, columnIndex } from '../Redux/slice.js';
import services from '../services/index.js';
import { columnsLocal } from '../utils/LocalSt.js';
import email from '../api/email.js';
import { GET_ALL_USER } from '../utils/Apifunction.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

function ColumnContainer() {
    const [statusUpdate, setStatusUpdate] = useState(true);
    const [taskDetailsPopup, setTaskDetailsPopup] = useState(false);
    const [taskMail, setTaskMail] = useState([]);
    const [taskAssign, setTaskAssign] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isShiftPressed, setIsShiftPressed] = useState(false);

    const dispatch = useDispatch();

    const conData = columnsLocal();
    const getTaskMail = async () => {
        const query = {
            limit: 400,
        };
        const obj = new URLSearchParams(query);
        try {
            const response = await services.task.GET_TASK(obj);
            if (response) {
                let data = JSON.parse(JSON.stringify(response?.data?.data));
                setTaskMail(data);
            }
        } catch (error) {
            console.error('Error fetching task emails:', error);
        }
    };
    const getTaskAssign = async () => {
        const response = await GET_ALL_USER({ limit: 10, email: searchTerm });
        response && setTaskAssign(response?.users);
    };

    useEffect(() => {
        getTaskMail();
    }, []);

    useEffect(() => {
        getTaskAssign();
    }, [searchTerm]);

    const columnMove = (draggedColumn, index) => {
        dispatch(columnIndex({ from: draggedColumn, to: index }));
    };

    const moveChildTask = async (dragItem, dropItem, parentTaskStatus) => {
        const parentData = {
            parentTaskID: dropItem?.id || '',
            parentTaskStatus: !parentTaskStatus,
        };
        try {
            await services.task.UPDATE_CHILD_TASK(dragItem?.item?.id || dragItem?.id, parentData);
            getTaskMail();
        } catch (e) {
            console.error(e);
        }
    };

    const handleRemoveChild = (itemid, parentDataId, columnID, item) => {
        moveChildTask(item, '', true);
    };
    return (
        <div>
            <div
                style={{ gap: '1px', display: 'flex' }}
                className="column__container colum-border border-[2px]  mt-[-8px]"
            >
                {conData?.map((column, index) => (
                    <Column
                        key={column?.id}
                        id={column?.id}
                        columnTitle={column?.title}
                        limit={column?.limit}
                        borderColor={column?.borderColor}
                        setStatusUpdate={setStatusUpdate}
                        statusUpdate={statusUpdate}
                        setTaskDetailsPopup={setTaskDetailsPopup}
                        taskDetailsPopup={taskDetailsPopup}
                        getTaskMail={getTaskMail}
                        taskMail={taskMail}
                        taskAssign={taskAssign}
                        setSearchTerm={setSearchTerm}
                        index={index}
                        columnMove={columnMove}
                        columnID={column?.id}
                        searchTerm={searchTerm}
                        isShiftPressed={isShiftPressed}
                        moveChildTask={moveChildTask}
                        handleRemoveChild={handleRemoveChild}
                        setIsShiftPressed={setIsShiftPressed}
                    />
                ))}

                <div className="inbox-Task-scroll" style={{ minWidth: '300px' }}>
                    <div className="task task-curve">
                        <button
                            style={{ opacity: '0.3' }}
                            className="add-task-button"
                            onClick={() => dispatch(AddColumnCall())}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ColumnContainer;
