import React, { useEffect } from 'react';
import './App.css';
import initialiseInterceptor from './services/interceptor';
import AppViewer from './components/AppViewer';

function App() {
    useEffect(() => {
        initialiseInterceptor();
    }, []);

    return (
        <div className="app">
            <AppViewer />
        </div>
    );
}

export default App;
