import api from '../api';
import axios from 'axios';
export default {
    CREATE_AUTOMATIONS: (data) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.REACT_APP_BACKEND_URL + api.automations.CREATE_AUTOMATIONS(), data)
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        });
    },
    GET_ALL_AUTOMATIONS: () => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_BACKEND_URL + api.automations.GET_ALL_AUTOMATIONS())
                .then((res) => resolve(res?.data?.data))
                .catch((err) => reject(err));
        });
    },
    DELETE_BY_ID_AUTOMATIONS: (id) => {
        return new Promise((resolve, reject) => {
            axios
                .delete(process.env.REACT_APP_BACKEND_URL + api.automations.DELETE_BY_ID_AUTOMATIONS(id))
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
    UPDATE_AUTOMATIONS: (id, data) => {
        return new Promise((resolve, reject) => {
            axios
                .put(process.env.REACT_APP_BACKEND_URL + api.automations.UPDATE_AUTOMATIONS(id), data)
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
    GET_BY_ID_AUTOMATIONS: (id) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_BACKEND_URL + api.automations.GET_BY_ID_AUTOMATIONS(id))
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
};
