import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import services from '../../services';
import DeleteModal from '../../utils/deleteModal/deleteModal';
import Automations from './Automations';

const UserTable = () => {
    const [editData, setEditData] = useState('');
    const [editId, setEditId] = useState('');
    const [allGrorp, setAllGroup] = useState([]);

    const [showModalDelete, setShowModalDelete] = useState(false);
    const [selectDeleteId, setSelectDeleteId] = useState('');

    const [addUserStatus, setAddUserStatus] = useState(false);
    const [boardName, setBoardName] = useState('');

    const [gmailUsers, setGmailUsers] = useState([]);
    const navigate = useNavigate();

    const handleStatusToggle = async (item) => {
        try {
            await services.automations.UPDATE_AUTOMATIONS(item?.id, { status: !item?.status });
            toast.success('Update Successfully');
            getAllAutomation();
        } catch (e) {
            console.error(e);
        }
    };

    const handleEdit = (data) => {
        setEditData(data);
        setAddUserStatus(true);
        setEditId(data?.id);
    };

    useEffect(() => {
        getAllAutomation();
        getAllBoard();
        getAllLinkedEmail();
    }, []);

    const getAllAutomation = async () => {
        try {
            const response = await services.automations.GET_ALL_AUTOMATIONS();
            setAllGroup(response);
        } catch (e) {
            console.error(e);
        }
    };
    const handleDelete = async () => {
        try {
            await services.automations.DELETE_BY_ID_AUTOMATIONS(selectDeleteId);
            setShowModalDelete(false);
            getAllAutomation();
        } catch (e) {
            console.error(e);
        }
    };

    const getAllBoard = async () => {
        try {
            const { data } = await services.board.GETALLBOARD();
            const mapData = data?.map(({ boardName, id }) => ({ label: boardName, value: id }));
            console.log('mapData123', mapData);
            setBoardName(mapData);
        } catch (e) {
            console.error(e);
        }
    };

    const getAllLinkedEmail = async () => {
        try {
            const response = await services.email.Get_The_Linked_Email();
            if (response) {
                const options = response?.data?.map((email) => ({
                    label: email.email,
                    value: email.id,
                    color: email.colour,
                }));
                setGmailUsers(options);
            }
        } catch (error) {
            console.error('error');
        }
    };

    return (
        <div style={{ padding: '20px 20px 0px 20px' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                <button
                    className="border-2 border-[#42B4F4]  text-[#42B4F4]  py-1 px-2 rounded"
                    onClick={() => navigate('/app')}
                >
                    Back
                </button>
            </div>
            <div style={{ marginTop: '10px' }}>
                <table className="table table-hover table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">Type</th>

                            <th scope="col">Board Name</th>
                            <th scope="col">Column Name</th>

                            <th scope="col">Create Date</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>

                    {allGrorp?.map((item, index) => (
                        <tbody key={item.id}>
                            <tr>
                                <th scope="row">{index + 1}</th>
                                <td>{item?.name}</td>
                                <td>{item?.type}</td>

                                <td>{item?.board?.boardName}</td>
                                <td>{item?.boardList?.coloum}</td>

                                <td>{moment(item?.createdAt).format('DD MMM YYYY')}</td>
                                <td>
                                    <div className="form-check form-switch" style={{}}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={item.status}
                                            onChange={() => handleStatusToggle(item)}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <button className="btn btn-sm me-1" onClick={() => handleEdit(item)}>
                                        <img src="assets/edit.svg" alt="edit" />
                                    </button>
                                    <button
                                        className="btn btn-sm me-1"
                                        onClick={() => {
                                            setShowModalDelete(true);
                                            setSelectDeleteId(item.id);
                                        }}
                                    >
                                        <img src="assets/delete.svg" alt="delete" />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
            {showModalDelete && (
                <DeleteModal
                    showModal={showModalDelete}
                    handleCloseModal={() => setShowModalDelete(false)}
                    handleConfirm={() => handleDelete()}
                    confirmButtonName={'Delete'}
                />
            )}
            {addUserStatus && (
                <Automations
                    show={addUserStatus}
                    onHide={() => setAddUserStatus(false)}
                    boardName={boardName}
                    gmailUsers={gmailUsers}
                    editData={editData}
                    editId={editId}
                    getAllAutomation={getAllAutomation}
                />
            )}
        </div>
    );
};

export default UserTable;
