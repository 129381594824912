import React from 'react';
import ColumnContainer from './ColumnContainer';
import { TaskContext } from './../context';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ModalBox from './ModalBox';

export default class Board extends React.Component {
    static contextType = TaskContext;

    render() {
        const { columns, tasks, moveTaskToColumn, removeTask, addTaskToColumn, isModalOpen, handleCloseModal } = this.context;
        return (
            <div className=''>
                <section className="kanban ">
                    <DndProvider backend={HTML5Backend}>
                        <ColumnContainer
                            columns={columns}
                            tasks={tasks}
                            moveTaskToColumn={moveTaskToColumn}
                            removeTask={removeTask}
                            addTaskToColumn={addTaskToColumn}
                        />
                    </DndProvider>
                </section>
                {isModalOpen && <ModalBox onClose={handleCloseModal} isModalOpen={isModalOpen} />}
            </div>
        );
    }
}
