import React, { useState } from 'react';
import Header from '../Header';

const TableData = () => {
    const [items, setItems] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [listFilter, setlistFilter] = useState(false);
    const [filterIdeas, setfilterIdeas] = useState(false);

    const listDropdown = () => {
        setlistFilter(!listFilter);
    };

    const filterOfIdeas = () => {
        setfilterIdeas(!filterIdeas);
    };
    const handleAddItem = (event) => {
        event.preventDefault();
        const idData = items?.length > 0 ? items[items.length - 1].id + 1 : 1;

        const newItem = {
            id: idData,
            title: event.target.title.value,
            list: event.target.list.value,
            taskStatus: 'Open',
            assignedTo: (
                <span className="circle-user">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-person"
                        viewBox="0 0 16 16"
                    >
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                    </svg>
                </span>
            ),
            created: 'Today',
            lastActivity: 'Today',
        };

        setItems([...items, newItem]);
        setIsModalOpen(false);
        event.target.reset();
    };

    return (
        <div>
            <Header />
            <div className=" p-4">
                {isModalOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-0  z-50 ">
                        <div className="bg-white p-6 rounded  shadow-2xl shadow-[0 0 60px 8px rgba(0, 0, 0, 0.3)] w-[374px]">
                            <div className="flex justify-between">
                                <h2 className="text-2xl font-bold mb-4 text-[black]">Add Item</h2>
                                <button
                                    type="button"
                                    onClick={() => setIsModalOpen(false)}
                                    className="text-gray font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                                >
                                    X
                                </button>
                            </div>
                            <form onSubmit={handleAddItem}>
                                <div className="mb-4">
                                    <label htmlFor="title" className="block mb-2 text-gray-700">
                                        Title:
                                    </label>

                                    <textarea
                                        id="title"
                                        name="title"
                                        rows="4"
                                        placeholder="e.g. Deal with Apple"
                                        className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                        required
                                    ></textarea>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="list" className="block mb-2 text-[black]">
                                        Add to list
                                    </label>
                                    <select
                                        id="list"
                                        name="list"
                                        className="w-full text-[black] px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                        required
                                    >
                                        <option value="Ideas">Ideas</option>
                                        <option value="Planned">Planned</option>
                                        <option value="In progress">In progress</option>
                                        <option value="Done">Done</option>
                                    </select>
                                </div>
                                <div className="flex justify-end">
                                    <button
                                        type="submit"
                                        className="bg-[#42B4F4] hover:bg-[#1692d8] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-[130px]"
                                    >
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
                <div className="grid grid-cols-[20%_auto] h-[800px] border-2 border-[#E0E0E0]">
                    <div>
                        <div className="flex border-2 border-[#E0E0E0] h-[88px] justify-between ">
                            <div className="px-4 py-2  text-left text-black mt-[20px]">Title</div>
                            <button
                                onClick={() => setIsModalOpen(true)}
                                className="bg-[#68C3F6] mr-[10px] text-white font-bold px-2 rounded focus:outline-none focus:shadow-outline mb-4 mt-[23px] text-[13px] py-2"
                            >
                                Add Item
                            </button>
                        </div>
                        {items?.map((item, index) => (
                            <div key={index} className=" h-[88px] border-2 border-[#E0E0E0] bg-hover-[]">
                                <div className="px-4 py-2  text-left text-black mt-[20px]">{item.title}</div>
                            </div>
                        ))}
                    </div>
                    <div className="overflow-x-scroll">
                        <div className="w-[2119px]">
                            <div className="grid grid-cols-12 text-[14px] h-[88px] justify-center items-center border-b border-[#E0E0E0]">
                                <div className="  col-auto text-center text-black flex justify-center items-center gap-[10px]">
                                    <span onClick={listDropdown}>List</span>{' '}
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-up-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z" />
                                        </svg>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="  col-auto text-center text-black flex justify-center items-center gap-[10px]">
                                    <span>Task Status</span>{' '}
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-up-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z" />
                                        </svg>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="  col-auto text-center text-black flex justify-center items-center gap-[10px]">
                                    <span>Assigned To</span>{' '}
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-up-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z" />
                                        </svg>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="  col-auto text-center text-black flex justify-center items-center gap-[10px]">
                                    <span>Due Date</span>{' '}
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-up-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z" />
                                        </svg>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="  col-auto text-center text-black flex justify-center items-center gap-[10px]">
                                    <span>Kick Off</span>{' '}
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-up-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z" />
                                        </svg>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="  col-auto text-center text-black flex justify-center items-center gap-[10px]">
                                    <span>Priority</span>{' '}
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-up-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z" />
                                        </svg>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="  col-auto text-center text-black flex justify-center items-center gap-[10px]">
                                    <span>Time Estimate</span>{' '}
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-up-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z" />
                                        </svg>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="  col-auto text-center text-black flex justify-center items-center gap-[10px]">
                                    <span>Status</span>{' '}
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-up-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z" />
                                        </svg>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="  col-auto text-center text-black flex justify-center items-center gap-[10px]">
                                    <span>Budget</span>{' '}
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-up-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z" />
                                        </svg>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    </div>
                                </div>
                                
                                <div className="  col-auto text-center text-black flex justify-center items-center gap-[10px]">
                                    <span>Created</span>{' '}
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-up-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z" />
                                        </svg>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="  col-auto text-center text-black flex justify-center items-center gap-[10px]">
                                    <span>Last Email</span>{' '}
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-up-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z" />
                                        </svg>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="  col-auto text-center text-black flex justify-center items-center gap-[10px]">
                                    <span>Last Activity</span>{' '}
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-up-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z" />
                                        </svg>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="currentColor"
                                            className="bi bi-chevron-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            {items.map((item, index) => (
                                <div
                                    key={index}
                                    className="grid grid-cols-12 text-[14px] h-[88px] justify-center items-center border-b border-[#E0E0E0] divide-x"
                                >
                                    <div
                                        className="col-auto text-center text-black border-2 border-[#E0E0E0] flex justify-center items-center h-[100%]"
                                        onClick={filterOfIdeas}
                                    >
                                        <button
                                            className={`m-0
                                        ${
                                item.list === 'Ideas'
                                    ? 'text-white px-[4px] py-[2px] bg-[#8BC34A] rounded-[5px]'
                                    : ''
                                }
                                        ${
                                item.list === 'Planned'
                                    ? 'text-white px-[4px] py-[2px] bg-[#00BCD4] rounded-[5px]'
                                    : ''
                                }
                                        ${
                                item.list === 'In progress'
                                    ? 'text-white px-[4px] py-[2px] bg-[#FF5722] rounded-[5px]'
                                    : ''
                                }
                                        ${
                                item.list === 'Done'
                                    ? 'text-white px-[4px] py-[2px] bg-[#9C27B0] rounded-[5px]'
                                    : ''
                                }
                                        `}
                                        >
                                            {item.list}
                                        </button>
                                    </div>

                                    <div className=" col-auto text-center text-black border-2 border-[#E0E0E0] flex justify-center items-center  h-[100%]">
                                        {item.taskStatus}
                                    </div>
                                    <div className=" col-auto text-center text-black border-2 border-[#E0E0E0] flex justify-center items-center  h-[100%]">
                                        {item.assignedTo}
                                    </div>
                                    <div className=" col-auto text-center text-black border-2 border-[#E0E0E0] flex justify-center items-center  h-[100%]">
                                        {item.dueDate}
                                    </div>
                                    <div className=" col-auto text-center text-black border-2 border-[#E0E0E0] flex justify-center items-center  h-[100%]">
                                        {item.kickOff}
                                    </div>
                                    <div className=" col-auto text-center text-black border-2 border-[#E0E0E0] flex justify-center items-center  h-[100%]">
                                        {item.priority}
                                    </div>
                                    <div className=" col-auto text-center text-black border-2 border-[#E0E0E0] flex justify-center items-center  h-[100%]">
                                        {item.timeEstimate}
                                    </div>
                                    <div className=" col-auto text-center text-black border-2 border-[#E0E0E0] flex justify-center items-center  h-[100%]">
                                        {item.status}
                                    </div>
                                    <div className=" col-auto text-center text-black border-2 border-[#E0E0E0] flex justify-center items-center  h-[100%]">
                                        {item.budget}
                                    </div>
                                    <div className=" col-auto text-center text-black border-2 border-[#E0E0E0] flex justify-center items-center  h-[100%]">
                                        {item.created}
                                    </div>
                                    <div className=" col-auto text-center text-black border-2 border-[#E0E0E0] flex justify-center items-center  h-[100%]">
                                        {item.lastEmail}
                                    </div>
                                    <div className=" col-auto text-center text-black border-2 border-[#E0E0E0] flex justify-center items-center  h-[100%]">
                                        {item.lastActivity}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {filterIdeas && (
                        <div className="origin-top-right absolute mt-2 w-[18rem] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 left-[318px] text-black">
                            <div className="max-w-xs p-4 bg-white border rounded shadow-lg">
                                <div className="flex mb-4 justify-between border-b-4">
                                    <input type="text" className="w-full ideastask" />
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-search text-[#696D72]"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                    </svg>
                                </div>
                                <div className="space-y-2">
                                    <div className="border-b pb-2 text-black">
                                        <h2 className="font-semibold border-b-2 text-start">Personal Board</h2>
                                        <ul className="space-y-1 text-start mt-[12px] ml-[10px]">
                                            <li>To do</li>
                                            <li>Working on</li>
                                            <li>Follow up</li>
                                            <li>To Read</li>
                                            <li>Done</li>
                                        </ul>
                                    </div>
                                    <div className="border-b pb-2 text-black">
                                        <h2 className="font-semibold text-start">Project Board</h2>
                                        <ul className="space-y-1 text-start mt-[12px] ml-[10px]">
                                            <li>Ideas</li>
                                            <li>Planned</li>
                                            <li>In progress</li>
                                            <li>Done</li>
                                        </ul>
                                    </div>
                                    <button className="text-[#42B4F4]">Remove from board</button>
                                </div>
                            </div>
                        </div>
                    )}
                    {listFilter && (
                        <div className="origin-top-right absolute mt-2 w-[18rem] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 left-[318px] text-black">
                            <div
                                className="py-1"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="options-menu"
                            >
                                <div className="flex ml-[18px] gap-[4px]">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        className="bi bi-arrow-up-short"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5" />
                                    </svg>
                                    <span>Sort ascending</span>
                                </div>
                                <div className="flex ml-[18px] gap-[4px]">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        className="bi bi-arrow-down-short"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4" />
                                    </svg>
                                    <span>Sort descending</span>
                                </div>
                                <div className="flex ml-[18px] gap-[4px]">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        className="bi bi-arrow-right-short"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                    </svg>
                                    <span>Insert Custom Property at end</span>
                                </div>

                                <div className="border-t border-gray-100"></div>
                                <div className="px-4 py-2">
                                    <div className="flex gap-[10px]">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-filter mt-[2px]"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5" />
                                        </svg>
                                        <span className="text-[15px]">Filter</span>
                                    </div>

                                    <input
                                        type="text"
                                        name="filter"
                                        id="filter"
                                        placeholder="Type to Search"
                                        className="mt-1 block w-full px-3 py-2 border-[#BDBDBD] border-2 rounded-md placeholder-black  sm:text-sm"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TableData;
