import api from '../api';
import axios from 'axios';
export default {
    CREATE_PERMISSION: (data) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.REACT_APP_BACKEND_URL + api.permission.CREATE_PERMISSION(), data)
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        });
    },
    GET_ALL_PERMISSION: () => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_BACKEND_URL + api.permission.GET_ALL_PERMISSION())
                .then((res) => resolve(res?.data?.data))
                .catch((err) => reject(err));
        });
    },
    DELETE_PERMISSION: (id) => {
        return new Promise((resolve, reject) => {
            axios
                .delete(process.env.REACT_APP_BACKEND_URL + api.permission.DELETE_PERMISSION(id))
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
    UPDATE_PERMISSION: (id, data) => {
        return new Promise((resolve, reject) => {
            axios
                .put(process.env.REACT_APP_BACKEND_URL + api.permission.UPDATE_PERMISSION(id), data)
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
    GET_PERMISSION_ID: (id) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_BACKEND_URL + api.permission.GET_PERMISSION_ID(id))
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
};
