import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { HexColorPicker } from 'react-colorful';

const UserTable = () => {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [taskMailInbox, setTaskMailInbox] = useState([]);
    const [showUser, setShowUser] = useState(true);
    const [showSync, setShowSync] = useState(false);
    const [color, setColor] = useState('');
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
        setShowModal((prev) => !prev);
    };

    const getTaskUser = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/getAllUser?limit=10`);
            if (response) {
                const users = response?.data?.data?.users || [];
                setUsers(users);

                const initialPermissions = users.map((user) => {
                    const permissions = user?.permissions || [];
                    return permissions.map((perItem) => ({
                        value: perItem,
                        label: perItem,
                    }));
                });

                setSelectedPermissions(initialPermissions);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        getTaskUser();
    }, []);

    const updateStatusTask = async (id, permissions) => {
        const data = {
            permissions: permissions.join(','),
        };
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/auth/updateUser/${id}`, data);
            if (response) {
                getTaskUser();
            }
        } catch (error) {
            console.error('Error updating user permissions:', error);
        }
    };

    const getTaskMailInbox = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/email/getEmailsByDate?limit=13`);
            if (response) {
                setTaskMailInbox(response?.data?.emails);
            }
        } catch (error) {
            console.error('Error fetching task emails:', error);
        }
    };

    useEffect(() => {
        getTaskMailInbox();
    }, []);

    const updateGmailUser = async (id) => {
        const data = {
            colour: color,
        };
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/email/updateGmailUser/${id}`, data);
            if (response) {
                getTaskMailInbox();
            }
        } catch (error) {
            console.error('Error updating user permissions:', error);
        }
    };

    const handleSelectChange = (index, selectedOptions) => {
        const updatedPermissions = selectedOptions.map((option) => option.value);
        setSelectedPermissions((prev) => {
            const newPermissions = [...prev];
            newPermissions[index] = updatedPermissions.map((value) => ({
                value,
                label: value,
            }));
            return newPermissions;
        });
    };

    const handleColorPaletteClick = () => {
        setShowColorPicker(!showColorPicker); // Toggle color picker visibility
    };

    const handleBackButton = () => {
        navigate('/adduser');
    };

    const handleUserClick = () => {
        setShowUser(true);
        setShowSync(false);
    };

    const handleSyncClick = () => {
        setShowSync(true);
        setShowUser(false);
    };

    const permissionsOptions = [
        { value: 'updateTask', label: 'updateTask' },
        { value: 'updateStatusTask', label: 'updateStatusTask' },
        { value: 'createTask', label: 'createTask' },
        { value: 'getAllTask', label: 'getAllTask' },
        { value: 'deleteTask', label: 'deleteTask' },
        { value: 'getAllDeletedTask', label: 'getAllDeletedTask' },
        { value: 'getTaskBy', label: 'getTaskBy' },
        { value: 'createaddProperty', label: 'createaddProperty' },
        { value: 'updateaddProperty', label: 'updateaddProperty' },
        { value: 'getAddPropertyBy', label: 'getAddPropertyBy' },
        { value: 'getAllAddProperty', label: 'getAllAddProperty' },
        { value: 'deleteAddProperty', label: 'deleteAddProperty' },
    ];

    return (
        <div className="bg-gray-100 h-screen ">
            <div className=" m-auto  ">
                <div className="">
                    <div className="mx-auto pt-[5rem] max-w-[962px]">
                        <div className="">
                            <div className="flex items-center tab-shadow-xl ml-[30px]">
                                <button
                                    className={`mt-[25px] rounded-l-[4px]   user_tab ${
                                        showUser ? 'active-usertab' : ''
                                    }`}
                                    onClick={handleUserClick}
                                >
                                    <span>User</span>
                                </button>
                                <button
                                    className={`mt-[25px]  rounded-r-[4px]  user_tab ${
                                        showSync ? 'active-usertab' : ''
                                    }`}
                                    onClick={handleSyncClick}
                                >
                                    <span>Gmail Account</span>
                                </button>
                            </div>
                        </div>

                        <div className="mt-[1rem]">
                            {showUser && (
                                <div className=" relative overflow-x-auto shadow-md sm:rounded-lg">
                                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    User Name
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Email
                                                </th>

                                                <th scope="col" className="px-6 py-3">
                                                    <div className="text-end">
                                                        <button className=" adduserbtn" onClick={handleBackButton}>
                                                            Add User
                                                        </button>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users?.map((user, index) => (
                                                <tr
                                                    key={user.id || index}
                                                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                                >
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                    >
                                                        {user?.name}
                                                    </th>
                                                    <td className="px-6 py-4">{user?.email}</td>

                                                    <td className="px-6 py-4 text-right">
                                                        <button
                                                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                                            style={{
                                                                opacity:
                                                                    selectedPermissions[index]?.length > 0 ? 1 : 0.5,
                                                                cursor:
                                                                    selectedPermissions[index]?.length > 0
                                                                        ? 'pointer'
                                                                        : 'not-allowed',
                                                            }}
                                                            disabled={!selectedPermissions[index]?.length}
                                                            onClick={() => {
                                                                updateStatusTask(
                                                                    user.id,
                                                                    selectedPermissions[index]?.map(
                                                                        (permission) => permission.value,
                                                                    ),
                                                                    handleOpenModal,
                                                                );
                                                            }}
                                                        >
                                                            Edit
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}

                            {showModal ? (
                                <div>
                                    <div className="layout-backdrop"></div>
                                    <div className="select-permission">
                                        <Select
                                            isMulti
                                            options={permissionsOptions}
                                            value={selectedPermissions[index] || []}
                                            onChange={(selectedOptions) => handleSelectChange(index, selectedOptions)}
                                            className="bg-white !border-none rounded outline-none w-full"
                                        />
                                    </div>
                                </div>
                            ) : null}

                            {showSync && (
                                <div className=" relative overflow-x-auto shadow-md sm:rounded-lg">
                                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    User Name
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Email
                                                </th>

                                                <th scope="col" className="px-6 py-3">
                                                    <div className="text-end">
                                                        <button className=" adduserbtn" onClick={handleBackButton}>
                                                            Add Account
                                                        </button>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {taskMailInbox?.map((user, index) => (
                                                <tr
                                                    key={user.id || index}
                                                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                                >
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                    >
                                                        {user?.name}
                                                    </th>
                                                    <td className="px-6 py-4">{user?.sentTo}</td>

                                                    <td className="px-6 py-4 text-right">
                                                        <button
                                                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                                            onClick={() => {
                                                                updateGmailUser(user.id);
                                                                handleOpenModal();
                                                            }}
                                                        >
                                                            Edit
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            {showModal ? (
                                <div>
                                    <div className="layout-backdrop"></div>
                                    <div className="select-permission">
                                        <div className="flex">
                                            <h6 className="text-black mr-[2rem] text-xs font-medium py-3 uppercase">
                                                Colour :
                                            </h6>
                                            <div
                                                className="w-[30px] h-[30px] rounded-full cursor-pointer border-2 mt-2"
                                                style={{ backgroundColor: color || user?.colour }}
                                                onClick={handleColorPaletteClick}
                                            />
                                            {showColorPicker && (
                                                <HexColorPicker
                                                    color={color}
                                                    onChange={setColor}
                                                    className="absolute z-10 ml-4"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserTable;
