import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import services from '../../services';

const AddBoard = ({ onHide, show, getAllBoard }) => {
    const [boardInput, setBoardInput] = useState('');

    const handleAddBoard = async () => {
        try {
            await services.board.CREATE_BOARD({ boardName: boardInput });
            setBoardInput('');
            toast.success('Board Create Successfully');
            getAllBoard();
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Add Board</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control
                    type="text"
                    placeholder="Board Name"
                    value={boardInput}
                    onChange={(e) => setBoardInput(e.target.value.trimStart())}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            if (!boardInput) {
                                toast.warning('Please Enter Board Name');
                                return;
                            }
                            handleAddBoard();
                            onHide();
                        }
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Close</Button>
                <Button
                    onClick={() => {
                        if (!boardInput) {
                            toast.warning('Please Enter Board Name');
                            return;
                        }
                        handleAddBoard();
                        onHide();
                    }}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddBoard;
