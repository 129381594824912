import axios from 'axios';
import api from '../api';

export default {
    SEND_COMPOSE: (data) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.REACT_APP_BACKEND_URL + api.compose.SEND_COMPOSE(), data)
                .then((res) => resolve(res))
                .catch((e) => reject(e));
        });
    },
};
