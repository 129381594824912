import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';

const AddBoard = ({ onHide, show, handleAddColumn }) => {
    const [boardInput, setBoardInput] = useState('');

    return (
        <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Add Column</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control
                    type="text"
                    placeholder="Column Name"
                    value={boardInput}
                    onChange={(e) => setBoardInput(e.target.value.trimStart())}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            if (!boardInput) {
                                toast.warning('Please Enter Column Name');
                                return;
                            }
                            handleAddColumn(boardInput);
                            onHide();
                        }
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Close</Button>
                <Button
                    onClick={() => {
                        if (!boardInput) {
                            toast.warning('Please Enter Column Name');
                            return;
                        }
                        handleAddColumn(boardInput);
                        onHide();
                    }}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddBoard;
