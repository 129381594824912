import services from '../services';
export async function GET_ALL_USER(obj) {
    const query = new URLSearchParams(obj);
    try {
        const response = await services.user.GET_ALL_USER(query);
        return response;
    } catch (error) {
        console.error('Error fetching task mails:', error);
    }
}
