import api from '../api';
import axios from 'axios';
export default {
    CREATE_TEMPLATE: (data) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.REACT_APP_BACKEND_URL + api.template.CREATE_TEMPLATE(), data)
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        });
    },
    GET_ALL_TEMPLATE: () => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_BACKEND_URL + api.template.GET_ALL_TEMPLATE())
                .then((res) => resolve(res?.data?.data))
                .catch((err) => reject(err));
        });
    },
    DELETE_TEMPLATE: (id) => {
        return new Promise((resolve, reject) => {
            axios
                .delete(process.env.REACT_APP_BACKEND_URL + api.template.DELETE_TEMPLATE(id))
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
    UPDATE_TEMPLATE: (id, data) => {
        return new Promise((resolve, reject) => {
            axios
                .put(process.env.REACT_APP_BACKEND_URL + api.template.UPDATE_TEMPLATE(id), data)
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
    GET_TEMPLATE_ID: (id) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_BACKEND_URL + api.template.GET_TEMPLATE_ID(id))
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
};
