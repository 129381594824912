import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import imageCover from '../../images/59877.jpg';
import services from '../../services';

const Login = () => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleLogin = async () => {
        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address.');
            return;
        }
        setIsSubmitting(true);
        const data = {
            email: email,
            password: password,
        };

        try {
            const response = await services.auth.LOGIN(data);

            if (response?.data?.tokens?.access?.token) {
                setEmail('');
                setPassword('');
                // navigate('/app');
                window.location.replace('/app');
            }
        } catch (error) {
            console.error('Error logging in:', error);
            toast.error(error?.response?.data?.message || 'Login failed');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <div>
            <div className="bg-cover min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
                <div className="md:grid grid-cols-2 relative  max-w-login mx-auto  bg-white rounded-[8px] shadow-lg py-[2.4rem] px-[2rem]">
                    <div className="hidden md:flex items-center justify-center border-r border-[#e0e0e0]">
                        <img src={imageCover} alt />
                    </div>
                    <div className=" flex items-center justify-center relative ">
                        <div className="">
                            <div>
                                <h3 className="text-[20px] text-center mt-[1rem] mb-[1.5rem] font-semibold text-[#2c2c2c]">
                                    <span className="border-b border-[#0b529e] pb-[0.2rem] px-[5px]">Admin Login</span>
                                </h3>
                            </div>
                            <div className="">
                                <div className=" text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                    <div className="relative mb-[20px]">
                                        <label className="block mb-[0.4rem] text-gray-600 text-sm font-i">
                                            Email Address
                                        </label>
                                        <input
                                            id="email"
                                            name="email"
                                            type="text"
                                            className="h-10 w-full border-r  text-gray-900 font-i focus:outline-none p-3 border-i input-border"
                                            placeholder="Email address"
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                if (!validateEmail(e.target.value)) {
                                                    setEmailError('Please enter a valid email address.');
                                                } else {
                                                    setEmailError('');
                                                }
                                            }}
                                        />

                                        <h6 className="text-[10.5px] text-[#F5004F] absolute bottom-[-25px] left-0 ">
                                            {emailError && emailError}
                                        </h6>
                                    </div>
                                    <div className="relative mb-[20px]">
                                        <label className="block mb-[0.4rem] text-gray-600  text-[14px]">Password</label>
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            className="h-10 w-full !text-[14px] border-r font-i placeholder:text-[14px]  text-gray-900 focus:outline-none p-3 border-i input-border"
                                            placeholder="Password"
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (value === '') {
                                                    setPasswordError('Email is required');
                                                } else {
                                                    setPasswordError('');
                                                    setPassword(value);
                                                }
                                            }}
                                            onKeyDown={handleKeyDown}
                                        />
                                        <h6 className="text-[10.5px] text-[#F5004F] absolute bottom-[-25px] left-0 ">
                                            {passwordError && passwordError}
                                        </h6>
                                    </div>
                                    <div className="relative ">
                                        <button
                                            className="bg-[#0b529e] text-white rounded-[4px] px-2 py-1 text-[14px] w-[-webkit-fill-available] mt-[20px] mb-[1rem]"
                                            onClick={() => {
                                                handleLogin();
                                            }}
                                        >
                                            {isSubmitting ? 'Submitting...' : 'Submit'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
