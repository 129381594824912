import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
const TaskData = ({ gmailMassageID, taskItem, updateTaskMail }) => {
    const [propertyType, setPropertyType] = useState('');
    const [propertyName, setPropertyName] = useState('');
    const [displayOnCard, setDisplayOnCard] = useState(false);
    const [isOpenDeadline, setIsOpenDeadline] = useState(false);
    const [taskMail, setTaskMail] = useState([]);
    const [taskAssign, setTaskAssign] = useState([]);
    const [isOpenProjectBoard, setIsOpenProjectBoard] = useState(false);
    const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);
    const propertyTypes = [
        'Text Single Line',
        'Text Double Line',
        'Number',
        'Date',
        'Team member',
        'Contact / Company',
        'Task',
        'Status',
        'Tag',
        'Dropdown',
        'Multi-select',
        'File',
        'URL',
        'Email',
        'Phone',
        'Location',
    ];

    const handlePropertyTypeChange = (e) => {
        setPropertyType(e.target.value);
    };

    const addPropertyName = () => {
        setIsOpenDeadline(!isOpenDeadline);
    };

    const handlePropertyNameChange = (event) => {
        setPropertyName(event.target.value);
    };

    const handleDisplayOnCardChange = (event) => {
        setDisplayOnCard(event.target.checked);
    };

    const handleToggleSearchBox = () => {
        setIsSearchBoxOpen(!isSearchBoxOpen);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            createAddProperty();
            getTaskMailInbox();

            setIsOpenDeadline(false);
        }
    };

    const handleAssign = (e) => {
        if (e.key === 'Enter') {
            getTaskAssign();
        }
    };

    const getTaskMailInbox = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/addProperty/getAllAddProperty`);
            if (response) {
                setTaskMail(response?.data?.data);
            }
        } catch (error) {
            console.error('Error fetching task mails:', error);
        }
    };

    const createAddProperty = async () => {
        const data = {
            type: propertyType,
            propertyName: propertyName,
        };

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/addProperty/createaddProperty`,
                data,
            );
            if (response) {
                getTaskMailInbox();
            }
        } catch (error) {
            console.error('Error creating task mail:', error);
        }
    };

    const deleteTaskMailProperty = async (type, propertyName, id) => {
        const data = {
            type: type,
            propertyName: propertyName,
        };
        try {
            const response = await axios.delete(
                ` ${process.env.REACT_APP_BACKEND_URL}/addProperty/deleteAddProperty/${id}`,
                data,
            );
            if (response) {
                getTaskMailInbox();
            }
        } catch (error) {
            console.error('Error fetching task emails:', error);
        }
    };

    const getTaskAssign = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/auth/getAllUser?limit=10&email=hiteshlokhande@gmail.com`,
            );
            if (response) {
                setTaskAssign(response?.data?.data?.users);
            }
        } catch (error) {
            console.error('Error fetching task mails:', error);
        }
    };

    useEffect(() => {
        getTaskMailInbox();
    }, []);

    useEffect(() => {
        getTaskAssign();
    }, []);

    return (
        <div>
            <div className="fixed  top-[81px] z-[10] right-[0px]  z-20 w-min">
                <div className="bg-white rounded-md p-4 h-[100vh] shadow-[-14px_0px_12px_-9px_rgba(0,0,0,0.54)]">
                    <div className="flex justify-between">
                        <div className="flex gap-[20px] allspanitems">
                            <span className=" hover:bg-[#E4E4E4] rounded-[50%]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-check-circle p-[1px]"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                </svg>
                            </span>
                            <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-bell p-[1px]"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
                                </svg>
                            </span>
                            <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-calendar-date p-[1px]"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M6.445 11.688V6.354h-.633A13 13 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23" />
                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                </svg>
                            </span>
                            <span className=" hover:bg-[#E4E4E4] rounded-[50%]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-flag p-[1px]"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                                </svg>
                            </span>
                            <span className=" hover:bg-[#E4E4E4] rounded-[50%]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-paperclip p-[1px]"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
                                </svg>
                            </span>
                            <span className=" hover:bg-[#E4E4E4] rounded-[50%]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-envelope p-[1px]"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                </svg>
                            </span>
                        </div>
                        <span className=" hover:bg-[#E4E4E4] rounded-[50%]">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="28"
                                height="28"
                                fill="currentColor"
                                className="bi bi-arrow-right-short p-[1px]"
                                viewBox="0 0 16 16"
                            >
                                <path d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                            </svg>
                        </span>
                    </div>
                    <div className="flex justify-between pb-[5px] border-b border-[#e0e0e0]">
                        <h3 className="text-[1.2rem] font-bold text-black mt-[10px] text-start">
                            {taskItem?.title ? taskItem?.title : taskItem?.subject}
                        </h3>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-pencil mt-[15px] text-black"
                            viewBox="0 0 16 16"
                        >
                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                        </svg>
                    </div>

                    <div className="pt-[20px] pb-[15px] border-b ">
                        <button className="border-2 w-[100%]  h-[40px] ">
                            <span className="text-[#A2A2A2]">No email thread</span>
                        </button>
                    </div>
                    <div>
                        <div className="flex justify-between items-center pt-[5px]">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-funnel-fill"
                                viewBox="0 0 16 16"
                            >
                                <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5z" />
                            </svg>
                            <button
                                className="text-[12px] font-medium p-[3px_84px_2px] bg-[#8BC34A] rounded-[4px] text-[#fff]"
                                onClick={() => setIsOpenProjectBoard(!isOpenProjectBoard)}
                            >
                                Project Board - Plan
                            </button>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-search"
                                viewBox="0 0 16 16"
                            >
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                            </svg>
                        </div>
                        <div>
                            {isOpenProjectBoard && (
                                <div className="absolute right-0 mt-2 w-64 bg-white border rounded shadow-lg">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="w-full px-4 py-2 border-b focus:outline-none"
                                    />
                                    <div className="p-2 overflow-y-board">
                                        <div className="font-bold text-sm text-gray-700">Personal Board</div>
                                        <ul className="space-y-2 mt-1">
                                            <li className="px-4 py-2 cursor-pointer hover:bg-gray-100">To do</li>
                                            <li className="px-4 py-2 cursor-pointer hover:bg-gray-100">Working on</li>
                                            <li className="px-4 py-2 cursor-pointer hover:bg-gray-100">Follow up</li>
                                            <li className="px-4 py-2 cursor-pointer hover:bg-gray-100">To Read</li>
                                            <li className="px-4 py-2 cursor-pointer hover:bg-gray-100">Done</li>
                                        </ul>
                                        <div className="font-bold text-sm text-gray-700 mt-4">Hitesh Project Board</div>
                                        <ul className="space-y-2 mt-1">
                                            <li className="px-4 py-2 cursor-pointer hover:bg-gray-100">Planned</li>
                                            <li className="px-4 py-2 cursor-pointer hover:bg-gray-100">Ideas</li>
                                            <li className="px-4 py-2 cursor-pointer hover:bg-gray-100">In progress</li>
                                            <li className="px-4 py-2 cursor-pointer hover:bg-gray-100">Done</li>
                                        </ul>
                                        <div className="mt-4 px-4 py-2 text-blue-500 cursor-pointer hover:underline">
                                            Remove from board
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex gap-[10px] mt-[20px]">
                            <div className="">
                                <div className="h-[36px] flex items-center gap-x-[10px]]">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-person-circle"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                        <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1" />
                                    </svg>
                                </div>
                                <div className="h-[36px] flex items-center gap-x-[10px]]">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-bell"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
                                    </svg>
                                </div>
                                <div className="h-[36px] flex items-center gap-x-[10px]]">
                                    {' '}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-calendar-date"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M6.445 11.688V6.354h-.633A13 13 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23" />
                                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                    </svg>
                                </div>
                                <div className="h-[36px] flex items-center gap-x-[10px]]">
                                    {' '}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-flag"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                                    </svg>
                                </div>
                                <div className="h-[36px] flex items-center gap-x-[10px]]">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-list"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                                    </svg>
                                </div>
                                <div className="h-[36px] flex items-center gap-x-[10px]]">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-caret-right"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M6 12.796V3.204L11.481 8zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753" />
                                    </svg>
                                </div>
                            </div>

                            <div className="w-[100%]">
                                <div>
                                    <div className="h-[36px] border-b border-[#f5f5f5] items-center gap-x-[10px]">
                                        <a
                                            href="#"
                                            className="text-listdrop !text-[13px]"
                                            onClick={() => {
                                                handleToggleSearchBox();
                                            }}
                                        >
                                            Assign...
                                        </a>

                                        {isSearchBoxOpen && (
                                            <div className="search-assign">
                                                <label>Assign to</label>
                                                {taskAssign?.map((item) => (
                                                    <input
                                                        key={item?.users} // Ensure that item?.users is a unique value
                                                        type="text"
                                                        placeholder="Search by name"
                                                        className="inputField-text"
                                                        onKeyDown={handleAssign}
                                                    />
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="h-[36px] border-b border-[#f5f5f5] flex items-center gap-x-[10px]">
                                    <a href="#" className="text-listdrop !text-[13px]">
                                        Reminder...
                                    </a>
                                </div>
                                <div className="h-[36px] border-b border-[#f5f5f5] flex items-center gap-x-[10px]">
                                    <a href="#" className="text-listdrop !text-[13px]">
                                        My Day plan...
                                    </a>
                                </div>
                                <div className="h-[36px] border-b border-[#f5f5f5] flex items-center gap-x-[10px]">
                                    <a href="#" className="text-listdrop !text-[13px]">
                                        Deadline...
                                    </a>
                                    {taskItem?.deadline ? moment(taskItem.deadline).format('MMM DD') : ''}
                                </div>
                                <div className="h-[36px] border-b border-[#f5f5f5] flex items-center gap-x-[10px]">
                                    <a href="#" className="text-listdrop !text-[13px]">
                                        Notes (Shift-Enter for new line)...
                                    </a>
                                </div>
                                <div className="h-[36px] border-b border-[#f5f5f5] flex items-center gap-x-[10px]">
                                    <a href="#" className="text-listdrop !text-[13px]">
                                        Next Step...
                                    </a>
                                </div>
                                <div className="">
                                    {taskMail?.map((item) => {
                                        return (
                                            <>
                                                <div className="relative h-[36px] flex justify-between border-b border-[#f5f5f5]  items-center gap-x-[10px]">
                                                    <a href="#" className="text-listdrop !text-[13px]">
                                                        {item?.type}
                                                    </a>
                                                    <div>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            className="bi bi-trash"
                                                            viewBox="0 0 16 16"
                                                            onClick={() =>
                                                                deleteTaskMailProperty(
                                                                    item?.type,
                                                                    item?.propertyName,
                                                                    item?.id,
                                                                )
                                                            }
                                                        >
                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                                        </svg>
                                                    </div>
                                                    <div></div>
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="add-property flex gap-[15px] items-center border-b border-[#f5f5f5] text-[.7rem] mt-[17px] border-b-[5px] border-[#f5f5f5]">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-plus-lg text-[#80B1F2]"
                            viewBox="0 0 16 16"
                        >
                            <path d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                        </svg>
                        <h3
                            onClick={addPropertyName}
                            className="text-gray-700 font-bold mb-2 text-[#80B1F2] mt-2 cursor-pointer"
                        >
                            Add a property
                        </h3>
                    </div>
                    <div
                        className={`dropdown ${
                            isOpenDeadline ? 'open' : ''
                        } deadline-day  deadend-start  kanban-dropDown bg-white rounded-md shadow-md p-4 drop-menu-property !absolute`}
                    >
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-lg font-bold text-[black]">Property Settings</h2>
                            <div className="flex gap-2">
                                <span className="cursor-pointer">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path d="M10 18a8 8 0 110-16 8 8 0 010 16zm3.707-8.707a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" />
                                    </svg>
                                </span>
                                <span className="cursor-pointer">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.894.893l3 3a1 1 0 001.106-.894l-3-3A1 1 0 0011 6z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div className="mb-4">
                            <label
                                htmlFor="propertyType"
                                className="block text-gray-700 text-sm font-bold mb-2 text-start"
                            >
                                Type
                            </label>
                            <select
                                id="propertyType"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={propertyType}
                                onChange={handlePropertyTypeChange}
                            >
                                {propertyTypes.map((type, index) => (
                                    <option key={index} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-4">
                            <label
                                htmlFor="propertyName"
                                className="block text-gray-700 text-sm font-bold mb-2 text-start"
                            >
                                Property Name
                            </label>
                            <input
                                type="text"
                                id="propertyName"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={propertyName}
                                onChange={handlePropertyNameChange}
                                onKeyDown={handleKeyDown}
                                placeholder="Add property name here"
                            />
                        </div>
                        <div className="mb-4">
                            <input
                                type="checkbox"
                                id="displayOnCard"
                                className="form-checkbox text-blue-600"
                                checked={displayOnCard}
                                onChange={handleDisplayOnCardChange}
                            />
                            <label htmlFor="displayOnCard" className="ml-2 text-gray-700 text-[12px] font-bold">
                                Display on Card (available on tags + statuses)
                            </label>
                        </div>
                    </div>
                    <div className="mt-4 add-comment">
                        <div className="flex gap-[15px] items-center border-b border-[#f5f5f5] text-[.7rem] ">
                            <h3 className="text-gray-700 font-bold mb-2 text-[#757575]">Comments</h3>
                            <h3 className="text-gray-700 font-bold mb-2 text-[#757575]">Activity log</h3>
                        </div>
                        <p className="h-[35px] border-b-[5px] border-[#f5f5f5] text-[.7rem] text-start">No comments</p>
                        <div className="flex mt-[15px]">
                            <span className=" left-0 mt-[12px] left-[0px] border-2 w-[25px] h-[25px] rounded-[50%] bg-[#F5F5F5] flex justify-center items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-person"
                                    viewBox="0 0 16 16 ml-[14px] mt-[11px]"
                                >
                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                                </svg>
                            </span>
                            <div className="mb-2 ml-[10px] w-[100%] flex svg-inputbox-relative">
                                <input id="comments" placeholder="Add a comment" className="InputFieldOne emailinput" />
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-telegram mt-[15px]"
                                    viewBox="0 0 16 16"
                                    onClick={() => {
                                        updateTaskMail(gmailMassageID);
                                    }}
                                >
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

TaskData.propTypes = {
    popupTaskData: PropTypes.object.isRequired,
    taskItem: PropTypes.object.isRequired,
    updateTaskMail: PropTypes.func.isRequired,
    gmailMassageID: PropTypes.string.isRequired,
};
export default TaskData;
