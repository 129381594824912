import React, { useState, useEffect } from 'react';
import Board from './Board';
import { TaskContext, ColumnContext } from '../context';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuid } from 'uuid';
import services from '../services';
import Header from '../components/Header';
import InboxData from '../components/Inbox/InboxData';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import socket from '../socket';
import { boardLocal, columnsLocal, tasksLocal, userDataLocal } from '../utils/LocalSt';

const AppViewer = () => {
    const tasksInMemory = tasksLocal() || [];
    const [tasks, setTasks] = useState(tasksInMemory);
    const [taskList, setTaskList] = useState();
    const [filterList, setFilterList] = useState();
    const [selectedEmailsIds, setSelectedEmailsIds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [emailLimit, setEmailLimit] = useState(13);
    const [emailName, setEmailName] = useState([]);
    const [selectedBoardId, setSelectedBoardId] = useState('');
    const [selected, setSelected] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const moduleName = 'board';
    const [columns, setColumns] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedBoard, setSelectedBoard] = useState([]);
    const [localSt, setLocalSt] = useState(boardLocal());
    const [isShiftPressedMail, setIsShiftPressedMail] = useState(false);

    const { role } = userDataLocal();

    const navigate = useNavigate();
    const columnIndexCurrent = useSelector((state) => state.redux.columnIndex);

    useEffect(() => {
        localStorage.setItem('tasks', JSON.stringify(tasks));
        localStorage.setItem('columns', columns[0] == undefined ? JSON.stringify([]) : JSON.stringify(columns));
    }, [tasks, columns]);

    const handleClick = (category) => {
        setSelected(category);
        if (category === 'primary') {
            setOpen();
        }
    };
    useEffect(() => {
        setLocalSt(boardLocal());
    }, [localStorage.getItem('board')]);

    const getColor = (category) => {
        return selected === category ? 'text-blue-500 border-b-4 border-blue-500' : 'text-current';
    };

    const moveTaskUp = (taskId) => {
        setTasks((prevTasks) => {
            const taskIndex = prevTasks.findIndex((task) => task.id === taskId);
            if (taskIndex > 0 && prevTasks[taskIndex].columnId === prevTasks[taskIndex - 1].columnId) {
                const updatedTasks = [...prevTasks];
                [updatedTasks[taskIndex - 1], updatedTasks[taskIndex]] = [
                    updatedTasks[taskIndex],
                    updatedTasks[taskIndex - 1],
                ];
                return updatedTasks;
            }
            return prevTasks;
        });
    };

    const moveTaskDown = (taskId) => {
        setTasks((prevTasks) => {
            const taskIndex = prevTasks.findIndex((task) => task.id === taskId);
            if (
                taskIndex < prevTasks.length - 1 &&
                prevTasks[taskIndex].columnId === prevTasks[taskIndex + 1].columnId
            ) {
                const updatedTasks = [...prevTasks];
                [updatedTasks[taskIndex + 1], updatedTasks[taskIndex]] = [
                    updatedTasks[taskIndex],
                    updatedTasks[taskIndex + 1],
                ];
                return updatedTasks;
            }
            return prevTasks;
        });
    };

    const addTaskToColumn = ({ title, user, columnId, date, deadlineDate }) => {
        const newTask = {
            id: uuid(),
            title,
            user,
            columnId,
            date,
            deadlineDate,
        };

        const updatedTasks = [...tasks, newTask];
        setTasks(updatedTasks);
        localStorage.setItem('tasks', JSON.stringify(updatedTasks));
    };

    const updateTitleInLocalStorage = (itemId, editTaskTitle) => {
        const existingData = tasksLocal() || [];
        const updatedData = existingData?.map((item) => {
            if (item.id === itemId) {
                return { ...item, title: editTaskTitle };
            }
            return item;
        });
        setTasks(updatedData);
        localStorage.setItem('tasks', JSON.stringify(updatedData));
    };

    const handleCheckboxClick = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const updateColunTitleInLocalStorage = (columnID, editColumnTitle) => {
        const existingData = columnsLocal() || [];
        const updatedData = existingData?.map((item) => {
            if (item.id === columnID) {
                return { ...item, title: editColumnTitle };
            }
            return item;
        });
        setColumns(updatedData);
        localStorage.setItem('columns', JSON.stringify(updatedData));
    };

    const updateDeadlineDateInLocalStorage = (itemId, date) => {
        const existingData = tasksLocal() || [];

        const updatedData = existingData?.map((item) => {
            if (item.id === itemId) {
                return { ...item, deadlineDate: date };
            }
            return item;
        });
        setTasks(updatedData);
        localStorage.setItem('tasks', JSON.stringify(updatedData));
    };
    const handleViewDetails = (item) => {
        navigate('/mailinbox', { state: { selectedMail: item } });
    };

    useEffect(() => {
        getTaskMailIndbox();
    }, [selectedBoardId, emailLimit]);

    const getTaskMailIndbox = async () => {
        setLoading(true);
        if (!selectedBoardId && !localSt?.value) {
            return;
        }
        try {
            const data = {
                limit: emailLimit,
                page: 1,
                boardId: selectedBoardId || localSt?.value,
            };

            if (Array.isArray(selectedEmailsIds) && selectedEmailsIds.length > 0) {
                data.gmailUserId = selectedEmailsIds.map((element) => element.value);
            }
            const query = new URLSearchParams(data).toString();
            const response = await services.email.GET_EMAIL_BY_DATE(query, selectedBoardId || localSt?.value);

            if (response) {
                setTaskList(response?.data?.emails);
            }
        } catch (error) {
            console.error('Error fetching task emails:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        socket.on('emailSaved', (message) => {
            getTaskMailIndbox();
        });
        return () => {
            socket.off('emailSaved');
        };
    }, []);

    const handleChange = (selectedOptions) => {
        setSelectedEmailsIds(selectedOptions);
    };

    useEffect(() => {
        getTaskMailIndbox(selectedEmailsIds);
    }, [selectedEmailsIds]);

    const getAllLinkedEmail = async () => {
        try {
            const response = await services.email.Get_The_Linked_Email();
            if (response) {
                const options = response?.data?.map((email) => ({
                    label: email.email,
                    value: email.id,
                    color: email.colour,
                }));
                setFilterList(options);
            }
        } catch (error) {
            console.error('error');
        }
    };

    useEffect(() => {
        getAllLinkedEmail();
    }, []);

    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollTop + clientHeight >= scrollHeight - 50 && !loading) {
            setEmailLimit((prevLimit) => prevLimit + 1);
        }
    };

    const generateMailInbox = () => {
        return taskList?.map((item) => {
            return (
                <InboxData
                    key={item?.id}
                    item={item}
                    open={open}
                    setOpen={setOpen}
                    getTaskMailIndbox={getTaskMailIndbox}
                    onCheckboxClick={handleCheckboxClick}
                    onViewDetails={() => handleViewDetails(item)}
                    setIsShiftPressedMail={setIsShiftPressedMail}
                    isShiftPressedMail={isShiftPressedMail}
                />
            );
        });
    };

    useEffect(() => {
        if (columnIndexCurrent?.from >= 0 ?? columnIndexCurrent?.to >= 0) {
            const updatedColumns = [...columns];
            const [movedColumn] = updatedColumns.splice(columnIndexCurrent?.from, 1);
            updatedColumns.splice(columnIndexCurrent?.to, 0, movedColumn);
            localStorage.setItem('columns', JSON.stringify(updatedColumns));
            setColumns(updatedColumns);

            const sequenceFun = async () => {
                const data =
                    updatedColumns &&
                    updatedColumns?.length > 0 &&
                    updatedColumns?.map((item, index) => ({
                        id: item?.id,
                        sequence: index + 1,
                    }));
                if (data) {
                    try {
                        await services.board.SEQUENCE(data, boardLocal()?.value);
                    } catch (e) {
                        console.error(e);
                    }
                }
            };
            if (updatedColumns[0] != undefined) {
                sequenceFun();
            }
        }
    }, [columnIndexCurrent]);

    const handleAddColumn = async (columnInputAdd) => {
        const obj = {
            coloum: columnInputAdd,
            boardId: selectedBoardId,
            sequence: columns?.length + 1,
        };
        try {
            await services.column.CREATE_COLUMN(obj);
            handleSelectedBoard(selectedBoard);
            toast.success('Column Create Successfully.');
        } catch (e) {
            console.error(e);
        }
    };

    const handleSelectedBoard = async (id) => {
        if (!id?.value) {
            return;
        }
        try {
            const response = await services.board.SELECTED_BOARD(id?.value);
            const data = response?.data?.map((item) => ({
                id: item?.id,
                title: item?.coloum,
                className: item?.colour,
                borderColor: item?.colour,
            }));
            localStorage.setItem('columns', JSON.stringify(data));
            localStorage.setItem('board', JSON.stringify(id));
            setColumns(data);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div>
            <DndProvider backend={HTML5Backend}>
                <div className="h-screen overflow-hidden">
                    <>
                        <Header
                            filterList={filterList}
                            emailName={emailName}
                            handleChange={handleChange}
                            handleSelectedBoard={handleSelectedBoard}
                            setSelectedBoardId={setSelectedBoardId}
                            handleAddColumn={handleAddColumn}
                            selectedBoardId={selectedBoardId}
                            selectedEmailsIds={selectedEmailsIds}
                            role={role}
                            setSelectedBoard={setSelectedBoard}
                            selectedBoard={selectedBoard}
                            getTaskMailIndbox={getTaskMailIndbox}
                        />

                        {role != 'User' ? (
                            <div className="grid grid-cols-[27%_auto] border-2 ">
                                <div className="overflow-y-scroll h-[calc(100vh_-_40px)]">
                                    <div className="flex justify-between px-2.5 mt-6 items-center mb-[10px] border-b-2">
                                        <div
                                            className={`flex gap-1.5 cursor-pointer ${getColor('primary')}`}
                                            onClick={() => handleClick('primary')}
                                        >
                                            <img src="./assets/primary.svg" alt="Promos" />
                                            <span>Primary</span>
                                        </div>
                                        <div
                                            className={`flex gap-1.5 cursor-pointer ${getColor('social')}`}
                                            onClick={() => handleClick('social')}
                                        >
                                            <img src="./assets/social.svg" alt="Promos" />
                                            <span>Social</span>
                                        </div>
                                        <div
                                            className={`flex gap-1.5 cursor-pointer ${getColor('promos')}`}
                                            onClick={() => handleClick('promos')}
                                        >
                                            <img src="./assets/promos.svg" alt="Promos" />
                                            <span>Promos</span>
                                        </div>
                                        <div>
                                            <img src="./assets/details.svg" alt="Promos" />
                                        </div>
                                    </div>

                                    <div onScroll={handleScroll} className="inbox-Mail">
                                        {generateMailInbox()}
                                    </div>
                                </div>
                                <TaskContext.Provider
                                    value={{
                                        tasks,
                                        setTasks,
                                        updateTitleInLocalStorage,
                                        isModalOpen,
                                        handleCloseModal,
                                        getTaskMailIndbox,
                                    }}
                                >
                                    <ColumnContext.Provider
                                        value={{
                                            columns,
                                            setColumns,
                                            addTaskToColumn,
                                            updateColunTitleInLocalStorage,
                                            updateDeadlineDateInLocalStorage,
                                            moveTaskUp,
                                            moveTaskDown,
                                            isModalOpen,
                                            handleCloseModal,
                                            getTaskMailIndbox,
                                        }}
                                    >
                                        <div className="overflow-x-auto h-[100vh] relative ">
                                            <Board isModalOpen={isModalOpen} onClose={handleCloseModal} />
                                        </div>
                                    </ColumnContext.Provider>
                                </TaskContext.Provider>
                            </div>
                        ) : (
                            <div
                                style={role == 'User' ? { marginLeft: '20px' } : {}}
                                className="border-r border-r-solid border-r-gray-400 w-full"
                            >
                                <TaskContext.Provider value={{ tasks, setTasks, updateTitleInLocalStorage }}>
                                    <ColumnContext.Provider
                                        value={{
                                            columns,
                                            setColumns,
                                            addTaskToColumn,
                                            updateColunTitleInLocalStorage,
                                            updateDeadlineDateInLocalStorage,
                                            moveTaskUp,
                                            moveTaskDown,
                                        }}
                                    >
                                        <Board moduleName={moduleName} />
                                    </ColumnContext.Provider>
                                </TaskContext.Provider>
                            </div>
                        )}
                    </>
                </div>
            </DndProvider>
        </div>
    );
};

export default AppViewer;
