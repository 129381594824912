import React, { useEffect, useState } from 'react';
import services from '../../services';
import { toast } from 'react-toastify';
import Loading from '../../utils/Loading';

const ComposeEmail = ({ replyDropdown, subject, selectedMail, setComposeStatus, setLoading }) => {
    const [compose, setCompose] = useState({
        sentTo: '',
        subject: '',
        body: '',
        parentTaskID: '',
        parentTaskStatus: '',
        sentBy: '',
    });
    useEffect(() => {
        setCompose((prev) => ({
            ...prev,
            parentTaskID: selectedMail?.id,
            parentTaskStatus: true,
            sentBy: selectedMail?.Email?.sentBy,
            boardListId: selectedMail?.boardListId,
        }));
    }, [selectedMail]);
    const handleSubmit = async () => {
        try {
            setLoading(true);
            await services.compose.SEND_COMPOSE(compose);
            setComposeStatus(false);
            setLoading(false);
            toast.success('Compose Email Send Successfully...');
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    return (
        <div
            className={`replydropdown mailreply-dropbox ${
                replyDropdown ? 'open' : ''
            }  kanban-dropDown  text-black shadow-lg`}
            style={{ width: '65%' }}
        >
            <div>
                <div className="p-4 border-b border-gray-200">
                    <h2 className="text-lg font-semibold text-gray-700">New Message</h2>
                </div>
                <div className="p-4">
                    <div>
                        <input
                            id="recipients"
                            placeholder="Recipients"
                            type="text"
                            className="mt-1 block w-full px-3 py-2  rounded-md shadow-sm "
                            value={compose?.sentTo}
                            onChange={(e) =>
                                setCompose((prev) => ({
                                    ...prev,
                                    sentTo: e?.target?.value?.trimStart(),
                                }))
                            }
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            id="subject"
                            placeholder="Subject"
                            type="text"
                            className="mt-1 block w-full px-3 py-2   rounded-md shadow-sm "
                            value={compose?.subject}
                            onChange={(e) =>
                                setCompose((prev) => ({
                                    ...prev,
                                    subject: e?.target?.value?.trimStart(),
                                }))
                            }
                        />
                    </div>
                    <div className="flex items-center space-x-2 mb-4">
                        <button className="flex items-center space-x-1 px-3 py-1 bg-blue-100 text-blue-600 rounded-full border border-blue-200">
                            <span className="text-sm">Working on</span>
                        </button>
                        <button className="flex items-center space-x-1 px-3 py-1 bg-blue-100 text-blue-600 rounded-full border border-blue-200">
                            <span className="text-sm">Remind me</span>
                        </button>
                        <button className="px-2 py-1 bg-gray-100 text-gray-600 rounded-full border border-gray-200"></button>
                    </div>
                    <div>{subject}</div>
                    <hr />
                    <div>
                        <textarea
                            style={{ width: '100%', minHeight: '300px', border: 'none' }}
                            autoFocus
                            value={compose?.body}
                            onChange={(e) =>
                                setCompose((prev) => ({
                                    ...prev,
                                    body: e?.target?.value?.trimStart(),
                                }))
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="bg-white p-4">
                <div className="flex gap-[1rem] items-center text-black">
                    <div className="flex">
                        {' '}
                        <button
                            onClick={handleSubmit}
                            className="items-center text-[14px] px-[14px] pt-[4px] pb-[5px] flex bg-[#0B57D0] text-white hover:bg-[#1568D7] hover:text-white rounded-l-[20px] focus:outline-none focus:shadow-outline"
                        >
                            Send
                        </button>
                        <button className="border-l border-[#000] items-center text-[14px] pt-[4px] pb-[5px] px-[14px] flex bg-[#0B57D0] text-white hover:bg-[#1568D7] hover:text-white rounded-r-[20px] focus:outline-none focus:shadow-outline">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-caret-down-fill"
                                viewBox="0 0 16 16"
                            >
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </button>
                    </div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#2d6ec7"
                        className="bi bi-file-earmark-text"
                        viewBox="0 0 16 16"
                    >
                        <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5" />
                        <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                    </svg>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#2d6ec7"
                        className="bi bi-check2-all"
                        viewBox="0 0 16 16"
                    >
                        <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                        <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                    </svg>
                    <span className="font-bold text-[14px] text-black capitalize  pb-1">A</span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-paperclip"
                        viewBox="0 0 16 16"
                    >
                        <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
                    </svg>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-usb-c"
                        viewBox="0 0 16 16"
                    >
                        <path d="M3.5 7.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1z" />
                        <path d="M0 8a3 3 0 0 1 3-3h10a3 3 0 1 1 0 6H3a3 3 0 0 1-3-3m3-2a2 2 0 1 0 0 4h10a2 2 0 1 0 0-4z" />
                    </svg>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-emoji-smile"
                        viewBox="0 0 16 16"
                    >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5" />
                    </svg>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-hdd"
                        viewBox="0 0 16 16"
                    >
                        <path d="M4.5 11a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1M3 10.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
                        <path d="M16 11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V9.51c0-.418.105-.83.305-1.197l2.472-4.531A1.5 1.5 0 0 1 4.094 3h7.812a1.5 1.5 0 0 1 1.317.782l2.472 4.53c.2.368.305.78.305 1.198zM3.655 4.26 1.592 8.043Q1.79 8 2 8h12q.21 0 .408.042L12.345 4.26a.5.5 0 0 0-.439-.26H4.094a.5.5 0 0 0-.44.26zM1 10v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1" />
                    </svg>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-image"
                        viewBox="0 0 16 16"
                    >
                        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                        <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z" />
                    </svg>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-lock"
                        viewBox="0 0 16 16"
                    >
                        <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2M5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1" />
                    </svg>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-pen"
                        viewBox="0 0 16 16"
                    >
                        <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708z" />
                    </svg>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-three-dots-vertical"
                        viewBox="0 0 16 16"
                    >
                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default ComposeEmail;
