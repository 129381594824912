import api from '../api';
import axios from 'axios';
export default {
    CREATE_USER: (data) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.REACT_APP_BACKEND_URL + api.user.CREATE_USER(), data)
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        });
    },
    GET_ALL_USER: (query) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_BACKEND_URL + api.user.GET_ALL_USER(query))
                .then((res) => resolve(res?.data?.data))
                .catch((err) => reject(err));
        });
    },
    DELETE_USER: (id) => {
        return new Promise((resolve, reject) => {
            axios
                .delete(process.env.REACT_APP_BACKEND_URL + api.user.DELETE_USER(id))
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
    UPDATE_USER: (id, data) => {
        return new Promise((resolve, reject) => {
            axios
                .put(process.env.REACT_APP_BACKEND_URL + api.user.UPDATE_USER(id), data)
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
    GET_USER_ID: (id) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_BACKEND_URL + api.user.GET_USER_ID(id))
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
    ASSIGN_GROUP_USER: (data) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.REACT_APP_BACKEND_URL + api.user.ASSIGN_GROUP_USER(), data)
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
};
