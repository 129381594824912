import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    columnIndex: {
        from: '',
        to: '',
    },
    emailReply: '',
    AddColumnCall: '',
};

export const slice = createSlice({
    name: 'redux',
    initialState,
    reducers: {
        columnIndex: (state, { payload }) => {
            state.columnIndex = {
                from: payload?.from,
                to: payload?.to,
            };
        },
        emailReply: (state) => {
            state.emailReply = Math.random();
        },
        AddColumnCall: (state) => {
            console.log(state,"hello")
            state.AddColumnCall = Math.random()
        },
    },
});

export const { columnIndex, emailReply, AddColumnCall } = slice.actions;

export default slice.reducer;
