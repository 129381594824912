import React, { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useSelector } from 'react-redux';

const MyEditor = ({ selectedPrevData, handleEditorChange }) => {
    const emailReplyEvent = useSelector((state) => state.redux.emailReply);
    const [editorData, setEditorData] = useState(
        // "<img src='https://app.applatus.com:9001/uploads/1726662862959-e6ae29dc-3ed0-4390-8320-71932bc7abf6-image.png' />"
    );
    // "<img src='http://192.168.29.43:3000/uploads/1726560315636-d13371cb-2eb5-4033-ad73-fb30b37811e6-Screenshot 2024-05-06 185002.png' />",
    // "<img src='https://dev-reality-pulse.s3.ap-south-1.amazonaws.com/image_97431726560942.jpg' />"
    // "<img src='https://images.unsplash.com/photo-1673859360509-1ef362f94f0c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY3NjMzNjE2OA&ixlib=rb-4.0.3&q=80&w=1080' />"

    useEffect(() => {
        if (selectedPrevData?.templateData) {
            setEditorData(selectedPrevData.templateData);
        }
    }, [selectedPrevData]);

    useEffect(() => {
        if (emailReplyEvent) {
            setEditorData('');
        }
    }, [emailReplyEvent]);

    const editorConfiguration = {
        extraPlugins: [MyCustomUploadAdapterPlugin],
    };

    function MyCustomUploadAdapterPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new MyUploadAdapter(loader);
        };
    }

    class MyUploadAdapter {
        constructor(loader) {
            this.loader = loader;
        }

        upload() {
            return this.loader.file.then((file) => {
                const data = new FormData();
                data.append('image', file);
                return fetch(`${process.env.REACT_APP_BACKEND_URL}/template/singleImage`, {
                    method: 'POST',
                    body: data,
                })
                    .then((response) => response.json())
                    .then((result) => {
                        console.log('result111230', `${process.env.REACT_APP_IMAGE_URL}` + result?.image);
                        return {
                            default: `${process.env.REACT_APP_IMAGE_URL}` + result?.image,
                        };
                    });
            });
        }

        abort() {
            // Handle abort action if needed
        }
    }

    return (
        <div>
            <CKEditor
                config={editorConfiguration}
                editor={ClassicEditor}
                data={editorData}
                onChange={(content, editor) => {
                    const cleanedString = editor.getData().replace(/<\/?figure[^>]*>/g, '');
                    const dtT = cleanedString.replace(/(<img[^>]*)(?<!\/)>/g, '$1 />');
                    console.log('jjjjjjjjjjjjjjjjooo', dtT);
                    handleEditorChange(editor.getData());
                }}
                onReady={(editor) => {
                    editor.editing.view.change((writer) => {
                        writer.setStyle('min-height', '400px', editor.editing.view.document.getRoot());
                    });
                }}
            />
        </div>
    );
};

export default MyEditor;
