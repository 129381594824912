/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import MailInbox from '../MailInbox';
import { useLocation } from 'react-router-dom';
import WhatsApps from '../Whatsapp';
import services from '../../services';
import Header from '../Header';
import { useParams } from 'react-router-dom';

function Whatsapp() {
    const location = useLocation();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [nationality, setNationality] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [preExistingMedicalConditions, setPreExistingMedicalConditions] = useState('');

    const [selectedTab, setSelectedTab] = useState('GMAIL');
    const [selectedEmail, setSelectedEmail] = useState({});
    const [messages, setMessages] = useState('');
    const [dataById, setDataById] = useState({});
    const [chatData, setChat] = useState([]);

    const { id } = useParams();

    const selectedMail = location.state?.selectedMail;
    const selectedtaskID = location.state?.taskID;
    const deadlineDate = location.state?.deadlineDate;

    useEffect(() => {
        if (selectedTab === 'GMAIL') {
            setSelectedEmail(selectedMail || {});
        }
    }, [selectedTab]);

    const sendMessage = async () => {
        const dataObj = {
            messageSendTo: 919340779692,
            messageRecivedBy: 6263210069,
            message: '',
        };

        try {
            const response = await services.Whatsapp.WHATS_APP_EMAIL_SEND(dataObj);

            if (response) {
            }
        } catch (error) {
            console.error('Error fetching task emails:', error);
        }
    };

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleNationalityChange = (e) => {
        setNationality(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };

    const handleDateOfBirthChange = (e) => {
        setDateOfBirth(e.target.value);
    };

    const handlePreExistingMedicalConditionsChange = (e) => {
        setPreExistingMedicalConditions(e.target.value);
    };

    const handleTabChange = (tab) => {
        setSelectedTab(tab);

        if (tab === 'GMAIL') {
            setSelectedEmail({
                email: email,
            });
        } else if (tab === 'WHATSAPP') {
            sendMessage();
        }
    };

    const handleSendMessage = (message) => {
        alert;

        setMessages(message);
    };

    const handleWhatspp = async () => {
        try {
            const data = {
                messageSendTo: 919977220732,
                messageRecivedBy: 9977222732,
                message: messages,
            };
            const response = await services.Whatsapp.WHATS_APP_EMAIL_SEND(data);
        } catch (error) {
            console.error('Error', error);
        }
    };

    const getTaskById = async (id) => {
        try {
            const response = await services.Whatsapp.WHATSAPP_GETTASKBYID(id);
            if (response) {
                setDataById(response?.data?.data);
            }
        } catch (error) {
            console.error('Error fetching task mails:', error);
        }
    };

    useEffect(() => {
        handleWhatspp();
    }, [messages]);

    const handlegetMessage = async () => {
        try {
            const response = await services.Whatsapp.GET_WHATSAPP_MESSAGE();
            if (response) {
                setChat(response?.data?.data);
            }
        } catch (error) {
            console.error('Error', error);
        }
    };

    useEffect(() => {
          if (selectedtaskID) {
            getTaskById(selectedtaskID);
        }
    }, [selectedtaskID]);

    useEffect(() => {
        handlegetMessage();
    }, []);

    return (
        <div className="bg-[#F8F8F8]">
            <div className="bg-white shadow-md">
                <Header />
            </div>
            <div className="container mx-auto p-4 mt-5 ">
                {/* <div className="grid grid-cols-[75%_auto] gap-x-5">
                    <div>
                        <div className="grid grid-cols-3  gap-x-4">
                            <div className="bg-white rounded-[6px] shadow-md p-4">
                                <h2 className="flex justify-between items-center text-[14px] mb-2 text-black">
                                    <span> Booking status</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-clock"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                                    </svg>
                                </h2>

                                <h4 className="font-bold text-black" value="Initial enquiry">
                                    Initial enquiry
                                </h4>
                            </div>
                            <div className="bg-white rounded-[6px] shadow-md p-4">
                                <h2 className="text-[14px] mb-2 text-black">Reference ID</h2>
                                <h3 className="text-[14px] font-bold mb-2 text-black">1090519-10RPZ</h3>
                            </div>
                            <div className="bg-white rounded-[6px] shadow-md p-4">
                                <h2 className="text-[14px] mb-2 text-black">Enquiry type</h2>
                                <h3 className="text-[14px] font-bold mb-2 text-black">Question</h3>
                            </div>
                        </div>
                        <div className=" mt-[1rem] gap-x-4">
                            <div className="mt-4 bg-white rounded-[6px] shadow-md p-4">
                                <div className="">
                                    <h2 className="text-[18px] text-black font-bold mb-4">Traveller details</h2>
                                </div>

                                <div className="grid grid-cols-2 gap-4 input-force">
                                    <div>
                                        <label
                                            htmlFor="gender"
                                            className="block mb-[0.4rem] text-gray-600 text-sm font-i"
                                        >
                                            Gender
                                        </label>
                                        <input
                                            type="email"
                                            id="email"
                                            className="h-10 w-full border-r  text-gray-900 font-i focus:outline-none p-3 border-i input-border"
                                            value={email}
                                            onChange={handleEmailChange}
                                        />
                                    </div>

                                    <div>
                                        <label
                                            htmlFor="email"
                                            className="block mb-[0.4rem] text-gray-600 text-sm font-i"
                                        >
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            id="email"
                                            className="h-10 w-full border-r  text-gray-900 font-i focus:outline-none p-3 border-i input-border"
                                            value={email}
                                            onChange={handleEmailChange}
                                        />
                                    </div>

                                    <div>
                                        <label
                                            htmlFor="firstName"
                                            className="block mb-[0.4rem] text-gray-600 text-sm font-i"
                                        >
                                            First Name (as per Passport)
                                        </label>
                                        <input
                                            type="text"
                                            id="firstName"
                                            className="h-10 w-full border-r  text-gray-900 font-i focus:outline-none p-3 border-i input-border"
                                            value={firstName}
                                            onChange={handleFirstNameChange}
                                        />
                                    </div>

                                    <div>
                                        <label
                                            htmlFor="phone"
                                            className="block mb-[0.4rem] text-gray-600 text-sm font-i"
                                        >
                                            Phone
                                        </label>
                                        <input
                                            type="tel"
                                            id="phone"
                                            className="h-10 w-full border-r  text-gray-900 font-i focus:outline-none p-3 border-i input-border"
                                            value={phone}
                                            onChange={handlePhoneChange}
                                        />
                                    </div>

                                    <div>
                                        <label
                                            htmlFor="lastName"
                                            className="block mb-[0.4rem] text-gray-600 text-sm font-i"
                                        >
                                            Last Name (as per Passport)
                                        </label>
                                        <input
                                            type="text"
                                            id="lastName"
                                            className="h-10 w-full border-r  text-gray-900 font-i focus:outline-none p-3 border-i input-border"
                                            value={lastName}
                                            onChange={handleLastNameChange}
                                        />
                                    </div>

                                    <div>
                                        <label
                                            htmlFor="nationality"
                                            className="block mb-[0.4rem] text-gray-600 text-sm font-i"
                                        >
                                            Nationality
                                        </label>
                                        <input
                                            type="text"
                                            id="nationality"
                                            className="h-10 w-full border-r  text-gray-900 font-i focus:outline-none p-3 border-i input-border"
                                            value={nationality}
                                            onChange={handleNationalityChange}
                                        />
                                    </div>

                                    <div>
                                        <label
                                            htmlFor="preExistingMedicalConditions"
                                            className="block mb-[0.4rem] text-gray-600 text-sm font-i"
                                        >
                                            Pre-existing medical conditions
                                        </label>
                                        <textarea
                                            id="preExistingMedicalConditions"
                                            rows={3}
                                            className=" w-full border-r  text-gray-900 font-i focus:outline-none p-3 border-i input-border"
                                            value={preExistingMedicalConditions}
                                            onChange={handlePreExistingMedicalConditionsChange}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="bg-white rounded-[6px] shadow-md p-4">
                            <h2 className="text-[14px] mb-2 text-black">Enquiry Status</h2>
                            <h3 className="text-[14px] font-bold mb-2 text-black">Open</h3>
                        </div>
                        <div className="bg-white rounded-[6px] shadow-md p-4 mt-[15px]">
                            <h2 className="text-[14px] mb-2 text-black">Attachments</h2>
                        </div>
                    </div>
                </div> */}

                <div className="mt-4 bg-white rounded-[6px] shadow-md p-4">
                    {/* <h2 className="text-[18px] text-black font-bold mb-4">Conversation</h2>
                    <div className="flex mb-2 tap-button-o">
                        <button
                            className={`py-2 px-4 w-[180px] rounded-l-[4px] ${
                                selectedTab === 'GMAIL'
                                    ? ' text-white border-l border-y border-[#2d6ec7] bg-[#2d6ec7]'
                                    : 'text-[#2d6ec7] border-y border-l border-[#2d6ec7]'
                            }`}
                            onClick={() => handleTabChange('GMAIL')}
                        >
                            GMAIL
                        </button>
                        <button
                            className={`py-2 px-4 w-[180px] ${
                                selectedTab === 'WHATSAPP'
                                    ? ' text-white border border-[rgb(45,110,199)] bg-[#2d6ec7]'
                                    : 'text-[#2d6ec7] border-y border-[#2d6ec7]'
                            }`}
                            onClick={() => handleTabChange('WHATSAPP')}
                        >
                            WHATSAPP
                        </button>
                        <button
                            className={`py-2 px-4 w-[180px] ${
                                selectedTab === 'INTERNAL NOTES'
                                    ? ' text-white border border-[#2d6ec7] bg-[#2d6ec7]'
                                    : 'text-[#2d6ec7] border-y border-[#2d6ec7]'
                            }`}
                            onClick={() => handleTabChange('INTERNAL NOTES')}
                        >
                            INTERNAL NOTES
                        </button>
                        <button
                            className={`w-[180px] rounded-r-[4px]  ${
                                selectedTab === 'All'
                                    ? ' text-white border border-[#2d6ec7] bg-[#2d6ec7]'
                                    : 'text-[#2d6ec7] border-y border-r border-[#2d6ec7]'
                            } py-2 px-4 `}
                            onClick={() => handleTabChange('All')}
                        >
                            All
                        </button>
                    </div> */}
                    <div>
                        {selectedTab === 'GMAIL' && (
                            <div className="bg-white   p-4 mt-4">
                                <MailInbox
                                    selectedEmailData={selectedEmail}
                                    deadlineDate={deadlineDate}
                                    dataById={dataById}
                                />
                            </div>
                        )}
                    </div>
                    {/* <div>
                        {selectedTab === 'WHATSAPP' && (
                            <div className="mt-4 rounded-[6px]  ">
                                <WhatsApps chatData={chatData} onSendMessage={handleSendMessage} />
                            </div>
                        )}
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Whatsapp;
