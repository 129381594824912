import api from '../api';
import axios from 'axios';

export default {
    CREATE_TASK: (data) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.REACT_APP_BACKEND_URL + api.Task.CREATE_TASK(), data)
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        });
    },

    GET_TASK: (query) => {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;

            axios
                .get(process.env.REACT_APP_BACKEND_URL + api.Task.GET_TASK(query))
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        });
    },

    UPDATE_TASK: (id, data) => {
        return new Promise((resolve, reject) => {
            axios
                .put(`${process.env.REACT_APP_BACKEND_URL}+${api.Task.UPDATE_TASK}${id}`, data)
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        });
    },
    DELETE_TASK: (id) => {
        return new Promise((resolve, reject) => {
            axios
                .delete(process.env.REACT_APP_BACKEND_URL + api.Task.DELETE_TASK(id))
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        });
    },
    UPDATE_CHILD_TASK: (id, data) => {
        return new Promise((resolve, reject) => {
            axios
                .put(`${process.env.REACT_APP_BACKEND_URL}` + api.Task.UPDATE_CHILD_TASK(id), data)
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        });
    },
};
