import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MultiSelect } from 'react-multi-select-component';
import axios from 'axios';
import Select from 'react-dropdown-select';
import AddBoardModal from './AddBoard/AddBoard';
import AddColumnModal from './AddBoard/AddColumnModal';
import AddAutoMation from './Automation/Automation';
import Automations from './Automation/Automations';
import services from '../services';
import { boardLocal } from '../utils/LocalSt';
import { useSelector } from 'react-redux';

const Header = ({
    filterList,
    emailName,
    handleChange,
    handleSelectedBoard,
    setSelectedBoardId,
    handleAddColumn,
    selectedBoardId,
    selectedEmailsIds,
    role,
    setSelectedBoard,
    selectedBoard,
    getTaskMailIndbox,
}) => {
    const [boardName, setBoardName] = useState([]);
    const [boardStatus, setBoardStatus] = useState(false);
    const [columnAddStatus, setColumnAddStatus] = useState(false);

    const [automtStatus, setAutomtStatus] = useState(false);

    const navigate = useNavigate();
    const [activeButton, setActiveButton] = useState(null);
    const handleClick = (button) => {
        setActiveButton(button);
        if (button === 'board') {
            navigate('/app');
        } else if (button === 'date') {
            navigate('/dateplan');
        } else if (button === 'table') {
            navigate('/tabledata');
        }
    };
    const handleUser = () => {
        navigate('/user');
    };

    const [isOpenDeadline, setIsOpenDeadline] = useState(false);
    const [isLogoutUser, setIsLogoutUser] = useState(false);
    const [automations, setAutomations] = useState(false);

    const getButtonClass = (button) =>
        `p-[7px] ${
            activeButton === button ? 'bg-[#20212414]' : 'bg-white'
        } mr-[5px] items-center flex cursor-pointer text-black gap-[4px] rounded-[5px]`;

    const filterdropdown = () => {
        setIsOpenDeadline(!isOpenDeadline);
    };

    const logoutdropdown = () => {
        setIsLogoutUser(!isLogoutUser);
    };

    const handleLogout = () => {
        localStorage.removeItem('access_token');
        axios.defaults.headers.common['Authorization'] = '';
        window.location.replace('/login');
        localStorage.clear('');
    };

    const getAllBoard = async () => {
        try {
            const { data } = await services.board.GETALLBOARD();
            const mapData = data?.map(({ boardName, id }) => ({ label: boardName, value: id }));
            setBoardName(mapData);

            if (!localStorage.getItem('board')) {
                setSelectedBoard(mapData[0]);
                setSelectedBoardId(mapData[0]?.value);
                handleSelectedBoard(mapData[0]?.value);
                localStorage.setItem('board', JSON.stringify(mapData[0]));
            } else {
                const BoardData = boardLocal();
                setSelectedBoard(BoardData);
                setSelectedBoardId(BoardData?.value);
                handleSelectedBoard(BoardData?.value);
            }
        } catch (e) {
            console.error(e);
        }
    };
    useEffect(() => {
        getAllBoard();
    }, []);

    const handleAutomation = () => {};

    const columnCall = useSelector((state) => state.redux.AddColumnCall);

    useEffect(() => {
        columnCall && setColumnAddStatus(true);
    }, [columnCall]);
    return (
        <div>
            <div className="header-app flex justify-between items-center">
                <div>
                    {filterList && role != 'User' && (
                        <div className="drop-header">
                            <MultiSelect
                                options={filterList}
                                value={selectedEmailsIds}
                                onChange={handleChange}
                                labelledBy="Select Emails"
                            />
                        </div>
                    )}
                </div>
                <div className="header-item  flex justify-center  my-[20px] items-center">
                    <div onClick={() => handleClick('board')} className={getButtonClass('board')}>
                        {/* <img src="./assets/board.svg" alt="board" /> */}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            fill="currentColor"
                            className="bi bi-filter-right rotate-[0deg]"
                            viewBox="0 0 16 16"
                        >
                            <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5m0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5m0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5" />
                        </svg>
                        <span className="text-[11.2px]">Board</span>
                    </div>
                    <div onClick={() => handleClick('table')} className={getButtonClass('table')}>
                        {/* <img src="./assets/table.svg" alt="table" /> */}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="13"
                            fill="currentColor"
                            className="bi bi-calendar2-range"
                            viewBox="0 0 16 16"
                        >
                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z" />
                            <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5zM9 8a1 1 0 0 1 1-1h5v2h-5a1 1 0 0 1-1-1m-8 2h4a1 1 0 1 1 0 2H1z" />
                        </svg>
                        <button className="text-[11.2px]">Table</button>
                    </div>
                    <div onClick={() => handleClick('date')} className={getButtonClass('date')}>
                        {/* <img src="./assets/date.svg" alt="date" /> */}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="13"
                            fill="currentColor"
                            className="bi bi-calendar3-event"
                            viewBox="0 0 16 16"
                        >
                            <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z" />
                            <path d="M12 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                        </svg>
                        <button className="text-[11.2px]">Date</button>
                    </div>
                    <div className=" text-[black] flex items-center">
                        <div
                            className="px-[8px] py-[4px] mr-[5px]"
                            onClick={logoutdropdown}
                            style={{ cursor: 'pointer' }}
                        >
                            {/* <img src="./assets/LogoutDot.svg" alt="Logout"  /> */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                fill="currentColor"
                                className="bi bi-three-dots-vertical "
                                viewBox="0 0 16 16"
                            >
                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                            </svg>
                        </div>
                        {/* <div className="relative py-[4px] px-[8px]">
                            <img src="./assets/details.svg" alt="details" onClick={filterdropdown} />
                            <div onClick={filterdropdown} className={`${isOpenDeadline ? 'block' : 'hidden'}`}>
                                <div className="layout-backdrop"></div>
                                <div
                                    className={`dropdown !absolute ${
                                        isOpenDeadline ? 'open' : ''
                                    } deadline-day deadend-start-w kanban-dropDown !m-0 !p-0 `}
                                >
                                    <div className="bg-white text-[12px] rounded-[5px] px-[10px] my-[5px] text-[black]">
                                        <div className="flex justify-between items-center pb-[7px]">
                                            <h2 className=" font-bold ">TASK STATUS</h2>
                                            <button className="text-blue-500 hover:underline">Reset</button>
                                        </div>

                                        <div className="flex flex-col ">
                                            <label className="grid grid-cols-[10%_auto] items-center pb-[7px]">
                                                <span>
                                                    <img src="./assets/right.svg" alt="right" />
                                                </span>
                                                <span className="font-medium">Completed</span>
                                            </label>
                                            <label className="grid grid-cols-[10%_auto] items-center pb-[7px]">
                                                <span>
                                                    <img src="./assets/right.svg" alt="right" />
                                                </span>
                                                <span className="font-medium">Open</span>
                                            </label>
                                        </div>

                                        <h2 className="font-bold pb-[7px]">EMAIL STATE</h2>
                                        <div className="flex flex-col">
                                            <label className="grid grid-cols-[10%_auto] pb-[7px] items-center">
                                                <span>
                                                    <img src="./assets/right.svg" alt="right" />
                                                </span>
                                                <span className="font-medium">Read</span>
                                            </label>
                                            <label className="grid grid-cols-[10%_auto] pb-[7px] items-center">
                                                <span>
                                                    <img src="./assets/right.svg" alt="right" />
                                                </span>
                                                <span className="font-medium">Unread</span>
                                            </label>
                                        </div>

                                        <h2 className="font-bold pb-[7px]">PEOPLE</h2>
                                        <div className="flex flex-col ">
                                            <label className="grid grid-cols-[10%_auto] pb-[7px] items-center">
                                                <span>
                                                    <img src="./assets/right.svg" alt="right" />
                                                </span>
                                                <span className="font-medium">Me</span>
                                            </label>
                                            <label className="grid grid-cols-[10%_auto] pb-[7px] items-center">
                                                <span>
                                                    <img src="./assets/right.svg" alt="right" />
                                                </span>
                                                <span className="font-medium">Unassigned</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div onClick={logoutdropdown} className={`${isLogoutUser ? 'block' : 'hidden'}`}>
                            <div className="layout-backdrop"></div>

                            <div
                                className={`dropdown !absolute ${
                                    isLogoutUser ? 'open' : ''
                                } deadline-day deadend-start-w move-bottom kanban-dropDown border-2 border-[#F5F5F5] text-black`}
                            >
                                <div className="border-b-[3px] border-[#F5F5F5] rounded-[4px] hover:bg-[#f3f3f3]">
                                    <button onClick={handleLogout} className="p-[2px] text-black text-[12px]">
                                        Log Out
                                    </button>
                                </div>
                                <div className="rounded-[4px] hover:bg-[#f3f3f3]">
                                    <button
                                        onClick={() => navigate('/template')}
                                        className="p-[2px] text-black text-[12px]"
                                    >
                                        Template
                                    </button>
                                </div>
                                <div className="rounded-[4px] hover:bg-[#f3f3f3]">
                                    <button
                                        onClick={() => navigate('/usermanage')}
                                        className="p-[2px] text-black text-[12px]"
                                    >
                                        User management
                                    </button>
                                </div>
                                <div className="rounded-[4px] hover:bg-[#f3f3f3]">
                                    <button
                                        onClick={() => navigate('/group')}
                                        className="p-[2px] text-black text-[12px]"
                                    >
                                        Group
                                    </button>
                                </div>

                                <div className="rounded-[4px] hover:bg-[#f3f3f3]">
                                    <button
                                        onClick={() => navigate('/permissions')}
                                        className="p-[2px] text-black text-[12px]"
                                    >
                                        Permissions
                                    </button>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div className="rounded-[4px] hover:bg-[#f3f3f3]">
                                        <button
                                            onClick={() => navigate('/automations')}
                                            className="p-[2px] text-black text-[12px]"
                                        >
                                            <img src="assets/eye.svg" alt="eye" style={{marginTop:"6px"}} />
                                        </button>
                                    </div>
                                    <div className="rounded-[4px] hover:bg-[#f3f3f3]" style={{marginLeft:"10px"}}>
                                        <button
                                            onClick={() => setAutomations(!automations)}
                                            className="p-[2px] text-black text-[12px]"
                                        >
                                            Automations
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    {selectedBoardId && (
                        <div onClick={() => setAutomtStatus(true)} className={getButtonClass('board')}>
                            {/* <img src="./assets/board.svg" alt="Column" /> */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                fill="currentColor"
                                className="bi bi-filter-right rotate-[0deg]"
                                viewBox="0 0 16 16"
                            >
                                <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5m0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5m0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5" />
                            </svg>
                            <span className="text-[11.2px]">Automation</span>
                        </div>
                    )}
                    {/* {selectedBoardId && (
                        <div onClick={() => setColumnAddStatus(true)} className={getButtonClass('board')}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                fill="currentColor"
                                className="bi bi-filter-right rotate-[0deg]"
                                viewBox="0 0 16 16"
                            >
                                <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5m0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5m0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5" />
                            </svg>
                            <span className="text-[11.2px]">Add Column</span>
                        </div>
                    )} */}
                    <div onClick={() => setBoardStatus(true)} className={getButtonClass('board')}>
                        {/* <img src="./assets/board.svg" alt="board" /> */}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            fill="currentColor"
                            className="bi bi-filter-right rotate-[0deg]"
                            viewBox="0 0 16 16"
                        >
                            <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5m0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5m0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5" />
                        </svg>
                        <span className="text-[11.2px]">Add Board</span>
                    </div>

                    <div>
                        <Select
                            style={{ width: '300px' }}
                            options={boardName}
                            onChange={(e) => {
                                setSelectedBoardId(e[0]?.value);
                                handleSelectedBoard(e[0]);
                                setSelectedBoard(e[0]);
                            }}
                            values={selectedBoard ? [selectedBoard] : []}
                        />
                    </div>
                </div>
                <div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-search mr-[25px]"
                        viewBox="0 0 16 16"
                    >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                    </svg>
                </div>
            </div>
            {boardStatus && (
                <AddBoardModal show={boardStatus} onHide={() => setBoardStatus(false)} getAllBoard={getAllBoard} />
            )}
            {columnAddStatus && (
                <AddColumnModal
                    show={columnAddStatus}
                    onHide={() => setColumnAddStatus(false)}
                    handleAddColumn={handleAddColumn}
                />
            )}
            {automtStatus && (
                <AddAutoMation
                    show={automtStatus}
                    onHide={() => setAutomtStatus(false)}
                    handleAddColumn={handleAutomation}
                    selectedBoard={selectedBoard}
                    getTaskMailIndbox={getTaskMailIndbox}
                />
            )}
            <div>
                {automations && (
                    <Automations
                        show={automations}
                        onHide={() => setAutomations(false)}
                        boardName={boardName}
                        gmailUsers={filterList}
                    />
                )}
            </div>{' '}
        </div>
    );
};

export default Header;
