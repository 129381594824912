import api from '../api';
import axios from 'axios';
export default {
    CREATE_AUTOMATION: (data) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.REACT_APP_BACKEND_URL + api.automation.CREATE_AUTOMATION(), data)
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        });
    },
    GET_ALL_AUTOMATION: () => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_BACKEND_URL + api.automation.GET_ALL_AUTOMATION())
                .then((res) => resolve(res?.data?.data))
                .catch((err) => reject(err));
        });
    },
    DELETE_AUTOMATION: (id) => {
        return new Promise((resolve, reject) => {
            axios
                .delete(process.env.REACT_APP_BACKEND_URL + api.automation.DELETE_AUTOMATION(id))
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
    UPDATE_AUTOMATION: (id, data) => {
        return new Promise((resolve, reject) => {
            axios
                .put(process.env.REACT_APP_BACKEND_URL + api.automation.UPDATE_AUTOMATION(id), data)
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
    GET_AUTOMATION_ID: (id) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_BACKEND_URL + api.automation.GET_AUTOMATION_ID(id))
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
};
