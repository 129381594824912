import React from 'react';

const ChildTask = ({ item, moveChildTask }) => {
    return (
        <div className="">
            <div className="grid-disp">
                {/* <div>
                    <img src="assets/email.svg" />
                </div> */}
                <h3 className="title-box">{item?.title ? item?.title : item?.subject}</h3>

                <div className="date-align">
                    <span data-no-popup>
                        <img alt="Remove" src="assets/popout.svg" onClick={() => moveChildTask(item, '', true)} />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ChildTask;
