import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faArrowLeftLong, faArrowDownLong, faArrowUpLong } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { TaskContext } from './../context';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import ConfirmationPopup from './ConfirmationPopup';
import TaskData from './Task/TaskData';
import { useNavigate } from 'react-router-dom';
import CONSTANTS from '../CONSTANTS.json';
import services from '../services';
import ChildTask from './ChildTask';

const today = new Date();

const allDaysData = [
    {
        deadline: 'Today',
        dayName: CONSTANTS.daysOfWeek[today.getDay()],
    },
    {
        deadline: 'Tomorrow',
        dayName: CONSTANTS.daysOfWeek[(today.getDay() + 1) % 7],
    },
    {
        deadline: 'In two days',
        dayName: CONSTANTS.daysOfWeek[(today.getDay() + 2) % 7],
    },
    {
        deadline: 'Next week',
        dayName: CONSTANTS.daysOfWeek[(today.getDay() + 5) % 7],
    },
    {
        deadline: 'Pick a date',
        dayName: '',
    },
    {
        deadline: 'Add this item to My Day plan',
        dayName: '',
    },
];
function Task(props) {
    const { updateDeadlineDateInLocalStorage, moduleName } = React.useContext(TaskContext);
    const {
        item,
        moveTaskToColumn,
        indexId,
        removeTask,
        moveTaskDown,
        moveTaskUp,
        getTaskMail,
        columnTitle,
        setTaskDetailsPopup,
        taskDeadline,
        userAssigneeName,
        taskColor,
        taskAssign,
        setSearchTerm,
        searchTerm,
        isShiftPressed,
        moveChildTask,
        columnID,
        handleRemoveChild,
        setIsShiftPressed,
        getTaskMailIndbox,
    } = props;
    const [taskToDelete, setTaskToDelete] = useState(null);

    const [showBox, setShowBox] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [activePatch, setActivePatch] = useState(null);
    const [colorIndexID, setColorIndexID] = useState();
    const [hoverIndex, setHoverIndex] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDeadline, setIsOpenDeadline] = useState(false);
    const [isProgress, setIsProgress] = useState(false);
    const [updateTitle, setUpdateTitle] = useState(false);
    const [editTaskTitle, setEditTaskTitle] = useState(item?.title || item?.subject);
    const [deadline, setDeadline] = useState('');

    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [showTime, setShowTime] = useState(false);
    const [selectedTaskColor, setSelectedTaskcolor] = useState('');
    const [taskitemasign, setTaskitemasign] = useState('');
    const [weedDays, setWeekDays] = useState(false);
    const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);
    const [checked, setChecked] = React.useState(false);
    const [currentTask, setCurrentTask] = useState(null);
    const [taskDeleteId, setTaskDeleteId] = useState('');

    const [selectedUserId, setSelectedUserId] = useState(null);
    const [assignedUserName, setAssignedUserName] = useState('');
    const [userName, setUserName] = useState('');
    let clickTimeout = null;
    const popupRef = useRef(null);

    const handleChange = () => {
        setChecked(!checked);
    };

    const handleSingleClick = (item, e) => {
        if (clickTimeout) {
            clearTimeout(clickTimeout);
        }
        clickTimeout = setTimeout(() => {
            if (e.type === 'click') {
                handleTaskDetailPopup(item, e);
            }
        }, 250);
    };

    const handleDoubleClick = (item, e) => {
        if (clickTimeout) {
            clearTimeout(clickTimeout);
        }
        setUpdateTitle(true);
        setCurrentTask(false);
    };

    const navigate = useNavigate();

    const itemId = item?.id;

    const updateTaskMail = async (id, dedlineData) => {
        const msgId = item?.gmailMessageId;
        const data = {
            deadline: dedlineData,
            taskStatus: columnTitle,
            title: editTaskTitle,
            gmailMessageId: msgId,
            userId: selectedUserId,
        };

        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/task/updateTask/${id}`, data);
            if (response) {
                setUpdateTitle(false);
                getTaskMail();
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    };

    const selectedColor = CONSTANTS?.color?.find((color) => color.value === taskColor);
    const borderColor = selectedColor ? selectedColor.value1 : '0px';

    const deleteTaskMail = async () => {
        try {
            const response = await services.task.DELETE_TASK(taskDeleteId);
            if (response) {
                getTaskMail();
                setShowBox(false);
            }
        } catch (error) {
            console.error('Error fetching task emails:', error);
        }
    };

    const filteredUsers = taskAssign.filter((user) => user.name.toLowerCase());
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            updateTaskMail(item?.id);
        }
    };

    const handleClick = () => {
        setIsOpen(false);
    };

    const [{ isDragging }, drag] = useDrag({
        type: !isShiftPressed ? 'task' : 'childTask',
        item: !isShiftPressed ? { id: item.id, indexId, taskStatus: item?.taskStatus } : { isShiftPressed, item },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const handleDateChange = (date) => {
        updateDeadlineDateInLocalStorage(itemId, date);

        setSelectedDate(date);
        setIsCalendarOpen(false);
        setIsOpenDeadline(!isOpenDeadline);
    };

    const toggleDropdown = (id, item) => {
        setEditTaskTitle(item?.subject);
        setSelectedTaskcolor(id);
        setIsOpen(!isOpen);
        setTaskDeleteId(id);
    };

    const deadlineDrop = () => {
        setShowTime(!showTime);
        setIsOpenDeadline(!isOpenDeadline);
    };

    const inTaskProgress = () => {
        setIsProgress(!isProgress);
    };

    const clearHighlight = () => {
        handleTaskSelectedColor('');
    };

    const handleDeadlineChange = (e) => {
        setDeadline(e.target.value);
    };

    const handleCancelDelete = () => {
        setTaskToDelete(null);
        setShowBox(false);
    };

    const handleToggleSearchBox = (itemData) => {
        setTaskitemasign(itemData);
        setIsSearchBoxOpen((prev) => !prev);
    };

    const handleEditTitle = () => {
        setUpdateTitle(true);
        setIsOpen(!isOpen);
        setIsOpenDeadline(false);
    };

    const handleTodayClick = () => {
        setShowTime(true);
        setWeekDays(true);
        const today = new Date();
        const dedlineData = today.toISOString();
        setDeadline(dedlineData);
        setIsOpenDeadline(false);
        updateTaskMail(item?.id, dedlineData);
    };

    const handleTomorrowClick = () => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        setShowTime(true);
        setWeekDays(true);
        setIsOpenDeadline(false);
        const dedlineData = tomorrow.toISOString();
        setDeadline(dedlineData);
        updateTaskMail(item?.id, dedlineData);
    };

    const handleNextTwoDaysClick = () => {
        const nextTwoDays = new Date();
        nextTwoDays.setDate(nextTwoDays.getDate() + 2);
        setShowTime(true);
        setWeekDays(true);
        setIsOpenDeadline(false);
        const dedlineData = nextTwoDays.toISOString();

        setDeadline(dedlineData);
        updateTaskMail(item?.id, dedlineData);
    };

    const handleNextWeekDaysClick = () => {
        const nextWeek = new Date();
        nextWeek.setDate(nextWeek.getDate() + 4);
        setShowTime(true);
        setWeekDays(true);
        setIsOpenDeadline(false);
        const dedlineData = nextWeek.toISOString();

        setDeadline(dedlineData);
        updateTaskMail(item?.id, dedlineData);
    };
    const handleInputBlur = () => {
        setUpdateTitle(true);
        setCurrentTask(false);
    };

    const handleTaskDetailPopup = (item, event) => {
        if (event.target.closest('[data-no-popup]')) {
            return;
        }
        if (!updateTitle) {
            navigate(`/detail/${itemId}`, {
                state: { selectedMail: item, deadlineDate: item?.deadline, taskID: itemId, clickMailTask: true },
            });
        }
        if (currentTask?.gmailMessageId === item.gmailMessageId) {
            setCurrentTask(null);
        } else {
            setCurrentTask(item);
        }
    };

    const handleTaskSelectedColor = async (selectedColor) => {
        const data = {
            color: selectedColor,
        };
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/task/updateTask/${selectedTaskColor}`,
                data,
            );
            getTaskMail();
            setIsSearchBoxOpen(false);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleUserSelect = async (user) => {
        const data = {
            userId: user,
        };
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/task/updateTask/${taskitemasign?.id}`,
                data,
            );
            getTaskMail();
            setIsSearchBoxOpen(false);
        } catch (error) {
            console.error('err');
        }
    };

    useEffect(() => {
        if (taskColor) {
            setActivePatch(taskColor);
        }
    }, [taskColor]);

    useEffect(() => {
        const getUserById = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/getAllUser`);
                if (response?.data?.data?.users) {
                    const user = response.data.data.users.find((u) => u.id === userAssigneeName);
                    if (user) {
                        setUserName(user.name);
                    }
                }
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };

        if (userAssigneeName) {
            getUserById();
        }
    }, [userAssigneeName]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.closest('.deadline-btn')) {
                return;
            }

            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setCurrentTask(null);
            }
        };

        window.addEventListener('mousedown', handleClickOutside);
        return () => window.removeEventListener('mousedown', handleClickOutside);
    }, [currentTask]);

    useEffect(() => {
        setEditTaskTitle(item?.title);
    }, [item?.title]);

    useEffect(() => {
        if (item?.deadline) {
            setDeadline(moment(item?.deadline).format('MMM D'));
        }
    }, [item]);
    const setIsSearchBoxClose = () => {
        setIsSearchBoxOpen(false);
    };

    const [, drop] = useDrop({
        accept: 'childTask',
        drop: (dragItem) => {
            if (dragItem?.isShiftPressed) {
                if (dragItem?.taskForCreate) {
                    handleCreateTask(dragItem, item);
                } else {
                    moveChildTask(dragItem, item);
                }
            }
        },
    });
    const handleCreateTask = async (dragItem, item) => {
        try {
            const { data } = await services.task.CREATE_TASK({ gmailMessageId: dragItem?.item?.gmailMessageId });
            await moveChildTask({ item: { id: data?.data?.id } }, item);
            getTaskMailIndbox();
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div
            className="task-crat"
            style={{
                isDragging,
                backgroundColor: taskColor ? taskColor : indexId === colorIndexID && status ? activePatch : hoverIndex,
                borderLeft: `5px solid ${borderColor === '0px' ? 'transparent' : borderColor}`,
            }}
        >
            <div
                onClick={(e) => {
                    handleSingleClick(item, e);
                }}
            >
                <div ref={(node) => drag(drop(node))} className="">
                    {isCalendarOpen && (
                        <div className="pick-A-calendar">
                            <DatePicker selected={selectedDate} onChange={handleDateChange} inline />
                        </div>
                    )}

                    <div className="">
                        <>
                            <div className="">
                                <div className="flex">
                                    {moduleName === 'board' ? (
                                        <></>
                                    ) : moduleName === 'datePaln' ? (
                                        <>{item?.deadline ? moment(item.deadline).format('MMM DD') : ''}</>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div className="grid-disp">
                                    {!updateTitle ? (
                                        <>
                                            <h3
                                                className="title-box"
                                                onDoubleClick={(e) => {
                                                    handleDoubleClick(item, e);
                                                }}
                                            >
                                                <div style={{ display: 'flex' }}>
                                                    <div
                                                        onMouseEnter={() => setIsShiftPressed(true)}
                                                        onMouseLeave={() => setIsShiftPressed(false)}
                                                        data-no-popup
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <img src="assets/copy.svg" alt="copy" />
                                                    </div>
                                                    <div>{item?.title ? item?.title : item?.subject}</div>
                                                </div>
                                            </h3>
                                        </>
                                    ) : (
                                        <>
                                            <textarea
                                                className="resize-textarea input-static1 outline-text !h-auto overflow-hidden resize-none focus:border-none !focus:outline-none placeholder:text-[#000]"
                                                rows={1}
                                                placeholder="Task title"
                                                value={editTaskTitle}
                                                onChange={(e) => setEditTaskTitle(e.target.value)}
                                                onKeyDown={handleKeyDown}
                                                onBlur={() => {
                                                    handleInputBlur();
                                                }}
                                                data-no-popup
                                                autoFocus
                                            />
                                        </>
                                    )}
                                    <div className="date-align">
                                        <span className="text-black text-[12px]">
                                            {item?.createdAt ? moment(item.createdAt).format('MMM DD') : ''}
                                        </span>

                                        <div className="calendar-dropdown calendar-start">
                                            <div className="absolute-span flex">
                                                {moduleName === 'board' ? (
                                                    <>{item?.fetchedAt}</>
                                                ) : (
                                                    moduleName === 'datePaln' && (
                                                        <>
                                                            <div className="text-gray-700 text-[13px]">
                                                                {item?.currentTime}
                                                            </div>
                                                        </>
                                                    )
                                                )}
                                                <button
                                                    className={`arrow functionality-open ${isOpen ? 'open' : ''}`}
                                                    onClick={() => {
                                                        toggleDropdown(item?.id, item);
                                                    }}
                                                    data-no-popup
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="10"
                                                        height="10"
                                                        fill="currentColor"
                                                        className="bi bi-chevron-down down-bottom"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {assignedUserName && (
                                        <div className="assigned-user text-black">{assignedUserName}</div>
                                    )}
                                </div>
                                {item?.SubTasks && item?.SubTasks.length > 0 && (
                                    <div
                                        style={{
                                            marginTop: '10px',
                                            paddingLeft: '15px',
                                            borderLeft: '2px solid #ccc',
                                        }}
                                    >
                                        {item.SubTasks.map((subTask) => (
                                            <div>
                                                {/* <img src="assets/email.svg" /> */}
                                                <ChildTask item={subTask} moveChildTask={moveChildTask} />
                                            </div>
                                        ))}
                                    </div>
                                )}
                                <div className="grid-disp">
                                    <div className="calendar-dropdown master-calendar deadline-align-task">
                                        {moduleName === 'datePaln' ? (
                                            <span className="">
                                                <span
                                                    className={`${showTime ? 'open' : ''} deadline-btn`}
                                                    onClick={inTaskProgress}
                                                >
                                                    Ideas
                                                </span>
                                            </span>
                                        ) : (
                                            <span className="calendar-child calendar-datee">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="10"
                                                    height="10"
                                                    fill="currentColor"
                                                    className="bi bi-flag flag-deadline"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                                                </svg>
                                                <span
                                                    className={`deadline-btn text-black ${showTime ? 'open' : ''}`}
                                                    onClick={deadlineDrop}
                                                    onChange={handleDeadlineChange}
                                                    data-no-popup
                                                >
                                                    {taskDeadline ? moment(taskDeadline).format('MMM DD') : 'Deadline'}
                                                </span>
                                            </span>
                                        )}
                                    </div>
                                    <div
                                        className={`${userAssigneeName ? 'inline-flex' : 'svg-circle'} absolute-span-1`}
                                    >
                                        <span className="circle-user">
                                            <img src="./assets/right.svg" alt="person" className="bi bi-check-lg" />
                                        </span>
                                        <div>
                                            <span
                                                className={`circle-user ${
                                                    userAssigneeName
                                                        ? 'ml-[5px] user-border bg-[#512da8] text-[#fff]'
                                                        : ''
                                                } `}
                                                onClick={() => {
                                                    handleToggleSearchBox(item);
                                                }}
                                                data-no-popup
                                            >
                                                {userAssigneeName ? (
                                                    <div className="circle-user-initial ">
                                                        {userName.charAt(0).toUpperCase()}
                                                    </div>
                                                ) : (
                                                    <img
                                                        src="./assets/person.svg"
                                                        alt="person"
                                                        className="bi bi-person"
                                                    />
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>

                    {item.columnId !== 1 && (
                        <button
                            onClick={() => moveTaskToColumn(item.id, item.columnId - 1)}
                            className="button__left btn_desktop button"
                        >
                            <FontAwesomeIcon icon={faArrowLeftLong} />
                        </button>
                    )}
                    {item.columnId !== 4 && (
                        <button
                            onClick={() => moveTaskToColumn(item.id, item.columnId + 1)}
                            className="button__right btn_desktop button"
                        >
                            <FontAwesomeIcon icon={faArrowRightLong} />
                        </button>
                    )}
                    {item.columnId !== 1 && (
                        <button onClick={() => moveTaskUp(item.id)} className="button__left btn_mobile button">
                            <FontAwesomeIcon icon={faArrowUpLong} />
                        </button>
                    )}
                    {item.columnId !== 4 && (
                        <button onClick={() => moveTaskDown(item.id)} className="button__right btn_mobile button">
                            <FontAwesomeIcon icon={faArrowDownLong} />
                        </button>
                    )}
                </div>
            </div>
            <div onClick={deadlineDrop} className={`${isOpenDeadline ? 'block' : 'hidden'}`}>
                <div
                    className={`dropdown ${
                        isOpenDeadline ? 'open' : ''
                    } deadline-day deadend-start deadline-dropdown kanban-dropDown`}
                >
                    <div className="ulList">
                        <ul className="ulname-list items-list">
                            <div className="deadline-text">
                                <h3 className="deadline-head">Deadline...</h3>
                                <span className="clear-button clr-position">Clear</span>
                            </div>

                            {allDaysData?.map((item) => {
                                return (
                                    <>
                                        <li key={item?.id}>
                                            <div
                                                id={item}
                                                className="text-listdrop daysof-week"
                                                data-no-popup
                                                onClick={() => {
                                                    switch (item?.deadline) {
                                                        case 'Today':
                                                            handleTodayClick();
                                                            break;
                                                        case 'Tomorrow':
                                                            handleTomorrowClick();
                                                            break;
                                                        case 'In two days':
                                                            handleNextTwoDaysClick();
                                                            break;
                                                        case 'Next week':
                                                            handleNextWeekDaysClick();
                                                            break;
                                                        case 'Pick a date':
                                                            if (isCalendarOpen !== true) {
                                                                setIsCalendarOpen(true);
                                                                // setWeekDays(true);
                                                            } else {
                                                                setIsCalendarOpen(false);
                                                            }
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                }}
                                            >
                                                {item?.deadline === 'Today' && (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-brightness-alt-high-fill inlineBlock"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3m8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5m-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4" />
                                                    </svg>
                                                )}
                                                {item?.deadline === 'Tomorrow' && (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-brightness-high-fill inlineBlock"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708" />
                                                    </svg>
                                                )}
                                                {item?.deadline === 'In two days' && (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-clock-history inlineBlock"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z" />
                                                        <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z" />
                                                        <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5" />
                                                    </svg>
                                                )}
                                                {item?.deadline === 'Next week' && (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-calendar4-event inlineBlock"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z" />
                                                        <path d="M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z" />
                                                    </svg>
                                                )}
                                                {item?.deadline === 'Pick a date' && (
                                                    <div
                                                        className="highlight-container"
                                                        style={{ display: 'inline-block' }}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            className="bi bi-calendar2-minus inlineBlock"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M5.5 10.5A.5.5 0 0 1 6 10h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5" />
                                                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z" />
                                                            <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5z" />
                                                        </svg>
                                                    </div>
                                                )}
                                                {item?.deadline === 'Add this item to My Day plan' && (
                                                    <input
                                                        type="checkbox"
                                                        checked={checked}
                                                        onChange={handleChange}
                                                        className="inlineBlock input-Taskk"
                                                    />
                                                )}

                                                <div className="currentDay">
                                                    {item?.deadline &&
                                                        item?.deadline.charAt(0).toUpperCase() +
                                                            item?.deadline.slice(1)}

                                                    <div>{item.dayName}</div>
                                                </div>
                                            </div>
                                        </li>
                                    </>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
            <div className={`ideas ${isProgress ? 'open' : ''} mt-[-268px] ideas-project`}>
                <div className="max-w-xs p-4 bg-white border rounded shadow-lg">
                    <div className="flex mb-4 justify-between border-b-4">
                        <input type="text" className="w-full ideastask" />
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-search text-[#696D72]"
                            viewBox="0 0 16 16"
                        >
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                        </svg>
                    </div>
                    <div className="space-y-2">
                        <div className="border-b pb-2 text-black">
                            <h2 className="font-semibold border-b-2 text-start">Personal Board</h2>
                            <ul className="space-y-1 text-start mt-[12px] ml-[10px]">
                                <li>To do</li>
                                <li>Working on</li>
                                <li>Follow up</li>
                                <li>To Read</li>
                                <li>Done</li>
                            </ul>
                        </div>
                        <div className="border-b pb-2 text-black">
                            <h2 className="font-semibold text-start">Project Board</h2>
                            <ul className="space-y-1 text-start mt-[12px] ml-[10px]">
                                <li>Ideas</li>
                                <li>Planned</li>
                                <li>In progress</li>
                                <li>Done</li>
                            </ul>
                        </div>
                        <button className="text-[#42B4F4]">Remove from board</button>
                    </div>
                </div>
            </div>
            {showBox === true && (
                <ConfirmationPopup
                    taskToDeleteId={taskToDelete}
                    onCancel={handleCancelDelete}
                    deleteTaskMail={deleteTaskMail}
                />
            )}
            {currentTask && (
                <div className="task-data-popup" ref={popupRef}>
                    <TaskData
                        taskItem={currentTask}
                        gmailMessageID={currentTask?.gmailMessageId}
                        updateTaskMail={updateTaskMail}
                    />
                </div>
            )}
            <div onClick={() => setIsOpen(false)} className={`${isOpen ? 'block' : 'hidden'}`}>
                <div className="layout-backdrop"></div>
                <div className={`dropdown dropdown-tactic drop-color ${isOpen ? 'open' : ''} `}>
                    <div className=" grid-ui">
                        <div className="grid-list-chid">
                            <div>
                                {' '}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="12"
                                    fill="#7d7d7d"
                                    className="bi bi-check-lg inlineBlock"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                </svg>
                            </div>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="12"
                                    fill="#7d7d7d"
                                    className="bi bi-box-arrow-up-right inlineBlock"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"
                                    />
                                </svg>
                            </div>
                            <div>
                                {' '}
                                <svg
                                    onClick={() => {
                                        handleEditTitle();
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="12"
                                    fill="#7d7d7d"
                                    className="bi bi-pencil-fill inlineBlock"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                                </svg>
                            </div>
                            <div>
                                {' '}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="12"
                                    fill="#7d7d7d"
                                    className="bi bi-lightning-fill inlineBlock"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641z" />
                                </svg>
                            </div>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="12"
                                    fill="#7d7d7d"
                                    className="bi bi-file-earmark-arrow-down-fill inlineBlock"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1m-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0" />
                                </svg>
                            </div>
                            <div>
                                {' '}
                                <svg
                                    onClick={() => {
                                        removeTask(itemId);
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="12"
                                    fillRule="#7d7d7d"
                                    className="bi bi-x inlineBlock "
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                </svg>
                            </div>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="12"
                                    fill="#7d7d7d"
                                    className="bi bi-pencil inlineBlock"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                </svg>
                            </div>
                        </div>
                        <div className="ulname-list grid-child-one">
                            {[
                                'Complete',
                                'View Details',
                                'Edit title',
                                'Mark as unread',
                                'Archive',
                                'Delete',
                                'Highlight',
                            ].map((item) => (
                                <div key={item}>
                                    <a
                                        href="#"
                                        id={item}
                                        className="text-listdrop"
                                        onClick={() => {
                                            if (item === 'Edit title') {
                                                handleEditTitle();
                                            } else if (item === 'Delete') {
                                                setShowBox(true);
                                            } else if (item === 'View Details') {
                                                navigate(`/detail/${item.id}`);
                                            }
                                        }}
                                        data-no-popup
                                    >
                                        {item === 'Highlight' && (
                                            <div
                                                className="highlight-container"
                                                style={{
                                                    display: 'inline-block',
                                                    position: 'absolute',
                                                    right: '7px',
                                                }}
                                            >
                                                <span
                                                    onClick={clearHighlight}
                                                    className="clear-button clr-position clear-on"
                                                >
                                                    Clear
                                                </span>
                                            </div>
                                        )}
                                        {item.charAt(0).toUpperCase() + item.slice(1)}
                                    </a>
                                </div>
                            ))}
                        </div>
                        <div className="Grid-Circle">
                            {CONSTANTS?.color?.map((color, index) => (
                                <div
                                    key={index}
                                    className={`patch ${color.color} ${activePatch === color.color ? 'active' : ''}`}
                                    onClick={() => {
                                        handleClick(color.value);
                                        setHoverIndex(color.value1);
                                        setColorIndexID(indexId);
                                        handleTaskSelectedColor(color.value);
                                    }}
                                ></div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {isSearchBoxOpen && (
                <div onClick={setIsSearchBoxClose}>
                    <div className="layout-backdrop"></div>
                    <div className="search-box">
                        <label>Assign to</label>
                        <input
                            type="text"
                            placeholder="Search by name"
                            className="inputField-text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyDown={handleKeyDown}
                            data-no-popup
                        />
                        <ul className="assign-box">
                            {filteredUsers.map((user, i) => (
                                <li
                                    key={i}
                                    onClick={() => handleUserSelect(user?.id)}
                                    data-no-popup
                                    className="flex items-center gap-2"
                                >
                                    <span className="avatar-mail text-white">{user.name.charAt(0).toUpperCase()}</span>{' '}
                                    {user.name} <span className="opacity-50">{user.email}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
}

Task.propTypes = {
    item: PropTypes.object.isRequired,
    moveTaskToColumn: PropTypes.func.isRequired,
    moveTaskUp: PropTypes.func.isRequired,
    moveTaskDown: PropTypes.func.isRequired,
    removeTask: PropTypes.func.isRequired,
    indexId: PropTypes.number,
    moduleName: PropTypes.string.isRequired,
    getTaskMail: PropTypes.func.isRequired,
    createTaskMail: PropTypes.func.isRequired,
    columnTitle: PropTypes.func.isRequired,
    setTaskDetailsPopup: PropTypes.func.isRequired,
    taskDetailsPopup: PropTypes.bool.isRequired,
    taskDeadline: PropTypes.object.isRequired,
    userAssigneeName: PropTypes.object.isRequired,
    taskColor: PropTypes.object.isRequired,
};

export default Task;
