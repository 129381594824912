export default {
    GET_EMAIL_BY_DATE: (query, selectedBoardId) => `/email/getEmailsByDate/${selectedBoardId}?${query}`,
    UPDATE_EMAIL_STATUS: (id) => `/email/updateStatusEmail/${id}`,
    EMAIL_REPLY: (id) => `/email/reply/${id}`,
    GET_ALL_DELETED_EMAIL: () => `/email/getAllDeletedMail`,
    GET_TASK_BY_ID: (id) => `/getTaskBy/${id}`,
    EMAIL_UPDATEMESSAGE_READ: (id) => `/email/updatedMessageRead/${id}`,
    UPDATED_EMAIL_FOR_TASK: (id) => `/email/updatedEmailForTask/${id}`,
    Get_The_Linked_Email: () => '/email/getTheLinkedEmail',
};
