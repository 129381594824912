import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import services from '../../services';

const User = () => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const navigate = useNavigate();
    const [isRightPanelActive, setIsRightPanelActive] = useState(false);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setEmail('');
            setPassword('');
            setName('');
            setEmailError('');
            setPasswordError('');
            setNameError('');
        }
    };

    const handleLogin = async () => {
        const data = {
            email: email,
            password: password,
        };

        try {
            const response = await services.auth.LOGIN(data);
            if (response) {
                setEmail('');
                setPassword('');
                toast('Successfully Login');
            }

            if (response) {
                navigate('/app');
            }
        } catch (error) {
            console.error('Error fetching task emails:', error);
            toast('Login Error');
        }
    };

    const registerUser = async () => {
        const data = {
            name: name,
            email: email,
            password: password,
        };
        try {
            const response = await services.auth.REGISTER(data);
            if (response) {
                setName('');
                setEmail('');
                setPassword('');
                setIsRightPanelActive(false);
                toast('Registered successfully!');
            }
        } catch (error) {
            console.error('Error fetching task emails:', error);
            toast('Registernation Error');
        }
    };

    return (
        <div className="flex flex-col justify-center items-center h-screen bg-gray-200 font-montserrat">
            <div
                className={`relative w-[768px] max-w-full min-h-[480px] bg-white rounded-lg shadow-lg overflow-hidden ${
                    isRightPanelActive ? 'right-panel-active' : ''
                }`}
            >
                <div
                    className={`absolute top-0 h-full w-1/2 transition-all duration-700 ease-in-out ${
                        isRightPanelActive ? 'transform translate-x-full opacity-100 z-50' : 'opacity-0 z-10'
                    }`}
                >
                    <div className="flex flex-col items-center justify-center p-12 text-center h-full bg-white">
                        <h1 className="font-bold m-0">Create Account</h1>

                        <span className="text-xs">or use your email for registration</span>
                        <div>
                            <input
                                className="bg-gray-200 border-none p-3 my-2 w-full"
                                type="text"
                                placeholder="Name"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (value === '') {
                                        setNameError('Name is required');
                                    } else {
                                        setNameError('');
                                        setName(value);
                                    }
                                }}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                        {nameError && nameError}
                        <div>
                            <input
                                className="bg-gray-200 border-none p-3 my-2 w-full"
                                type="email"
                                placeholder="Email"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (value === '') {
                                        setEmailError('Email is required');
                                    } else {
                                        setEmailError('');
                                        setEmail(value);
                                    }
                                }}
                                onKeyDown={handleKeyDown}
                            />
                            {emailError && emailError}
                        </div>
                        <div>
                            <input
                                className="bg-gray-200 border-none p-3 my-2 w-full"
                                type="password"
                                placeholder="Password"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (value === '') {
                                        setPasswordError('Email is required');
                                    } else {
                                        setPasswordError('');
                                        setPassword(value);
                                    }
                                }}
                                onKeyDown={handleKeyDown}
                            />
                            {passwordError && passwordError}
                        </div>
                        <div>
                            <button
                                className="rounded-full border border-red-500 bg-red-500 text-white text-xs font-bold px-11 py-3 tracking-wider uppercase transition-transform duration-75 ease-in"
                                type="submit"
                                onClick={() => {
                                    registerUser();
                                }}
                                onKeyDown={handleKeyDown}
                            >
                                Sign Up
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    className={`absolute top-0 h-full w-1/2 transition-all duration-700 ease-in-out ${
                        isRightPanelActive ? 'transform translate-x-full' : 'opacity-100 z-20'
                    }`}
                >
                    <div className="flex flex-col items-center justify-center p-12 text-center h-full bg-white">
                        <h1 className="font-bold m-0">Sign in</h1>
                        <span className="text-xs">or use your account</span>
                        <div>
                            <input
                                id="email"
                                name="email"
                                className="bg-gray-200 border-none p-3 my-2 w-full"
                                type="email"
                                placeholder="Email"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (value === '') {
                                        setEmailError('Email is required');
                                    } else {
                                        setEmailError('');
                                        setEmail(value);
                                    }
                                }}
                            />
                            {emailError && emailError}
                        </div>
                        <div>
                            <input
                                id="password"
                                name="password"
                                className="bg-gray-200 border-none p-3 my-2 w-full"
                                type="password"
                                placeholder="Password"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (value === '') {
                                        setPasswordError('Email is required');
                                    } else {
                                        setPasswordError('');
                                        setPassword(value);
                                    }
                                }}
                            />
                            {passwordError && passwordError}
                        </div>
                        <a className="text-gray-800 text-sm no-underline my-3" href="#">
                            Forgot your password?
                        </a>
                        <button
                            className="rounded-full border border-red-500 bg-red-500 text-white text-xs font-bold px-11 py-3 tracking-wider uppercase transition-transform duration-75 ease-in"
                            type="submit"
                            onClick={() => {
                                handleLogin();
                            }}
                        >
                            Sign In
                        </button>
                    </div>
                </div>
                <div
                    className={`absolute top-0 left-1/2 h-full w-1/2 overflow-hidden transition-transform duration-700 ease-in-out z-50 ${
                        isRightPanelActive ? 'transform -translate-x-full' : ''
                    }`}
                >
                    <div className="bg-gradient-to-r from-red-500 to-pink-500 bg-cover bg-center text-white absolute left-[-100%] h-full w-[200%] transition-transform duration-700 ease-in-out">
                        <div className="absolute flex flex-col items-center justify-center p-10 text-center top-0 h-full w-1/2 transition-transform duration-700 ease-in-out">
                            <h1 className="font-bold m-0">Welcome Back!</h1>
                            <p className="text-sm font-light leading-5 tracking-wide my-5">
                                To keep connected with us please login with your personal info
                            </p>
                            <button
                                className="rounded-full border border-white bg-transparent text-white text-xs font-bold px-11 py-3 tracking-wider uppercase transition-transform duration-75 ease-in"
                                id="signIn"
                            >
                                Sign In
                            </button>
                        </div>
                        <div className="absolute flex flex-col items-center justify-center p-10 text-center top-0 h-full w-1/2 right-0 transform transition-transform duration-700 ease-in-out">
                            <h1 className="font-bold m-0">Hello, Friend!</h1>
                            <p className="text-sm font-light leading-5 tracking-wide my-5">
                                Enter your personal details and start journey with us
                            </p>
                            {isRightPanelActive != true ? (
                                <>
                                    <button
                                        className="rounded-full border border-white bg-transparent text-white text-xs font-bold px-11 py-3 tracking-wider uppercase transition-transform duration-75 ease-in"
                                        id="signUp"
                                        onClick={() => {
                                            setIsRightPanelActive(true);
                                        }}
                                    >
                                        Sign Up
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button
                                        className="rounded-full border border-white bg-transparent text-white text-xs font-bold px-11 py-3 tracking-wider uppercase transition-transform duration-75 ease-in"
                                        id="signUp"
                                        onClick={() => {
                                            registerUser();
                                        }}
                                    >
                                        Sign Up
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default User;
