import React from 'react';
import './index.css';
const DeleteModal = ({ showModal, handleCloseModal, handleConfirm, confirmButtonName }) => {
    return (
        <div className="text-center">
            {showModal && (
                <div className="modal fade show" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog modal-confirm">
                        <div className="modal-content">
                            <div className="modal-header flex-column">
                                <div className="icon-box">
                                    <i className="material-icons">
                                        <img style={{ height: '50px' }} src="assets/delete.svg" alt="not " />
                                    </i>
                                </div>
                                <h4 className="modal-title w-100">Are you sure want to delete ?</h4>
                                <button type="button" className="close" onClick={handleCloseModal} aria-hidden="true">
                                    &times;
                                </button>
                            </div>

                            <div className="modal-footer justify-content-center">
                                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn"
                                    style={{ backgroundColor: '#42b4f4' }}
                                    onClick={handleConfirm}
                                >
                                    {confirmButtonName}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DeleteModal;
