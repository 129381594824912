import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { store } from './Redux/store';
import { Provider } from 'react-redux';
import AppRoute from './AppRoute';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
    <Provider store={store}>
        <ToastContainer />
        <AppRoute />
    </Provider>,
    document.getElementById('root'),
);
