export function boardLocal() {
    return JSON.parse(localStorage.getItem('board'));
}

export function columnsLocal() {
    return JSON.parse(localStorage.getItem('columns'));
}

export function tasksLocal() {
    return JSON.parse(localStorage.getItem('tasks'));
}

export function userDataLocal() {
    return JSON.parse(localStorage.getItem('userData'));
}
