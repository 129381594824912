import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Select from 'react-dropdown-select';
import services from '../../services';
import './User.css';
import { MultiSelect } from 'react-multi-select-component';

const UserAssignBoard = () => {
    const { state } = useLocation();
    const [allPermission, setAllPermission] = useState([]);
    const [boardColumn, setBoardColumn] = useState([]);
    const [selectedPerIds, setSelectedPerIds] = useState([]);
    const [selectedColumnIds, setSelectedColumnIds] = useState([]);
    const [selectedBoards, setSelectedBoards] = useState('');
    const [boardName, setBoardName] = useState([]);

    const [allASSIGNMENT, setAllASSIGNMENT] = useState([]);

    const handleSelect = (selected) => {
        setSelectedBoards(selected);
        getBoardColumn(selected[0].value);
    };

    const handleEditSelect = (selected, index) => {
        // setSelectedBoards(selected);
        // getBoardColumn(selected[0].value);
    };

    const getAllBoard = async () => {
        try {
            const { data } = await services.board.GETALLBOARD();
            const filterData =
                (data && data.length > 0 && data.map(({ boardName, id }) => ({ label: boardName, value: id }))) || [];

            setBoardName(filterData);
            GET_ALL_ASSIGNMENT(filterData);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getAllBoard();
        GET_ALL_PERMISSION();
    }, []);

    const GET_ALL_PERMISSION = async () => {
        try {
            const response = await services.permission.GET_ALL_PERMISSION();
            const data =
                (response &&
                    response.length > 0 &&
                    response.map((item) => ({
                        ...item,
                        label: item.name,
                        permission_details: item.details,
                        value: item.id,
                    }))) ||
                [];
            setAllPermission(data);
        } catch (e) {
            console.error(e);
        }
    };

    const getBoardColumn = async (id) => {
        try {
            const response = await services.board.SELECTED_BOARD(id);
            const data = response?.data?.map((item) => ({
                value: item?.id,
                label: item?.coloum,
            }));
            setBoardColumn(data);
        } catch (e) {
            console.error(e);
        }
    };

    const handleSubmit = async (item) => {
        //         if(item){
        //             try{
        // const response = await services.userassignboard.UPDATE_UserAssignBoard()
        //             }catch(e){
        //                 console.error(e)
        //             }
        //         }
        const data = {
            boardId: selectedBoards[0].value,
            userId: state.id,
            boardListId: selectedColumnIds.map((item) => item),
            permissions: selectedPerIds.map((item) => item),
        };
        try {
            await services.userassignboard.CREATE_UserAssignBoard(data);
            setSelectedBoards([]);
            setSelectedColumnIds([]);
            setSelectedPerIds([]);
            GET_ALL_ASSIGNMENT();
        } catch (e) {
            console.error(e);
        }
    };

    const GET_ALL_ASSIGNMENT = async (filterData) => {
        try {
            const response = await services.userassignboard.GET_UserAssignBoard_ID(state.id);
            const dataMap = response?.data?.data?.map((item, index) => ({
                ...item,
                boardListId: JSON.parse(item?.boardListId),
                permissions: JSON.parse(item?.permissions),
                BoardLists: item?.Board?.BoardLists?.map((item) => ({
                    label: item?.coloum,
                    value: item?.id,
                })),
                Board: {
                    label: item?.Board?.boardName,
                    value: item?.Board?.id,
                },
            }));
            setAllASSIGNMENT(dataMap || []);
        } catch (e) {
            console.error(e);
        }
    };

    const handleEditCol = (index, select) => {};
    const handleEditPer = (index, select) => {
        const data = [...allASSIGNMENT];
    };
    return (
        <div className="user-assign-board-container">
            <h1 className="header">Assign Boards and Permissions</h1>

            {allASSIGNMENT?.map((item, index) => (
                <div key={index}>
                    <div className="board-container">
                        <div className="form-row">
                            <div className="form-item">
                                <div className="label">Board</div>
                                <div style={{ width: '65%' }}>
                                    <Select
                                        options={boardName}
                                        onChange={(selected) => handleEditSelect(selected, index)}
                                        values={[item?.Board]}
                                    />
                                </div>
                            </div>
                            <div className="form-item">
                                <div className="label">Column</div>
                                <div className="drop-header">
                                    <MultiSelect
                                        options={item?.BoardLists}
                                        value={item?.boardListId}
                                        // onChange={(selectedOptions) => setSelectedColumnIds(selectedOptions)}
                                        onChange={(selectedOptions) => handleEditCol(index, selectedOptions)}
                                        labelledBy="Select Column"
                                    />
                                </div>
                            </div>

                            <div className="form-item">
                                <div className="label">Permissions</div>
                                <div className="drop-header">
                                    <MultiSelect
                                        options={allPermission}
                                        value={item?.permissions}
                                        // onChange={(selectedOptions) => setSelectedPerIds(selectedOptions)}
                                        onChange={(selectedOptions) => handleEditPer(index, selectedOptions)}
                                        labelledBy="Select Permissions"
                                    />
                                </div>
                            </div>

                            <div className="save-item">
                                <button className="save-button" onClick={() => handleSubmit(item)}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <div className="label" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                New Assignment
            </div>
            <div>
                <div className="board-container">
                    <div className="form-row">
                        <div className="form-item">
                            <div className="label">Board</div>
                            <div style={{ width: '65%' }}>
                                <Select
                                    options={boardName}
                                    onChange={handleSelect}
                                    values={selectedBoards?.length > 0 ? [selectedBoards] : []}
                                />
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="label">Column</div>
                            <div className="drop-header">
                                <MultiSelect
                                    options={boardColumn}
                                    value={selectedColumnIds}
                                    onChange={(selectedOptions) => setSelectedColumnIds(selectedOptions)}
                                    labelledBy="Select Column"
                                />
                            </div>
                        </div>

                        <div className="form-item">
                            <div className="label">Permissions</div>
                            <div className="drop-header">
                                <MultiSelect
                                    options={allPermission}
                                    value={selectedPerIds}
                                    onChange={(selectedOptions) => setSelectedPerIds(selectedOptions)}
                                    labelledBy="Select Permissions"
                                />
                            </div>
                        </div>

                        <div className="save-item">
                            <button className="save-button" onClick={handleSubmit}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserAssignBoard;
