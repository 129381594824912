import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import services from '../../services';
import 'react-toastify/dist/ReactToastify.css';

function AddUser() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate(); // Use useNavigate for navigation

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const registerUser = async () => {
        const data = {
            name: name,
            email: email,
            password: password,
        };
        try {
            const response = await services.auth.REGISTER(data);
            if (response) {
                setName('');
                setEmail('');
                setPassword('');
                toast('Registered successfully!');
                navigate('/admin'); // Navigate to the admin page
            }
        } catch (error) {
            console.error('Error during registration:', error);
            toast('Registration Error');
        }
    };

    return (
        <div className="">
            <div className="layout-backdrop"></div>
            <div className="add-user-modal ">
                <div className="tab-shadow">
                    <div className=" ">
                        <form onSubmit={handleSubmit} className=" ">
                            <div className="mb-4">
                                <label htmlFor="name" className="block mb-[0.4rem] text-gray-600 text-sm font-i">
                                    Name*
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    placeholder="Name"
                                    className="h-10 w-full border-r  text-gray-900 font-i focus:outline-none p-3 border-i input-border"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="email" className="block mb-[0.4rem] text-gray-600 text-sm font-i">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="Email"
                                    className="h-10 w-full border-r  text-gray-900 font-i focus:outline-none p-3 border-i input-border"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="password" className="block mb-[0.4rem] text-gray-600 text-sm font-i">
                                    Password
                                </label>
                                <input
                                    type="password"
                                    id="password"
                                    placeholder="*******"
                                    className="h-10 w-full border-r  text-gray-900 font-i focus:outline-none p-3 border-i input-border"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="flex items-center justify-end mt-[1.5rem]">
                                <button
                                    type="button"
                                    className="text-[14px] bg-gray-300 hover:bg-gray-400 text-gray-800 py-1.5 px-4 rounded-[4px] focus:outline-none focus:shadow-outline"
                                >
                                    Discard
                                </button>
                                <button
                                    type="submit"
                                    className="text-[14px] ml-4 bg-blue-500 hover:bg-blue-700 text-white  py-1.5 px-4 rounded-[4px] focus:outline-none focus:shadow-outline"
                                    onClick={registerUser}
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddUser;
