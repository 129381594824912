import axios from 'axios';
import api from '../api';

export default {
    LOGIN: (data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post(process.env.REACT_APP_BACKEND_URL + api.auth.LOGIN(), data, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                resolve(response);
                localStorage.setItem('access_token', response.data.tokens.access.token);
                localStorage.setItem('userData', JSON.stringify(response.data.user));
                axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
            } catch (err) {
                reject(err);
            }
        });
    },
    REGISTER: (data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post(constant.BASE_URL + api.auth.REGISTER(), data);
                resolve(response);
            } catch (err) {
                reject(err);
            }
        });
    },
};
