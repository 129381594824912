import React from 'react';
import PropTypes from 'prop-types';

const ConfirmationPopup = ({ onCancel, deleteTaskMail, taskToDeleteId}) => {
   
    return (
        <div className="popup">
            <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center z-[8] sm:p-0">
                        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[395px] ">
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center mx-auto sm:mt-0 ">
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">Are you sure you want to delete this task?</p>
                                            <p className="text-sm text-gray-500">You will no longer be able to view it.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex gap-2 items-center justify-center sm:px-6">
                                <button
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md bg-[#42B4F4] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#42B4F4] sm:ml-3 sm:w-auto"
                                    onClick={() => {deleteTaskMail(taskToDeleteId)}}
                                >   
                                    Yes, I am Sure
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                    onClick={onCancel}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

ConfirmationPopup.propTypes = {
    item: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    taskToDeleteId:PropTypes.string.isRequired,
    getTaskMail:PropTypes.func.isRequired,
    deleteTaskMail:PropTypes.func.isRequired
};

export default ConfirmationPopup;
