import task from './task';
import email from './email';
// import taskService from "./task";
import Whatsapp from './whatsapp';
import auth from './auth';
import board from './board';
import column from './column';
import template from './template';
import user from './user';
import permission from './permission';
import automation from './automation';
import userassignboard from './userassignboard';
import group from './group';
import compose from './compose';
import automations from './automations';

export default {
    task,
    email,
    Whatsapp,
    auth,
    board,
    column,
    template,
    user,
    permission,
    automation,
    userassignboard,
    group,
    compose,
    automations,
};
