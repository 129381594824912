import api from '../api';
import axios from 'axios';
export default {
    CREATE_GROUP: (data) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.REACT_APP_BACKEND_URL + api.group.CREATE_GROUP(), data)
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        });
    },
    GET_ALL_GROUP: (query) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_BACKEND_URL + api.group.GET_ALL_GROUP(query))
                .then((res) => resolve(res?.data?.data))
                .catch((err) => reject(err));
        });
    },
    DELETE_GROUP: (id) => {
        return new Promise((resolve, reject) => {
            axios
                .delete(process.env.REACT_APP_BACKEND_URL + api.group.DELETE_GROUP(id))
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
    UPDATE_GROUP: (id, data) => {
        return new Promise((resolve, reject) => {
            axios
                .put(process.env.REACT_APP_BACKEND_URL + api.group.UPDATE_GROUP(id), data)
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
    GET_GROUP_ID: (id) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_BACKEND_URL + api.group.GET_GROUP_ID(id))
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },

    CREATE_GROUP_ASSIGN: (data) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.REACT_APP_BACKEND_URL + api.group.CREATE_GROUP_ASSIGN(), data)
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        });
    },
    GET_ALL_GROUP_ASSIGN: (query) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_BACKEND_URL + api.group.GET_ALL_GROUP_ASSIGN(query))
                .then((res) => resolve(res?.data?.data))
                .catch((err) => reject(err));
        });
    },
    DELETE_GROUP_ASSIGN: (id) => {
        return new Promise((resolve, reject) => {
            axios
                .delete(process.env.REACT_APP_BACKEND_URL + api.group.DELETE_GROUP_ASSIGN(id))
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
    UPDATE_GROUP_ASSIGN: (id, data) => {
        return new Promise((resolve, reject) => {
            axios
                .put(process.env.REACT_APP_BACKEND_URL + api.group.UPDATE_GROUP_ASSIGN(id), data)
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
    GET_GROUP_ID_ASSIGN: (id) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_BACKEND_URL + api.group.GET_GROUP_ID_ASSIGN(id))
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
};
