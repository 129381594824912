import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';
import Select from 'react-dropdown-select';
import services from '../../services';
import { toast } from 'react-toastify';

const Automation = ({ onHide, show, selectedBoard, getTaskMailIndbox }) => {
    const [multiSelected, setMultiSelected] = useState([]);
    const [dropdownSelected, setDropdownSelected] = useState([]);
    const [filterList, setFilterList] = useState();

    const multiSelectOptions = [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
        { label: 'Option 3', value: 'option3' },
    ];

    const dropdownOptions = [
        { label: 'Dropdown 1', value: 'dropdown1' },
        { label: 'Dropdown 2', value: 'dropdown2' },
        { label: 'Dropdown 3', value: 'dropdown3' },
    ];

    useEffect(() => {
        getAllLinkedEmail();
    }, []);

    const getAllLinkedEmail = async () => {
        try {
            const response = await services.email.Get_The_Linked_Email();
            if (response) {
                const options = response?.data?.map((email) => ({
                    label: email.email,
                    value: email.id,
                    color: email.colour,
                }));
                setFilterList(options);
            }
        } catch (error) {
            console.error('error');
        }
    };

    const onSubmit = async (value) => {
        const data = {
            boardId: selectedBoard?.value,
            gmailUserId: dropdownSelected[0]?.value,
        };
        try {
           await services.automation.CREATE_AUTOMATION(data);
            getTaskMailIndbox();
            toast.success('User Assign Successfully..');
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Automation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <h5>Select Multiple Options</h5>
                <MultiSelect
                    options={multiSelectOptions}
                    value={multiSelected}
                    onChange={setMultiSelected}
                    labelledBy="Select Options"
                /> */}

                {/* <hr /> */}

                <h5>Select User</h5>
                <Select
                    options={filterList}
                    values={dropdownSelected}
                    onChange={(values) => setDropdownSelected(values)}
                    placeholder="Select User"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Close</Button>
                <Button
                    onClick={() => {
                        if (dropdownSelected?.length > 0) {
                            onHide();
                            onSubmit(dropdownSelected);
                        } else {
                            toast.warning('Please Select One User');
                        }
                    }}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default Automation;
