import React, { useState, useEffect } from 'react';
import '../../App.css';
import Board from '../Board';
import { TaskContext, ColumnContext } from '../../context';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuid } from 'uuid';
import ConfirmationPopup from '../ConfirmationPopup';
import Header from '../Header';

function DatePlan() {
    const getFormattedDate = (date, daysToAdd = 0) => {
        const today = new Date();
        const targetDate = new Date(today);
        targetDate.setDate(today.getDate() + daysToAdd);

        const dayOfWeek = targetDate.toLocaleDateString('en-US', { weekday: 'long' });
        const month = targetDate.toLocaleDateString('en-US', { month: 'long' });
        const dayOfMonth = targetDate.toLocaleDateString('en-US', { day: 'numeric' });

        if (daysToAdd === 0) {
            return `Today, ${month} ${dayOfMonth}`;
        } else if (daysToAdd === 1) {
            return `Tomorrow, ${month} ${dayOfMonth}`;
        } else if (daysToAdd === 2) {
            return `In 2 days, ${month} ${dayOfMonth}`;
        } else {
            return `${dayOfWeek}, ${month} ${dayOfMonth}`;
        }
    };

    const tasksInMemory = JSON.parse(localStorage.getItem('datetasks')) || [];
    const [tasks, setTasks] = useState(tasksInMemory);
    const [showBox, setShowBox] = useState(false);
    const [taskToDelete, setTaskToDelete] = useState(null);
    const moduleName = 'datePaln';

    const [columns, setColumns] = useState([]);
    const [currentTime, setCurrentTime] = useState('');
    

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            const hours = now.getHours() % 12 || 12;
            const minutes = now.getMinutes().toString().padStart(2, '0');
            const ampm = now.getHours() >= 12 ? 'pm' : 'am';
            setCurrentTime(`${hours}:${minutes}${ampm}`);
        };

        updateTime();
        const intervalId = setInterval(updateTime, 1000);

        return () => clearInterval(intervalId);
    }, []);
   

    useEffect(() => {
        const todayTitle = getFormattedDate(new Date(), 0);
        const tomorrowTitle = getFormattedDate(new Date(), 1);
        const inTwoDaysTitle = getFormattedDate(new Date(), 2);

        const initialColumns = [
            { id: 1, title: todayTitle, className: 'column col_first', borderColor: '#42B4F4', showkey: 'true', currentTime: ''},
            { id: 2, title: tomorrowTitle, className: 'column col_second', borderColor: '#62E1FF', showkey: 'true', currentTime: '' },
            { id: 3, title: inTwoDaysTitle, className: 'column col_third', borderColor: '#62E1FF', showkey: 'true', currentTime: '' },
            {
                id: 4,
                title: getFormattedDate(new Date(), 3),
                className: 'column col_fourth',
                borderColor: '#62E1FF',
                showkey: 'true',
                currentTime: ''
            },
            {
                id: 5,
                title: getFormattedDate(new Date(), 4),
                className: 'column col_fifth',
                borderColor: '#62E1FF',
                showkey: 'true',
                currentTime: ''
            },
            {
                id: 6,
                title: getFormattedDate(new Date(), 5),
                className: 'column col_sixth',
                borderColor: '#62E1FF',
                showkey: 'true',
                currentTime: ''
            },
            {
                id: 7,
                title: getFormattedDate(new Date(), 6),
                className: 'column col_seventh',
                borderColor: '#62E1FF',
                showkey: 'true',
                currentTime: ''
            },
            {
                id: 8,
                title: getFormattedDate(new Date(), 7),
                className: 'column col_eigth',
                borderColor: '#62E1FF',
                showkey: 'true',
                currentTime: ''
            },
        ];

        setColumns(initialColumns);
    }, []);

    useEffect(() => {
        localStorage.setItem('datetasks', JSON.stringify(tasks));
        localStorage.setItem('datecolumn', JSON.stringify(columns));
    }, [tasks]);

    useEffect(() => {
        localStorage.setItem('datetasks', JSON.stringify(tasks));
        localStorage.setItem('datecolumns', JSON.stringify(columns));
    }, [tasks, columns]);

    const moveTaskToColumn = (taskId, newColumnId) => {
        setTasks((prevTasks) => {
            const taskIndex = prevTasks.findIndex((task) => task.id === taskId);
            if (taskIndex === -1) return prevTasks;

            const updatedTasks = [...prevTasks];
            const task = updatedTasks[taskIndex];
            updatedTasks.splice(taskIndex, 1);
            const sameColumnTasks = updatedTasks.filter((task) => task.columnId === newColumnId);

            if (sameColumnTasks.length > 0) {
                updatedTasks.splice(
                    updatedTasks.findIndex((t) => t.id === sameColumnTasks[0].id),
                    0,
                    { ...task, columnId: newColumnId },
                );
            } else {
                updatedTasks.push({ ...task, columnId: newColumnId });
            }

            return updatedTasks;
        });
    };

    const moveTaskUp = (taskId) => {
        setTasks((prevTasks) => {
            const taskIndex = prevTasks.findIndex((task) => task.id === taskId);
            if (taskIndex > 0 && prevTasks[taskIndex].columnId === prevTasks[taskIndex - 1].columnId) {
                const updatedTasks = [...prevTasks];
                [updatedTasks[taskIndex - 1], updatedTasks[taskIndex]] = [
                    updatedTasks[taskIndex],
                    updatedTasks[taskIndex - 1],
                ];
                return updatedTasks;
            }
            return prevTasks;
        });
    };

    const moveTaskDown = (taskId) => {
        setTasks((prevTasks) => {
            const taskIndex = prevTasks.findIndex((task) => task.id === taskId);
            if (
                taskIndex < prevTasks.length - 1 &&
                prevTasks[taskIndex].columnId === prevTasks[taskIndex + 1].columnId
            ) {
                const updatedTasks = [...prevTasks];
                [updatedTasks[taskIndex + 1], updatedTasks[taskIndex]] = [
                    updatedTasks[taskIndex],
                    updatedTasks[taskIndex + 1],
                ];
                return updatedTasks;
            }
            return prevTasks;
        });
    };

    const addTaskToColumn = ({ title, user, columnId, date, deadlineDate }) => {
        const newTask = {
            id: uuid(),
            title,
            user,
            columnId,
            date,
            deadlineDate,
            currentTime,
        };
        

        const updatedTasks = [...tasks, newTask];
        setTasks(updatedTasks);
        localStorage.setItem('datetasks', JSON.stringify(updatedTasks));
    };
   

    const handleDeleteTask = (taskId) => {
        const updatedTasks = tasks.filter((task) => task.id !== taskId);
        setTasks(updatedTasks);
        setTaskToDelete(null);
        setShowBox(false);
    };

    const handleCancelDelete = () => {
        setTaskToDelete(null);
        setShowBox(false);
    };

    const removeTask = (taskId) => {
        setTaskToDelete(taskId);
        setShowBox(true);
    };

    const updateTitleInLocalStorage = (itemId, editTaskTitle) => {
        const existingData = JSON.parse(localStorage.getItem('datetasks')) || [];
        const updatedData = existingData?.map((item) => {
            if (item.id === itemId) {
                return { ...item, title: editTaskTitle, time:currentTime };
            }
            return item;
        });
        setTasks(updatedData);
        localStorage.setItem('datetasks', JSON.stringify(updatedData));
    };

    const updateColunTitleInLocalStorage = (columnID, editColumnTitle) => {
        const existingData = JSON.parse(localStorage.getItem('datecolumn')) || [];
        const updatedData = existingData?.map((item) => {
            if (item.id === columnID) {
                return { ...item, title: editColumnTitle };
            }
            return item;
        });
      
        setColumns(updatedData);
        localStorage.setItem('datecolumn', JSON.stringify(updatedData));
    };

    const updateDeadlineDateInLocalStorage = (itemId, date) => {
        const existingData = JSON.parse(localStorage.getItem('datetasks')) || [];
        const updatedData = existingData?.map((item) => {
            if (item.id === itemId) {
                return { ...item, deadlineDate: date };
            }
            return item;
        });
        setTasks(updatedData);
        localStorage.setItem('datetasks', JSON.stringify(updatedData));
    };

    return (
        <div className="app">
            <DndProvider backend={HTML5Backend}>
                <Header />
                <TaskContext.Provider
                    value={{
                        columns,
                        tasks,
                        moveTaskToColumn,
                        removeTask,
                        addTaskToColumn,
                        moveTaskUp,
                        moveTaskDown,
                        updateTitleInLocalStorage,
                        updateDeadlineDateInLocalStorage,
                        updateColunTitleInLocalStorage,
                        moduleName,
                    }}
                >
                    <ColumnContext.Provider value={{ columns }}>
                        <Board />
                    </ColumnContext.Provider>
                </TaskContext.Provider>
            </DndProvider>
            {showBox && taskToDelete && (
                <ConfirmationPopup
                    item={tasks.find((task) => task.id === taskToDelete)}
                    onDelete={handleDeleteTask}
                    onCancel={handleCancelDelete}
                />
            )}
        </div>
    );
}

export default DatePlan;