import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import DeleteModal from '../../utils/deleteModal/deleteModal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import AddPermission from './AddPermission';
import services from '../../services';

const permissionsTable = () => {
    const [userData, setUserData] = useState([]);
    const [editData, setEditData] = useState('');
    const [editId, setEditId] = useState('');

    const [showModalDelete, setShowModalDelete] = useState(false);
    const [selectDeleteId, setSelectDeleteId] = useState('');

    const [addUserStatus, setAddUserStatus] = useState(false);

    const [userAssignStatus, setUserAssignStatus] = useState(false);

    const navigate = useNavigate();

    const handleStatusToggle = async (item) => {
        try {
            await services.permission.UPDATE_PERMISSION(item?.id, { status: !item?.status });
            toast.success('Update Successfully');
            GET_ALL_PERMISSION();
        } catch (e) {
            console.error(e);
        }
    };

    const handleEdit = (data) => {
        setEditData(data);
        setAddUserStatus(true);
        setEditId(data?.id);
    };

    const handleDelete = async () => {
        try {
            await services.permission.DELETE_PERMISSION(selectDeleteId);
            toast.success('Permission Delete Successfully');
            GET_ALL_PERMISSION();
        } catch (e) {
            console.error(e);
        }
    };

    const handleConfirm = () => {
        setShowModalDelete(false);
        handleDelete();
    };

    useEffect(() => {
        GET_ALL_PERMISSION();
    }, []);
    const GET_ALL_PERMISSION = async () => {
        try {
            const response = await services.permission.GET_ALL_PERMISSION();
            setUserData(
                (response &&
                    response?.length > 0 &&
                    response?.map((item) => ({
                        name: item?.name,
                        details: item?.details,
                        id: item?.id,
                        status: item?.status,
                        createdAt: item?.createdAt,
                    }))) ||
                    [],
            );
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div style={{ padding: '20px 20px 0px 20px' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                <button
                    className="border-2 border-[#42B4F4]  text-[#42B4F4]  py-1 px-2 rounded"
                    onClick={() => navigate('/app')}
                >
                    Back
                </button>
                <button
                    className="border-2 border-[#42B4F4]  text-[#42B4F4]  py-1 px-2 rounded"
                    onClick={() => {
                        setAddUserStatus(true);
                        setEditData('');
                        setEditId('');
                    }}
                >
                    Add Permission
                </button>
            </div>
            <div style={{ marginTop: '10px' }}>
                <table className="table table-hover table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Permission Name</th>
                            <th scope="col">Details</th>
                            <th scope="col">Create Date</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>

                    {userData?.map((item, index) => (
                        <tbody key={item.id}>
                            <tr>
                                <th scope="row">{index + 1}</th>
                                <td>{item?.name}</td>
                                <td>{item?.details}</td>
                                <td>{moment(item?.createdAt).format('DD MMM YYYY')}</td>
                                <td>
                                    <div className="form-check form-switch" style={{}}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={item.status}
                                            onChange={() => handleStatusToggle(item)}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <button className="btn btn-sm me-1" onClick={() => handleEdit(item)}>
                                        <img src="assets/edit.svg" alt="edit" />
                                    </button>
                                    <button
                                        className="btn btn-sm me-1"
                                        onClick={() => {
                                            setShowModalDelete(true);
                                            setSelectDeleteId(item.id);
                                        }}
                                    >
                                        <img src="assets/delete.svg" alt="delete" />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
            {showModalDelete && (
                <DeleteModal
                    handleCloseModal={() => setShowModalDelete(false)}
                    handleConfirm={handleConfirm}
                    showModal={showModalDelete}
                    confirmButtonName={'Delete'}
                />
            )}

            {addUserStatus && (
                <AddPermission
                    showModal={addUserStatus}
                    handleClose={() => setAddUserStatus(false)}
                    editData={editData}
                    GET_ALL_PERMISSION={GET_ALL_PERMISSION}
                    editId={editId}
                />
            )}
        </div>
    );
};

export default permissionsTable;
