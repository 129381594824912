// import api from '../api';
import api from '../api';
import axios from 'axios';

export default {
    GET_EMAIL_BY_DATE: (query, selectedBoardId) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_BACKEND_URL + api.Email.GET_EMAIL_BY_DATE(query, selectedBoardId))
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        });
    },

    UPDATED_EMAIL_FOR_TASK: (id) => {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    `${process.env.REACT_APP_BACKEND_URL}+ api.Email.UPDATED_EMAIL_FOR_TASK${id}`,
                    // {
                    //   headers: {
                    //     "Content-Type": "application/json",
                    //   },
                    // }
                )
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        });
    },

    Get_The_Linked_Email: () => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_BACKEND_URL + api.Email.Get_The_Linked_Email())
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        });
    },
};
