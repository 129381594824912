import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DeleteModal from '../../utils/deleteModal/deleteModal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import AddGroup from './AddGroup';
import services from '../../services';

const GroupTable = () => {
    const [editData, setEditData] = useState('');
    const [editId, setEditId] = useState('');
    const [allGrorp, setAllGroup] = useState([]);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [selectDeleteId, setSelectDeleteId] = useState('');
    const [addUserStatus, setAddUserStatus] = useState(false);
    const navigate = useNavigate();

    const handleStatusToggle = async (item) => {
        try {
            await services.group.UPDATE_GROUP(item?.id, { status: !item?.status });
            toast.success('Update Successfully');
            getAllGroup();
        } catch (e) {
            console.error(e);
        }
    };

    const handleEdit = (data) => {
        setEditData(data);
        setAddUserStatus(true);
        setEditId(data?.id);
    };

    const handleDelete = async () => {
        try {
            await services.group.DELETE_GROUP(selectDeleteId);
            toast.success('Group Delete Successfully');
            getAllGroup();
        } catch (e) {
            console.error(e);
        }
    };

    const handleConfirm = () => {
        setShowModalDelete(false);
        handleDelete();
    };

    useEffect(() => {
        getAllGroup();
    }, []);

    const getAllGroup = async () => {
        try {
            const response = await services.group.GET_ALL_GROUP();
            setAllGroup(response || []);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div style={{ padding: '20px 20px 0px 20px' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                <button
                    className="border-2 border-[#42B4F4]  text-[#42B4F4]  py-1 px-2 rounded"
                    onClick={() => navigate('/app')}
                >
                    Back
                </button>
                <button
                    className="border-2 border-[#42B4F4]  text-[#42B4F4]  py-1 px-2 rounded"
                    onClick={() => {
                        setAddUserStatus(true);
                        setEditId('');
                        setEditData('');
                    }}
                >
                    Add Group
                </button>
            </div>
            <div style={{ marginTop: '10px' }}>
                <table className="table table-hover table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">No.</th>
                            <th scope="col">Group Name</th>
                            <th scope="col">Create Date</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>

                    {allGrorp?.map((item, index) => (
                        <tbody key={item.id}>
                            <tr>
                                <th scope="row">{index + 1}</th>
                                <td>{item?.name}</td>
                                <td>{moment(item?.createdAt).format('DD MMM YYYY')}</td>
                                <td>
                                    <div className="form-check form-switch" style={{}}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={item.status}
                                            onChange={() => handleStatusToggle(item)}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <button className="btn btn-sm me-1" onClick={() => handleEdit(item)}>
                                        <img src="assets/edit.svg" alt="edit" />
                                    </button>
                                    <button
                                        className="btn btn-sm me-1"
                                        onClick={() => {
                                            setShowModalDelete(true);
                                            setSelectDeleteId(item.id);
                                        }}
                                    >
                                        <img src="assets/delete.svg" alt="delete" />
                                    </button>
                                    <button
                                        className="btn btn-sm me-1"
                                        onClick={() => {
                                            navigate('/groupassignment', {
                                                state: {
                                                    groupId: item?.id,
                                                },
                                            });
                                        }}
                                    >
                                        <img src="assets/Plus.svg" alt="plus" />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
            {showModalDelete && (
                <DeleteModal
                    handleCloseModal={() => setShowModalDelete(false)}
                    handleConfirm={handleConfirm}
                    showModal={showModalDelete}
                    confirmButtonName={'Delete'}
                />
            )}

            {addUserStatus && (
                <AddGroup
                    showModal={addUserStatus}
                    handleClose={() => setAddUserStatus(false)}
                    editData={editData}
                    getAllGroup={getAllGroup}
                    editId={editId}
                />
            )}
        </div>
    );
};

export default GroupTable;
