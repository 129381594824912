import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import DeleteModal from '../../utils/deleteModal/deleteModal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import AddUser from './AddUser';
import services from '../../services';
import { GET_ALL_USER } from '../../utils/Apifunction';
import { MultiSelect } from 'react-multi-select-component';
import Select, { components } from 'react-select';

const UserTable = () => {
    const [userData, setUserData] = useState([]);
    const [editData, setEditData] = useState('');
    const [editId, setEditId] = useState('');
    const [allGrorp, setAllGroup] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);

    const [showModalDelete, setShowModalDelete] = useState(false);
    const [selectDeleteId, setSelectDeleteId] = useState('');

    const [addUserStatus, setAddUserStatus] = useState(false);

    const navigate = useNavigate();

    const handleStatusToggle = async (item) => {
        try {
            await services.user.UPDATE_USER(item?.id, { status: !item?.status });
            toast.success('Update Successfully');
            GET_ALL_USER_DATA();
        } catch (e) {
            console.error(e);
        }
    };

    const handleEdit = (data) => {
        setEditData(data);
        setAddUserStatus(true);
        setEditId(data?.id);
    };

    const handleDelete = async () => {
        try {
            await services.user.DELETE_USER(selectDeleteId);
            toast.success('User Delete Successfully');
            GET_ALL_USER_DATA();
        } catch (e) {
            console.error(e);
        }
    };

    const handleConfirm = () => {
        setShowModalDelete(false);
        handleDelete();
    };

    useEffect(() => {
        getAllGroup();
        GET_ALL_USER_DATA();
    }, []);
    const GET_ALL_USER_DATA = async () => {
        const response = await GET_ALL_USER();
        response && setUserData(response?.users);
    };

    const getAllGroup = async () => {
        try {
            const response = await services.group.GET_ALL_GROUP();

            const data = response?.map((item) => ({
                value: item?.id,
                label: item?.name,
            }));
            setAllGroup(data || []);

            console.log(data, 'llllllllll');
        } catch (e) {
            console.error(e);
        }
    };

    const handleGroup = async () => {
        console.log(selectedGroup, 'hhhhhhhhhhhhhhhhh');

        try {
            const response = await services.user.ASSIGN_GROUP_USER();
            console.log(response, 'jjjjjjjjjjjjjjjjjjjjjjjjjjjjj');
            setMenuOpen(false);
        } catch (e) {
            console.error(e);
        }
    };

    // const CustomMenu = (props) => {
    //     return (
    //         <div>
    //             <components.Menu {...props}>
    //                 {props.children}
    //                 <div style={{ padding: '10px', textAlign: 'center' }}>
    //                     <button onClick={handleGroup}>Save</button>
    //                 </div>
    //             </components.Menu>
    //         </div>
    //     );
    // };
    return (
        <div style={{ padding: '20px 20px 0px 20px' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                <button
                    className="border-2 border-[#42B4F4]  text-[#42B4F4]  py-1 px-2 rounded"
                    onClick={() => navigate('/app')}
                >
                    Back
                </button>
                <button
                    className="border-2 border-[#42B4F4]  text-[#42B4F4]  py-1 px-2 rounded"
                    onClick={() => {
                        setAddUserStatus(true);
                        setEditId('');
                        setEditData('');
                    }}
                >
                    Add User
                </button>
            </div>
            <div style={{ marginTop: '10px' }}>
                <table className="table table-hover table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">No.</th>
                            <th scope="col">User Name</th>
                            <th scope="col">Email</th>
                            {/* <th scope="col">Group Assign</th> */}
                            <th scope="col">Create Date</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>

                    {userData?.map((item, index) => (
                        <tbody key={item.id}>
                            <tr>
                                <th scope="row">{index + 1}</th>
                                <td>{item?.name}</td>
                                <td>{item?.email}</td>
                                {/* <td>
                                    <div className="drop-header"> */}
                                        {/* <MultiSelect
                                            options={allGrorp}
                                            value={selectedGroup}
                                            onChange={(selectedOptions) => {
                                                setSelectedGroup(selectedOptions);
                                            }}
                                            labelledBy="Select Column"
                                        /> */}
                                        {/* <Select
                                            options={allGrorp}
                                            value={selectedGroup}
                                            onChange={(selectedOptions) => {
                                                setSelectedGroup(selectedOptions);
                                            }}
                                            components={{ Menu: CustomMenu }}
                                            isMulti
                                            onMenuOpen={() => setMenuOpen(true)}
                                            onMenuClose={() => setMenuOpen(false)}
                                            menuIsOpen={menuOpen}
                                        /> */}
                                    {/* </div>
                                </td> */}
                                <td>{moment(item?.createdAt).format('DD MMM YYYY')}</td>
                                <td>
                                    <div className="form-check form-switch" style={{}}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={item.status}
                                            onChange={() => handleStatusToggle(item)}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <button className="btn btn-sm me-1" onClick={() => handleEdit(item)}>
                                        <img src="assets/edit.svg" alt="edit" />
                                    </button>
                                    <button
                                        className="btn btn-sm me-1"
                                        onClick={() => {
                                            setShowModalDelete(true);
                                            setSelectDeleteId(item.id);
                                        }}
                                    >
                                        <img src="assets/delete.svg" alt="delete" />
                                    </button>
                                    <button
                                        className="btn btn-sm me-1"
                                        onClick={() =>
                                            navigate('/assignboard', {
                                                state: item,
                                            })
                                        }
                                    >
                                        <img src="assets/Plus.svg" alt="plus" />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
            {showModalDelete && (
                <DeleteModal
                    handleCloseModal={() => setShowModalDelete(false)}
                    handleConfirm={handleConfirm}
                    showModal={showModalDelete}
                    confirmButtonName={'Delete'}
                />
            )}

            {addUserStatus && (
                <AddUser
                    showModal={addUserStatus}
                    handleClose={() => setAddUserStatus(false)}
                    editData={editData}
                    GET_ALL_USER={GET_ALL_USER_DATA}
                    editId={editId}
                />
            )}
        </div>
    );
};

export default UserTable;
