import React, { useEffect, useState } from 'react';
import services from '../../services';
import '../UserManage/User.css';
import { MultiSelect } from 'react-multi-select-component';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const UserAssignBoard = () => {
    const [allPermission, setAllPermission] = useState([]);
    const [boardName, setBoardName] = useState([]);
    const [updateStatus, setUpdateStatus] = useState(false);
    const [assignments, setAssignments] = useState([
        { selectedBoards: [], selectedColumnIds: [], selectedPerIds: [], boardColumn: [] },
    ]);
    const [sections, setSections] = useState([
        { name: 'Products', actions: ['View', 'Create', 'Edit', 'Delete'], value:[] },
        { name: 'Settings', actions: ['View', 'Create', 'Edit', 'Delete'], value:[] },
        { name: 'Trips', actions: ['View', 'Create', 'Edit', 'Delete', 'Clone', 'Export'], value:[] },
        { name: 'Exclusions', actions: ['View', 'Create', 'Edit', 'Delete'], value:[] },
        { name: 'Home', actions: ['View', 'Create', 'Edit', 'Delete'], value:[] },
    ]);

    const handleButtonClick = (sectionIndex, action) => {
        const updatedSections = sections.map((section, i) => {
            if (i === sectionIndex) {
                const activeActions = section.value || [];
                if (activeActions.includes(action)) {
                    return { ...section, value: activeActions.filter((a) => a !== action) };
                } else {
                    return { ...section, value: [...activeActions, action] };
                }
            }
            return section;
        });
        setSections(updatedSections);
    };

    const { state } = useLocation();
    const navigate = useNavigate();

    // Handle the board selection
    const handleSelect = (index, event) => {
        const selectedBoardValue = event.target.value; // Get selected board value

        const selectedBoard = boardName.find((board) => board.value === selectedBoardValue); // Find board object

        const newAssignments = [...assignments];
        newAssignments[index].selectedBoards = [selectedBoard]; // Set the full object for display purposes
        newAssignments[index].selectedColumnIds = []; // Reset columns
        setAssignments(newAssignments);
        getBoardColumn(index, selectedBoardValue); // Fetch columns for the selected board
    };

    // Fetch all boards
    const getAllBoard = async () => {
        try {
            const { data } = await services.board.GETALLBOARD();
            const filterData =
                (data && data.length > 0 && data.map(({ boardName, id }) => ({ label: boardName, value: id }))) || [];
            setBoardName(filterData);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getAllBoard();
        GET_ALL_PERMISSION();
        getByGroupId();
    }, []);

    // Fetch permissions
    const GET_ALL_PERMISSION = async () => {
        try {
            const response = await services.permission.GET_ALL_PERMISSION();
            const data =
                (response &&
                    response.length > 0 &&
                    response.map((item) => ({
                        ...item,
                        label: item.name,
                        permission_details: item.details,
                        value: item.id,
                    }))) ||
                [];
            setAllPermission(data);
        } catch (e) {
            console.error(e);
        }
    };

    // Fetch columns of a selected board
    const getBoardColumn = async (index, id) => {
        try {
            const response = await services.board.SELECTED_BOARD(id);
            const data = response?.data?.map((item) => ({
                value: item?.id,
                label: item?.coloum,
            }));
            const newAssignments = [...assignments];
            newAssignments[index].boardColumn = data;
            setAssignments(newAssignments);
        } catch (e) {
            console.error(e);
        }
    };

    // Add new assignment
    const addNewAssignment = () => {
        setAssignments([
            ...assignments,
            { selectedBoards: [], selectedColumnIds: [], selectedPerIds: [], boardColumn: [] },
        ]);
    };

    // Filter board options
    const getFilteredBoardOptions = (index) => {
        const selectedBoardIds = assignments
            .filter((_, i) => i !== index)
            .map((assignment) => assignment.selectedBoards[0]?.value)
            .filter(Boolean);
        return boardName.filter((board) => !selectedBoardIds.includes(board.value));
    };

    const handleRemove = (index) => {
        if (assignments?.length == 1) {
            toast.warning('Board Select Required');
            return;
        }
        const data = assignments.filter((_, i) => i !== index);
        setAssignments(data);
    };

    const handleSubmit = async () => {
        const data = assignments.map((item) => ({
            boardListId: item?.selectedColumnIds,
            permissions: sections,
            boardId: item?.selectedBoards[0]?.value,
            boardColumn: item?.boardColumn,
            groupUserId: state?.groupId,
        }));

        try {
            const response = updateStatus
                ? await services.group.UPDATE_GROUP_ASSIGN(state?.groupId, data)
                : await services.group.CREATE_GROUP_ASSIGN(data);
            toast.success('Assignment Successfully');
            navigate('/group');
            getByGroupId();
        } catch (e) {
            console.error(e);
        }
    };

    const getByGroupId = async () => {
        try {
            const response = await services.group.GET_GROUP_ID_ASSIGN(state?.groupId);
            console.log('kkkkkkkk', response?.data?.data);
            if (response?.data?.data?.length == 0) {
                return;
            }
            const data =
                response?.data?.data &&
                response?.data?.data?.length > 0 &&
                response?.data?.data?.map((item) => {
                    setUpdateStatus(true);
                    return {
                        selectedColumnIds: item?.boardListId,
                        selectedPerIds: item?.permissions,
                        selectedBoards: [{ label: item?.Board?.boardName, value: item?.Board?.id }],
                        boardColumn: item?.Board?.BoardLists?.map((item) => ({
                            label: item?.coloum,
                            value: item?.id,
                        })),
                    };
                });
            setSections(response?.data?.data[0]?.permissions);
            setAssignments(data);
        } catch (e) {
            console.error(e);
        }
    };
    return (
        <div className="user-assign-board-container">
            <div
                className="label"
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px' }}
            >
                Create Assignment
            </div>
            <div className="container mx-auto px-4 py-8" style={{ maxWidth: '87%' }}>
                <div className="space-y-4">
                    {sections.map((section, index) => (
                        <div key={index} className="bg-white rounded-lg shadow p-4">
                            <div className="flex items-center justify-between mb-4">
                                <h2 className="text-lg font-medium">{section.name}</h2>
                                <div className="space-x-2">
                                    {section.actions.map((action) => (
                                        <button
                                            key={action}
                                            className={`px-4 py-2 rounded-md text-sm font-medium ${
                                                section.value?.includes(action)
                                                    ? 'bg-green-500 text-white'
                                                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                                            }`}
                                            onClick={() => handleButtonClick(index, action)}
                                        >
                                            {action}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div>
                {assignments.map((assignment, index) => (
                    <div key={index} className="board-container" style={{ maxWidth: '85%', marginTop: '20px' }}>
                        <div className="form-row">
                            <div className="form-item">
                                <div className="label">Board</div>
                                <div style={{ width: '65%' }}>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId={`dropdown-label-${index}`}
                                            id={`dropdown-${index}`}
                                            value={assignment.selectedBoards[0]?.value || ''} // Use value directly
                                            onChange={(event) => handleSelect(index, event)}
                                        >
                                            {getFilteredBoardOptions(index).map((board) => (
                                                <MenuItem key={board.value} value={board.value}>
                                                    {' '}
                                                    {/* Pass only the value here */}
                                                    {board.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="form-item">
                                <div className="label">Column</div>
                                <div className="drop-header">
                                    <MultiSelect
                                        options={assignment.boardColumn}
                                        value={assignment.selectedColumnIds}
                                        onChange={(selectedOptions) => {
                                            const newAssignments = [...assignments];
                                            newAssignments[index].selectedColumnIds = selectedOptions;
                                            setAssignments(newAssignments);
                                        }}
                                        labelledBy="Select Column"
                                    />
                                </div>
                            </div>

                            {/* <div className="form-item">
                                <div className="label">Permissions</div>
                                <div className="drop-header">
                                    <MultiSelect
                                        options={allPermission}
                                        value={assignment.selectedPerIds}
                                        onChange={(selectedOptions) => {
                                            const newAssignments = [...assignments];
                                            newAssignments[index].selectedPerIds = selectedOptions;
                                            setAssignments(newAssignments);
                                        }}
                                        labelledBy="Select Permissions"
                                    />
                                </div>
                            </div> */}
                            <div style={{ marginRight: '100px', height: '0px', cursor: 'pointer' }}>
                                <img
                                    src="assets/delete.svg"
                                    alt="remove"
                                    style={{ height: '20px' }}
                                    onClick={() => handleRemove(index)}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {assignments.length < boardName.length && (
                <button className="add-assignment-button" onClick={addNewAssignment}>
                    + Add Board
                </button>
            )}
            <div className="save-item" style={{ justifyContent: 'center' }}>
                <button className="save-button" style={{ width: '100px' }} onClick={() => handleSubmit()}>
                    Save
                </button>
            </div>
        </div>
    );
};

export default UserAssignBoard;
