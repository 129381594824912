import React, { useEffect, useState } from 'react';
import './User.css';
import { toast } from 'react-toastify';
import services from '../../services';

const AddUser = ({ showModal, handleClose, editData, GET_ALL_USER, editId }) => {
    const [value, setValue] = useState({
        name: '',
        // number: '',
        password: '',
        email: '',
    });
    useEffect(() => {
        if (editData) {
            setValue(() => ({
                name: editData?.name,
                password: '',
                email: editData?.email,
            }));
        }
    }, []);

    const handleSubmit = async () => {
        try {
            !editId ? await services.user.CREATE_USER(value) : await services.user.UPDATE_USER(editId, value);
            !editId ? toast.success('User Create Successfully.') : toast.success('User Update Successfully.');
            GET_ALL_USER();
            handleClose();
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div>
            {showModal && (
                <div
                    className="modal fade show"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                    style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close custom-close"
                                    onClick={handleClose}
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div style={{ marginTop: '0px' }}>
                                    <label className="block mb-[0.4rem] text-gray-600 text-sm font-i">User Name</label>
                                    <input
                                        type="text"
                                        className="h-10 w-full border-r  text-gray-900 font-i focus:outline-none p-3 border-i input-border"
                                        value={value.name}
                                        onChange={(e) => {
                                            setValue((prev) => ({
                                                ...prev,
                                                name: e.target.value.trimStart(),
                                            }));
                                        }}
                                    />
                                </div>
                                <div style={{ marginTop: '0px' }}>
                                    <label className="block mb-[0.4rem] text-gray-600 text-sm font-i">Email</label>
                                    <input
                                        type="email"
                                        className="h-10 w-full border-r  text-gray-900 font-i focus:outline-none p-3 border-i input-border"
                                        value={value.email}
                                        onChange={(e) => {
                                            setValue((prev) => ({
                                                ...prev,
                                                email: e.target.value.trimStart(),
                                            }));
                                        }}
                                    />
                                </div>
                                {!editId && (
                                    <div style={{ marginTop: '0px' }}>
                                        <label className="block mb-[0.4rem] text-gray-600 text-sm font-i">
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            className="h-10 w-full border-r  text-gray-900 font-i focus:outline-none p-3 border-i input-border"
                                            value={value.password}
                                            onChange={(e) => {
                                                setValue((prev) => ({
                                                    ...prev,
                                                    password: e.target.value.trimStart(),
                                                }));
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleClose}>
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        if (!value.name) {
                                            toast.warning('Please Enter User Name');
                                            return;
                                        }
                                        if (!value.email) {
                                            toast.warning('Please Enter Email');
                                            return;
                                        }
                                        if (!value.password && !editId) {
                                            toast.warning('Please Enter Password');
                                            return;
                                        }
                                        handleSubmit();
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddUser;
