import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import App from './App';
import MailInbox from './components/MailInbox';
import CreateTemplate from './components/CreateTemplate';
import DatePlan from './components/Date/DatePlan';
import TableData from './components/Table/TableData';
import InboxData from './components/Inbox/InboxData';
import Login from './components/Login/Login';
import User from './components/User/User';
import Whatsapp from './components/Whatsapp/Whatsapp';
import initialiseInterceptor from './services/interceptor';
import Admin from './components/Admin/Admin';
import AddUser from './components/AddUser/AddUser';
import TemplateTable from './components/Template/TemplateTable';
import UserTable from './components/UserManage/UserTable';
import PermissionsTable from './components/Permissions/permissionsTable';
import UserAssignBoard from './components/UserManage/UserAssignBoard';
import GroupAssignment from './components/GroupAssignment/GroupAssignment';
import GroupTable from './components/GroupAssignment/GroupTable';
import AutomationsTable from "./components/Automation/AutomationsTable"

const AppRoute = () => {
    useEffect(() => {
        initialiseInterceptor();
    }, []);
    const isLoggedIn = localStorage.getItem('access_token');
    return (
        <BrowserRouter>
            <Routes>
                {!isLoggedIn ? (
                    <>
                        <Route exact path="/login" name="Login Page" element={<Login />} />
                        <Route path="*" name="Page 404" element={<Navigate to={'/login'} />} />
                    </>
                ) : (
                    <>
                        <Route path="*" name="app" element={<Navigate to={'/app'} />} />
                        <Route path="/mailinbox" element={<MailInbox />} />
                        <Route path="/createtemplate" element={<CreateTemplate />} />
                        <Route path="/app" element={<App />} />
                        <Route path="/dateplan" element={<DatePlan />} />
                        <Route path="/tabledata" element={<TableData />} />
                        <Route path="/inbox" element={<InboxData />} />
                        <Route path="/user" element={<User />} />
                        <Route path="/detail/:id" element={<Whatsapp />} />
                        <Route path="/admin" element={<Admin />} />
                        <Route path="/adduser" element={<AddUser />} />
                        <Route path="/template" element={<TemplateTable />} />
                        <Route path="/usermanage" element={<UserTable />} />
                        <Route path="/permissions" element={<PermissionsTable />} />
                        <Route path="/assignboard" element={<UserAssignBoard />} />
                        <Route path="/groupassignment" element={<GroupAssignment />} />
                        <Route path="/group" element={<GroupTable />} />
                        <Route path="/automations" element={<AutomationsTable />} />
                        
                    </>
                )}
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoute;
