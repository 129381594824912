import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import SelectDown from 'react-dropdown-select';
import EditorComponent from './Template/Editor';
import services from '../services';
import DOMPurify from 'dompurify';
import { emailReply } from '../Redux/slice';
import { useDispatch } from 'react-redux';
import ComposeEmail from './composeEmail/ComposeEmail';
import { FormControl, Select, MenuItem } from '@mui/material';
import Loading from '../utils/Loading';

const MailInbox = ({ selectedEmailData, dataById }) => {
    const [emailValue, setEmailValue] = useState('');
    const [textAreaValue, setTextAreaValue] = useState('');
    const location = useLocation();
    const [replyDropdown, setReplyDropdown] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);
    const [assignedUserName, setAssignedUserName] = useState('');
    const [formattedBody, setFormattedBody] = useState([]);
    const [allTemp, setAllTemp] = useState([]);
    const [thread, setThread] = useState('');
    const [loading, setLoading] = useState(false);

    const [mailContent, setMailContent] = useState('');

    const [composeStatus, setComposeStatus] = useState(false);

    const [getemailbyid, setgetEmailData] = useState('');
    const [templateselect, setTemplateselect] = useState([]);

    const [taskAssign, setTaskAssign] = useState([]);
    const [allThreadTask, setAllThreadTask] = useState([]);

    const [template, setTemplate] = useState({
        templateData: '',
    });

    const dispatch = useDispatch();
    const selectedMail = location.state?.selectedMail ? location.state?.selectedMail : selectedEmailData;
    const navigate = useNavigate();

    const filteredUsers = taskAssign.filter((user) => user.name.toLowerCase().includes(searchTerm.toLowerCase()));

    const handleEmailChange = (e) => {
        setEmailValue(e.target.value);
    };

    const handleTextAreaChange = (event) => {
        setTextAreaValue(event.target.value);
    };

    const handleToggleSearchBox = () => {
        setIsSearchBoxOpen(!isSearchBoxOpen);
    };

    const handlebackbutton = () => {
        navigate('/app');
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            updateTaskMail(selectedMail?.id);
        }
    };

    const handlereply = () => {
        setReplyDropdown(!replyDropdown);
    };

    const handleUserSelect = async (user) => {
        setSelectedUserId(user?.id);
        setSearchTerm(user?.name);

        try {
            await updateTaskMail(selectedMail?.id);
            setIsSearchBoxOpen(false);
            setAssignedUserName(user?.name);
            toast.success('Task assigned successfully!');
        } catch (error) {
            toast.error('Failed to assign task. Please try again.');
            console.error('Error assigning task:', error);
        }
    };

    const getTaskAssign = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/auth/getAllUser?limit=10&email=${searchTerm}`,
            );
            if (response) {
                setTaskAssign(response?.data?.data?.users);
            }
        } catch (error) {
            console.error('Error fetching task mails:', error);
        }
    };

    useEffect(() => {
        getTaskAssign();
    }, [searchTerm]);

    const handlegetEmailDetails = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/email/getEmailBy/${selectedMail?.gmailMessageId}`,
            );
            setgetEmailData(response?.data);
            // if (response?.data?.SubEmails && response.data.SubEmails.length > 0) {
            //     const decodedHtml = decodeHtml(response.data.SubEmails[0].body);
            //     const cleanHtml = DOMPurify.sanitize(decodedHtml);
            //     setFormattedBody(cleanHtml);
            // } else {
            //     const decodedHtml = decodeHtml(response?.data?.body);
            //     const cleanHtml = DOMPurify.sanitize(decodedHtml);
            //     setFormattedBody(cleanHtml);
            // }

            if (response?.data?.SubEmails && response.data.SubEmails.length > 0) {
                const sanitizedEmails = response.data.SubEmails.map((email) => {
                    const decodedHtml = decodeHtml(email.body);
                    return { ...email, body: DOMPurify.sanitize(decodedHtml) };
                });
                setFormattedBody(sanitizedEmails);
            }

            const decodedHtml = decodeHtml(response?.data?.body);
            const cleanHtml = DOMPurify.sanitize(decodedHtml);
            setMailContent({ ...response?.data, body: cleanHtml });
        } catch (error) {}
    };
    useEffect(() => {
        handlegetEmailDetails();
    }, [selectedMail?.gmailMessageId]);

    const createTask = async () => {
        if (!selectedMail) {
            console.error('No email selected');
            return;
        }

        const { gmailMessageId, sentBy, sentTo, Email } = selectedMail;
        const dataObj = {
            messageId: gmailMessageId,
            sender: (sentTo && sentTo.split('"').filter(Boolean)[0]) || Email?.sentTo.split('"').filter(Boolean)[0],
            receiver: sentBy || Email?.sentBy.match(/<([^>]+)>/)[1],
            body: textAreaValue,
        };

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/email/reply/${gmailMessageId}`,
                dataObj,
            );
            if (response) {
                handlegetEmailDetails();
                setTextAreaValue('');
                dispatch(emailReply());
                setTemplateselect([]);
                toast.success('Email Send Successfully..');
            }
        } catch (error) {
            console.error('Error creating task:', error);
        }
    };

    const updateTaskMail = async (id, deadlineData) => {
        const msgId = selectedMail?.gmailMessageId;
        const data = {
            deadline: deadlineData,
            gmailMessageId: msgId,
            userId: selectedUserId,
        };

        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/task/updateTask/${id}`, data);
            if (response) {
                getTaskAssign();
            }
        } catch (error) {
            console.error('Error fetching task emails:', error);
        }
    };

    const getTemplate = async () => {
        try {
            const response = await services.template.GET_ALL_TEMPLATE();
            setAllTemp(
                response &&
                    response?.length > 0 &&
                    response?.map((item) => ({
                        label: item?.name,
                        value: item?.body,
                    })),
            );
        } catch (e) {
            console.error(e);
        }
    };
    useEffect(() => {
        getTemplate();
    }, []);

    const decodeHtml = (html) => {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    };

    useEffect(() => {
        if (selectedMail && location?.state?.clickMailTask) {
            let Arr = [];

            Arr.push(selectedMail);

            if (selectedMail.SubTasks && selectedMail.SubTasks.length > 0) {
                Arr.push(...selectedMail?.SubTasks);
            }
            setThread(Arr?.length > 0 && Arr[0]?.id);
            setAllThreadTask(Arr);
        }
    }, [selectedMail]);

    if (loading) {
        return <Loading />;
    }
    return (
        <div className="flex">
            <div className="w-[100%] p-4">
                <div className="bg-white rounded-md shadow-md p-4 ">
                    <div className="flex justify-between top-mail-inBox">
                        <div className="flex gap-[30px] ">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="28"
                                height="28"
                                fill="currentColor"
                                className="bi bi-arrow-left-short hover:bg-[#E4E4E4] rounded-[50%]"
                                viewBox="0 0 16 16"
                                onClick={() => {
                                    handlebackbutton();
                                }}
                            >
                                <path d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5" />
                            </svg>
                            <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-arrow-down-square p-[1px]"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
                                </svg>
                            </span>
                            <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-exclamation-octagon p-[1px]"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1z" />
                                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                                </svg>
                            </span>
                            <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-trash3"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                </svg>
                            </span>
                            <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-check-circle"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                </svg>
                            </span>
                            <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-reception-2"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M0 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5zm4 5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5" />
                                </svg>
                            </span>
                            <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-bell"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
                                </svg>
                            </span>
                            <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-calendar-date"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M6.445 11.688V6.354h-.633A13 13 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23" />
                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                </svg>
                            </span>
                            <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-envelope"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                </svg>
                            </span>
                            <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-arrow-right-square"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm4.5 5.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                </svg>
                            </span>
                            <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-three-dots-vertical"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                </svg>
                            </span>
                        </div>
                        <div className="flex gap-[30px]">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-chevron-left"
                                viewBox="0 0 16 16"
                            >
                                <path d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-chevron-right"
                                viewBox="0 0 16 16"
                            >
                                <path d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-x-lg"
                                viewBox="0 0 16 16"
                            >
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                            </svg>
                        </div>
                    </div>
                    <div className="relative">
                        <div className="grid grid-cols-[50%_auto] lg:grid-cols-[60%_auto] xl:grid-cols-[70%_auto] gap-x-[15px]">
                            <div className="relative bg-[#fff]">
                                <div style={{ display: 'flex', justifyContent: 'end' }}>
                                    {composeStatus && (
                                        <ComposeEmail
                                            replyDropdown={composeStatus}
                                            subject={dataById?.subject}
                                            selectedMail={location?.state?.selectedMail}
                                            setComposeStatus={setComposeStatus}
                                            setLoading={setLoading}
                                        />
                                    )}
                                </div>
                                <div className="">
                                    <div className=" mail-inBox-header">
                                        <div className="ml-[80px] flex justify-between items-center">
                                            <div>
                                                <div className="text-[21px] text-gray-700">
                                                    {selectedMail?.subject || getemailbyid?.subject}
                                                    <span className=" text-xl font-bold mt-[40px] text-black text-[1.375rem] "></span>
                                                    &nbsp;
                                                    <button className="inline-flex bg-[#DDDDDD]   ml-[10px] rounded-[3px]">
                                                        <span className=" text-[.75rem] p-[2px_5px_1px]">Inbox x</span>
                                                    </button>
                                                    <button className="inline-flex bg-[#DDDDDD]    ml-[10px] rounded-[3px]">
                                                        <span className=" text-[.75rem] p-[2px_5px_1px]">
                                                            - Shared/Project Board
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="self-start flex items-center gap-2">
                                                <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-chevron-expand"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708m0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708"
                                                        />
                                                    </svg>
                                                </span>
                                                <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="20"
                                                        height="20"
                                                        fill="currentColor"
                                                        className="bi bi-printer p-[1px]"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                                                        <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1" />
                                                    </svg>
                                                </span>
                                                <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="18"
                                                        height="18"
                                                        fill="currentColor"
                                                        className="bi bi-box-arrow-up-right p-[1px]"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5" />
                                                        <path d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="conversation-box ml-[80px]  relative  bg-white py-5  ">
                                        <div className="absolute avatar-mail-span flex-shrink-0">
                                            <div className="w-[2.5rem] h-[2.5rem] rounded-full bg-purple-500 flex items-center justify-center text-white">
                                                <span className="text-lg">S</span>
                                            </div>
                                        </div>
                                        <div className=" grid grid-cols-2 user-mail-inBox ">
                                            <div className="flex items-center ">
                                                <h6 className=" text-[.875rem] text-[#1f1f1f] font-semibold whitespace-nowrap">
                                                    {selectedMail?.name || getemailbyid?.name}
                                                </h6>
                                                <span className="ml-[9px] text-[13px]  text-[#000] opacity-50 text-ellipse">
                                                    {selectedMail?.sentBy || getemailbyid?.sentBy}
                                                </span>
                                            </div>
                                            <div className="grid grid-cols-[65%_auto]">
                                                <h6 className="flex gap-2 items-center justify-end">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="#2d6ec7"
                                                        className="bi bi-check2-all"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                                                        <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                                                    </svg>

                                                    <h6 className="text-[13px]  text-[#000] opacity-50 text-ellipse">
                                                        {moment(
                                                            selectedMail?.createdAt || getemailbyid?.createdAt,
                                                        ).format('MMMM Do YYYY, h:mm:ss a')}
                                                    </h6>
                                                </h6>
                                                <div className="flex gap-1 xl:gap-2 items-center justify-end">
                                                    <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            className="bi bi-star p-[1px]"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                                                        </svg>
                                                    </span>
                                                    <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            className="bi bi-emoji-smile p-[1px]"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                            <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5" />
                                                        </svg>
                                                    </span>
                                                    <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            className="bi bi-arrow-90deg-left p-[1px]"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708z" />
                                                        </svg>
                                                    </span>
                                                    <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            className="bi bi-three-dots-vertical p-[1px]"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-2 text-[13px] opacity-50 text-black ">
                                            <p className="mt-[-20px] ">
                                                {selectedMail?.sentTo || getemailbyid?.sentTo}
                                            </p>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="14"
                                                fill="currentColor"
                                                className="bi bi-caret-down-fill mt-[-17px]"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                            </svg>
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: mailContent?.body }}></div>
                                        <div className="py-5">
                                            <div className="max-w-screen-sm leading-8 text-black bg-[transparent]">
                                                {formattedBody &&
                                                    formattedBody?.length > 0 &&
                                                    formattedBody?.map((item) => {
                                                        return (
                                                            <div style={{ minHeight: '80px' }}>
                                                                <hr />
                                                                <div className="absolute avatar-mail-span flex-shrink-0">
                                                                    <div className="w-[2.5rem] h-[2.5rem] rounded-full bg-purple-500 flex items-center justify-center text-white">
                                                                        <span className="text-lg">
                                                                            {item?.sender?.charAt(0)?.toUpperCase()}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div className=" grid grid-cols-2 user-mail-inBox ">
                                                                    <div className="flex items-center ">
                                                                        <h6 className=" text-[.875rem] text-[#1f1f1f] font-semibold whitespace-nowrap">
                                                                            {item?.name}
                                                                        </h6>
                                                                        <span className="ml-[9px] text-[13px]  text-[#000] opacity-50 text-ellipse">
                                                                            <div
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: item?.sender,
                                                                                }}
                                                                            ></div>
                                                                        </span>
                                                                    </div>
                                                                    <div className="grid grid-cols-[65%_auto]">
                                                                        <h6 className="flex gap-2 items-center justify-end">
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="#2d6ec7"
                                                                                className="bi bi-check2-all"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                                                                                <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                                                                            </svg>

                                                                            <h6 className="text-[13px]  text-[#000] opacity-50 text-ellipse">
                                                                                {moment(item?.createdAt)?.format(
                                                                                    'MMMM Do YYYY, h:mm:ss a',
                                                                                )}
                                                                            </h6>
                                                                        </h6>
                                                                        <div className="flex gap-1 xl:gap-2 items-center justify-end">
                                                                            <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width="16"
                                                                                    height="16"
                                                                                    fill="currentColor"
                                                                                    className="bi bi-star p-[1px]"
                                                                                    viewBox="0 0 16 16"
                                                                                >
                                                                                    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                                                                                </svg>
                                                                            </span>
                                                                            <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width="16"
                                                                                    height="16"
                                                                                    fill="currentColor"
                                                                                    className="bi bi-emoji-smile p-[1px]"
                                                                                    viewBox="0 0 16 16"
                                                                                >
                                                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                                                    <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5" />
                                                                                </svg>
                                                                            </span>
                                                                            <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width="16"
                                                                                    height="16"
                                                                                    fill="currentColor"
                                                                                    className="bi bi-arrow-90deg-left p-[1px]"
                                                                                    viewBox="0 0 16 16"
                                                                                >
                                                                                    <path d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708z" />
                                                                                </svg>
                                                                            </span>
                                                                            <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width="16"
                                                                                    height="16"
                                                                                    fill="currentColor"
                                                                                    className="bi bi-three-dots-vertical p-[1px]"
                                                                                    viewBox="0 0 16 16"
                                                                                >
                                                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                                                                </svg>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex items-center gap-2 text-[13px] opacity-50 text-black ">
                                                                    <p className="mt-[-20px] ">{item?.receiver}</p>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="14"
                                                                        height="14"
                                                                        fill="currentColor"
                                                                        className="bi bi-caret-down-fill mt-[-17px]"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                                    </svg>
                                                                </div>

                                                                <div
                                                                    dangerouslySetInnerHTML={{ __html: item?.body }}
                                                                ></div>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                        <div className="flex gap-[30px] mt-[25px]">
                                            <button className="flex items-end justify-center text-black border text-[14px] gap-[5px] pb-[5px] pt-[4px] w-[150px] rounded-[30px] border-[#747775]">
                                                <span
                                                    className=" flex items-center gap-[15px]  text-[#000]"
                                                    onClick={handlereply}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-arrow-90deg-left "
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708z" />
                                                    </svg>
                                                    Reply
                                                </span>
                                            </button>

                                            {/* <button className="flex items-end justify-center text-black border text-[14px] gap-[5px] pb-[5px] pt-[4px] w-[150px] rounded-[30px] border-[#747775]">
                                                <span className=" flex items-center gap-[15px]  text-[#000]">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-arrow-90deg-right"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M14.854 4.854a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 4H3.5A2.5 2.5 0 0 0 1 6.5v8a.5.5 0 0 0 1 0v-8A1.5 1.5 0 0 1 3.5 5h9.793l-3.147 3.146a.5.5 0 0 0 .708.708z" />
                                                    </svg>
                                                    Forward
                                                </span>
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`ml-[80px] replydropdown mailreply-dropbox ${
                                        replyDropdown ? 'open' : ''
                                    }  kanban-dropDown  text-black`}
                                >
                                    <div className="bg-white p-4">
                                        <div className="flex items-center justify-between mb-4">
                                            <div className="flex items-center gap-[15px]">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-arrow-90deg-left text-black"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708z" />
                                                </svg>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-caret-down-fill text-black"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                                <h6 className="text-[14px] opacity-50 capitalize text-[#000]">
                                                    Recipients
                                                </h6>
                                                <input
                                                    type="email"
                                                    disabled
                                                    className="h-10 w-full border-r  text-gray-900 font-i focus:outline-none p-3 border-i input-border"
                                                    value={
                                                        selectedMail?.sentTo?.split('"').filter(Boolean)[0] ||
                                                        selectedMail?.Email?.sentTo?.split('"').filter(Boolean)[0]
                                                    }
                                                    onChange={handleEmailChange}
                                                    placeholder="Enter email"
                                                />
                                            </div>
                                            <div>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="#000"
                                                    className="bi bi-pip"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5zM1.5 3a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z" />
                                                    <path d="M8 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5z" />
                                                </svg>
                                            </div>
                                        </div>

                                        <div className="bg-[#FAFAFA] border border-[#EAEAEA] rounded-[4px]">
                                            <div className="flex items-center gap-[20px]  p-[20px] ">
                                                {/* <button className="text-[14px] items-center  flex justify-center  bg-[#E8EEF7] border border-[#1692D8] text-[#1F96DA] hover:bg-[#1692D8] hover:text-white py-1.5 w-[150px]  rounded-[30px] focus:outline-none focus:shadow-outline">
                                                    <span className="flex items-center gap-[5px]">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            className="bi bi-funnel-fill"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5z" />
                                                        </svg>
                                                        Ideas
                                                    </span>
                                                </button>
                                                <button className="text-[14px] items-center  flex justify-center  bg-[#E8EEF7] border border-[#1692D8] text-[#1F96DA] hover:bg-[#1692D8] hover:text-white py-1.5 w-[150px]  rounded-[30px] focus:outline-none focus:shadow-outline">
                                                    <span className="flex items-center gap-[5px]">
                                                        {' '}
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            className="bi bi-bell"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
                                                        </svg>
                                                        Remind me
                                                    </span>
                                                </button> */}

                                                <div>
                                                    <SelectDown
                                                        style={{ width: '300px' }}
                                                        options={allTemp}
                                                        values={templateselect ? [templateselect] : []}
                                                        onChange={(e) => {
                                                            setTemplateselect(e[0]);
                                                            setTemplate((prev) => ({
                                                                ...prev,
                                                                templateData: e[0]?.value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                                <div className="flex items-center">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-three-dots-vertical text-[#1692D8]"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                                    </svg>
                                                </div>
                                            </div>
                                            {/* <div className="mb-4  ">
                                                <p className="text-[14px] font-semibold pl-[24px] text-black">
                                                    3 tips to get you started
                                                </p>
                                            </div> */}
                                        </div>
                                        {/* <div>
                                            <textarea
                                                rows="4"
                                                className="outline-none focus:border-none text-black resize w-[-webkit-fill-available]"
                                                value={textAreaValue}
                                                onChange={handleTextAreaChange}
                                            />
                                        </div> */}
                                        <div>
                                            <EditorComponent
                                                selectedPrevData={template}
                                                handleEditorChange={(data) => {
                                                    setTextAreaValue(data);
                                                }}
                                            />
                                        </div>
                                        <div className="flex gap-[1rem] items-center text-black">
                                            <div className="flex">
                                                {' '}
                                                <button
                                                    onClick={() => {
                                                        createTask();
                                                    }}
                                                    className="items-center text-[14px] px-[14px] pt-[4px] pb-[5px] flex bg-[#0B57D0] text-white hover:bg-[#1568D7] hover:text-white rounded-l-[20px] focus:outline-none focus:shadow-outline"
                                                >
                                                    Send
                                                </button>
                                                <button className="border-l border-[#000] items-center text-[14px] pt-[4px] pb-[5px] px-[14px] flex bg-[#0B57D0] text-white hover:bg-[#1568D7] hover:text-white rounded-r-[20px] focus:outline-none focus:shadow-outline">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-caret-down-fill"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                    </svg>
                                                </button>
                                            </div>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="#2d6ec7"
                                                className="bi bi-file-earmark-text"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5" />
                                                <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                            </svg>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="#2d6ec7"
                                                className="bi bi-check2-all"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                                                <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                                            </svg>
                                            <span className="font-bold text-[14px] text-black capitalize  pb-1">A</span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-paperclip"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
                                            </svg>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-usb-c"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M3.5 7.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1z" />
                                                <path d="M0 8a3 3 0 0 1 3-3h10a3 3 0 1 1 0 6H3a3 3 0 0 1-3-3m3-2a2 2 0 1 0 0 4h10a2 2 0 1 0 0-4z" />
                                            </svg>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-emoji-smile"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5" />
                                            </svg>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-hdd"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M4.5 11a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1M3 10.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
                                                <path d="M16 11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V9.51c0-.418.105-.83.305-1.197l2.472-4.531A1.5 1.5 0 0 1 4.094 3h7.812a1.5 1.5 0 0 1 1.317.782l2.472 4.53c.2.368.305.78.305 1.198zM3.655 4.26 1.592 8.043Q1.79 8 2 8h12q.21 0 .408.042L12.345 4.26a.5.5 0 0 0-.439-.26H4.094a.5.5 0 0 0-.44.26zM1 10v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1" />
                                            </svg>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-image"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                                <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z" />
                                            </svg>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-lock"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2M5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1" />
                                            </svg>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-pen"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708z" />
                                            </svg>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-three-dots-vertical"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="absolute avatar-mail-span top-0">
                                        <div className="w-[2.5rem] h-[2.5rem] rounded-full bg-purple-500 flex items-center justify-center text-white">
                                            <span className="text-lg">S</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="top-mail-border">
                                <div className="bg-white rounded-md p-4 mailbox-border ">
                                    <div className="flex justify-between mailbox-border-b">
                                        <div className="flex gap-[20px] allspanitems">
                                            <span className=" hover:bg-[#E4E4E4] rounded-[50%]">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                    className="bi bi-check-circle p-[1px]"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                                </svg>
                                            </span>
                                            <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                    className="bi bi-bell p-[1px]"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
                                                </svg>
                                            </span>
                                            <span className="p-[6px] hover:bg-[#E4E4E4] rounded-[50%]">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                    className="bi bi-calendar-date p-[1px]"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M6.445 11.688V6.354h-.633A13 13 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23" />
                                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                                </svg>
                                            </span>
                                            <span className=" hover:bg-[#E4E4E4] rounded-[50%]">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                    className="bi bi-flag p-[1px]"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                                                </svg>
                                            </span>
                                            <span className=" hover:bg-[#E4E4E4] rounded-[50%]">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                    className="bi bi-paperclip p-[1px]"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
                                                </svg>
                                            </span>
                                            <span className=" hover:bg-[#E4E4E4] rounded-[50%]">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                    className="bi bi-envelope p-[1px]"
                                                    viewBox="0 0 16 16"
                                                    onClick={() => setComposeStatus(!composeStatus)}
                                                >
                                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                                </svg>
                                            </span>
                                        </div>
                                        <span className=" hover:bg-[#E4E4E4] rounded-[50%]">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="28"
                                                height="28"
                                                fill="currentColor"
                                                className="bi bi-arrow-right-short p-[1px]"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="flex justify-between pb-[5px] border-b border-[#e0e0e0]">
                                        <h3 className="text-[1.2rem] font-bold text-black mt-[10px]">
                                            {dataById?.subject}
                                        </h3>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="currentColor"
                                            className="bi bi-pencil mt-[15px] text-black"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                        </svg>
                                    </div>

                                    <div className="m-[13px_15px]">
                                        {/* <button className=" w-[100%]  h-[40px] mailbox-add-emailbtn">
                                            <span className="text-[#A2A2A2]">{getemailbyid?.gmailStatus}</span>
                                        </button> */}
                                        <div className="relative inline-block text-left" style={{ width: '100%' }}>
                                            <FormControl fullWidth>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={thread}
                                                    onChange={(e) => {
                                                        setThread(e.target.value);
                                                    }}
                                                >
                                                    {allThreadTask &&
                                                        allThreadTask.length > 0 &&
                                                        allThreadTask.map((item, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={item?.id}
                                                                onClick={() => {
                                                                    navigate(`/detail/${item?.id}`, {
                                                                        state: {
                                                                            selectedMail: item,
                                                                            deadlineDate: item?.deadline,
                                                                            taskID: item?.id,
                                                                        },
                                                                    });
                                                                }}
                                                            >
                                                                {item?.subject}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="grid grid-cols-[10%_auto] ml-[13px] mt-[20px]">
                                            <div className="">
                                                <div className="h-[36px] flex items-center gap-x-[10px]]">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-person-circle"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                                        <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1" />
                                                    </svg>
                                                </div>
                                                <div className="h-[36px] flex items-center gap-x-[10px]]">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-bell"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
                                                    </svg>
                                                </div>
                                                <div className="h-[36px] flex items-center gap-x-[10px]]">
                                                    {' '}
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-calendar-date"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M6.445 11.688V6.354h-.633A13 13 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23" />
                                                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                                    </svg>
                                                </div>
                                                <div className="h-[36px] flex items-center gap-x-[10px]]">
                                                    {' '}
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-flag"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                                                    </svg>
                                                </div>
                                                <div className="h-[36px] flex items-center gap-x-[10px]]">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-list"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                                                    </svg>
                                                </div>
                                                <div className="h-[36px] flex items-center gap-x-[10px]]">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-caret-right"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M6 12.796V3.204L11.481 8zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div>
                                                <h3
                                                    onClick={handleToggleSearchBox}
                                                    className="h-[36px] border-b border-[#f5f5f5] flex items-center gap-x-[10px]"
                                                >
                                                    {assignedUserName ? dataById.userId : 'Assign...'}
                                                </h3>
                                                {[
                                                    'Reminder...',
                                                    'My Day plan...',
                                                    'Deadline...',
                                                    'Notes (Shift-Enter for new line)...',
                                                    'Next Step...',
                                                ].map((item) =>
                                                    item === 'Deadline...' ? (
                                                        <div
                                                            className="h-[36px] border-b border-[#f5f5f5] flex items-center gap-x-[10px]"
                                                            key={item}
                                                        >
                                                            <a href="#" className="text-listdrop !text-[13px]">
                                                                {item.charAt(0).toUpperCase() + item.slice(1)}{' '}
                                                                {dataById?.deadline}
                                                            </a>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className="h-[36px] border-b border-[#f5f5f5] flex items-center gap-x-[10px]"
                                                            key={item}
                                                        >
                                                            <a href="#" className="text-listdrop !text-[13px]">
                                                                {item.charAt(0).toUpperCase() + item.slice(1)}
                                                            </a>
                                                        </div>
                                                    ),
                                                )}

                                                {isSearchBoxOpen && (
                                                    <div className="search-box serachbox">
                                                        <label>Assign to</label>
                                                        <input
                                                            type="text"
                                                            placeholder="Search by name"
                                                            className="inputField-text"
                                                            value={searchTerm}
                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                            onKeyDown={handleKeyDown}
                                                            data-no-popup
                                                        />
                                                        <ul>
                                                            {filteredUsers.map((user) => (
                                                                <li
                                                                    key={user?.id}
                                                                    onClick={() => handleUserSelect(user)}
                                                                    data-no-popup
                                                                >
                                                                    {user.name}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-4 add-comment">
                                        <div className="flex gap-[15px] items-center border-b border-[#f5f5f5] text-[.7rem] ">
                                            <h3 className="text-gray-700 font-bold mb-2 text-[#757575]">Comments</h3>
                                            <h3 className="text-gray-700 font-bold mb-2 text-[#757575]">
                                                Activity log
                                            </h3>
                                        </div>
                                        <p className="no-comments-h border-b-[5px] border-[#f5f5f5] text-[.7rem]">
                                            No comments
                                        </p>
                                        <div className="flex mt-[15px] items-center">
                                            <div className=" border w-[35px] h-[34px] rounded-[50%] bg-[#F5F5F5] flex justify-center items-center self-start">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                    className="bi bi-person inline-block"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                                                </svg>
                                            </div>
                                            <div className="mb-2 ml-[10px] w-[100%] flex svg-inputbox-relative">
                                                <textarea
                                                    rows="4"
                                                    cols="50"
                                                    id="comments"
                                                    placeholder="Add a comment"
                                                    className="InputFieldOne emailinput textarea-comment  !w-[100%]"
                                                />
                                                <div className="svg-inputbox !top-[2px] !bottom-auto right-[13%]">
                                                    <h6>@</h6>{' '}
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-telegram "
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09" />
                                                    </svg>{' '}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MailInbox;
