import React, { useState } from 'react';

const CreateTemplate = () => {
    const data = [{ id: 0, label: 'Istanbul, TR (AHL)' }];
    const details = [{ id: 0, label: 'Hindi' }];
    const folder = [
        { id: 0, label: 'Shared with all' },
        { id: 1, label: 'Sales' },
    ];
    const [isOpen, setOpen] = useState(false);
    const [isOpenTemplate, setOpenTemplate] = useState(false);
    const [isOpenFolder, setOpenFolder] = useState(false);
    const [items] = useState(data);
    const [itemdetail] = useState(details);
    const [folderdetail] = useState(folder);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [folders, setFolders] = useState([
        {
            name: 'Shared with all',
            content: 'Template Name',
            sent: 'LAST SENT',
            views: 'NO VIEWS',
            created: 'CREATED BY',
            temp: 'There are no templates. Create one now.',
        },
        {
            name: 'Sales',
        },
    ]);

    const [openFolders, setOpenFolders] = useState({});

    const toggleDropdownFolder = (folderName) => {
        setOpenFolders((prevOpenFolders) => ({
            ...prevOpenFolders,
            [folderName]: !prevOpenFolders[folderName],
        }));
    };
    const handleAddFolder = () => {
        setFolders([
            ...folders,
            {
                name: 'New Folder',
                templates: [],
            },
        ]);
        setIsPopupOpen(false);
    };

    const toggleDropdown = () => setOpen(!isOpen);
    const templateDropdown = () => setOpenTemplate(!isOpenTemplate);
    const folderDropdown = () => setOpenFolder(!isOpenFolder);

    const handleItemClick = (id) => {
        selectedItem === id ? setSelectedItem(null) : setSelectedItem(id);
    };

    return (
        <div className="container mx-auto  bg-white shadow-md rounded-md mt-[35px] border-t-[3px] border-[#f5f5f5]">
            <div className="flex justify-between p-4 border-b-[2px]  border-[#f5f5f5] ">
                <h1 className="text-[1.6rem] font-extralight mb-4 text-black">Sortd - Email Templates</h1>
                <div className="relative w-72">
                    <div className="rounded-lg shadow-lg bg-white">
                        <div className="p-4 cursor-pointer flex justify-between items-center" onClick={toggleDropdown}>
                            {selectedItem ? items.find((item) => item.id === selectedItem).label : 'BU Country Tour'}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className={`transform ${
                                    isOpen ? 'rotate-90' : 'rotate-0'
                                } transition-transform duration-200 ease-in-out text-gray-400 text-sm`}
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                                />
                            </svg>
                        </div>
                        <div
                            className={`absolute z-10 w-full border-t border-gray-300 bg-white rounded-lg shadow-lg ${
                                isOpen ? 'block' : 'hidden'
                            }`}
                        >
                            {items.map((item) => (
                                <div
                                    key={item.id}
                                    className="p-2 hover:cursor-pointer"
                                    onClick={() => handleItemClick(item.id)}
                                    id={item.id}
                                >
                                    <span
                                        className={`opacity-0 text-gray-400 transition-opacity duration-200 ease-in-out ${
                                            item.id === selectedItem ? 'opacity-100' : ''
                                        }`}
                                    >
                                        •{' '}
                                    </span>
                                    {item.label}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-between mb-4 p-4 mt-[50px]">
                <h2 className="text-[21px] text-black font-bold mb-2">Folders</h2>
                <div className="flex">
                    <div>
                        <button
                            className="border-2 border-[#42B4F4]  text-[#42B4F4] font-bold py-2 px-4 rounded"
                            onClick={() => setIsPopupOpen(true)}
                        >
                            Add a folder
                        </button>

                        {isPopupOpen && (
                            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
                                <div className="bg-white p-8 rounded shadow-lg w-[27%]">
                                    <div className="flex justify-between">
                                        <h2 className="text-xl font-bold mb-4 text-black">Add a Template</h2>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-x-lg"
                                            viewBox="0 0 16 16"
                                            onClick={() => setIsPopupOpen(false)}
                                        >
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                        </svg>
                                    </div>
                                    <h5 className="text-black font-extralight">Name</h5>
                                    <input className="InputFieldOne" />
                                    <div className="flex">
                                        <button
                                            className="bg-[#42B4F4] text-white font-bold py-2 px-4 rounded mr-2"
                                            onClick={handleAddFolder}
                                        >
                                            Save Folder
                                        </button>
                                        <button
                                            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                                            onClick={() => setIsPopupOpen(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <button
                        className="bg-[#42B4F4] text-white font-bold py-2 px-4 rounded ml-2"
                        onClick={() => setIsPopupOpen(true)}
                    >
                        Create template
                    </button>
                    {isPopupOpen && (
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
                            <div className="bg-white p-8 rounded shadow-lg w-[90%] h-[90%]">
                                <div className="flex justify-between">
                                    <h2 className="text-xl font-bold mb-4 text-black">Add a Folder</h2>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-x-lg"
                                        viewBox="0 0 16 16"
                                        onClick={() => setIsPopupOpen(false)}
                                    >
                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                    </svg>
                                </div>
                                <div className="relative w-72">
                                    <div className="border-4 border-[#F5F5F5]">
                                        <div
                                            className="ml-[10px] text-[black] h-[35px] cursor-pointer flex justify-between items-center"
                                            onClick={templateDropdown}
                                        >
                                            {selectedItem
                                                ? itemdetail.find((item) => item.id === selectedItem).label
                                                : 'English'}
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className={`transform ${
                                                    isOpenTemplate ? 'rotate-90' : 'rotate-0'
                                                } transition-transform duration-200 ease-in-out text-gray-400 text-sm mr-[5px] text-[black]`}
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                                                />
                                            </svg>
                                        </div>
                                        <div
                                            className={`absolute z-10 w-full border-t border-gray-300 bg-white rounded-lg shadow-lg ${
                                                isOpenTemplate ? 'block' : 'hidden'
                                            }`}
                                        >
                                            {itemdetail.map((item) => (
                                                <div
                                                    key={item.id}
                                                    className="p-2 hover:cursor-pointer"
                                                    onClick={() => handleItemClick(item.id)}
                                                    id={item.id}
                                                >
                                                    <span
                                                        className={`opacity-0 text-gray-400 transition-opacity duration-200 ease-in-out ${
                                                            item.id === selectedItem ? 'opacity-100' : ''
                                                        }`}
                                                    >
                                                        •{' '}
                                                    </span>
                                                    {item.label}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-[80%_auto] items-center gap-x-[20px] mt-[25px]">
                                    <div className="">
                                        <h5 className="text-black font-extralight">Email template name</h5>
                                        <input className="InputFieldOne  InputFieldTwo" placeholder="Title" />
                                    </div>
                                    <div className="mb-[7px]">
                                        <h5 className="text-black font-extralight ">Folder</h5>
                                        <div className="relative ">
                                            <div className="bg-white border-4 border-[#F5F5F5] h-[35px] mt-[5px]">
                                                <div
                                                    className="p-4 cursor-pointer flex justify-between items-center mt-[5px] ml-[7px] p-[0rem]"
                                                    onClick={folderDropdown}
                                                >
                                                    {selectedItem
                                                        ? folderdetail.find((item) => item.id === selectedItem).label
                                                        : 'Folder Name'}
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className={`transform ${
                                                            isOpenFolder ? 'rotate-90' : 'rotate-0'
                                                        } transition-transform duration-200 ease-in-out text-gray-400 text-sm text-[black]`}
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                                                        />
                                                    </svg>
                                                </div>
                                                <div
                                                    className={`absolute z-10 w-full border-t border-gray-300 bg-white rounded-lg shadow-lg text-[black] ${
                                                        isOpenFolder ? 'block' : 'hidden'
                                                    }`}
                                                >
                                                    {folderdetail.map((item) => (
                                                        <div
                                                            key={folderdetail.id}
                                                            className="p-2 hover:cursor-pointer"
                                                            onClick={() => handleItemClick(item.id)}
                                                            id={item.id}
                                                        >
                                                            <span
                                                                className={`opacity-0 text-gray-400 transition-opacity duration-200 ease-in-out ${
                                                                    item.id === selectedItem ? 'opacity-100' : ''
                                                                }`}
                                                            >
                                                                •{' '}
                                                            </span>
                                                            {item.label}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="border-4 border-[#F5F5F5] h-[60%] mt-[35px]">
                                    <h4 className="mt-[15px] ml-[20px] border-b-[3px] border-[#f5f5f5]">Subject</h4>
                                    <h4 className="mt-[15px] ml-[20px]">Template Body</h4>
                                    <div className="h-[80%] flex flex-col justify-end">
                                        <div className="ml-[10px] flex gap-[20px] color-[black]">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-type-bold"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M8.21 13c2.106 0 3.412-1.087 3.412-2.823 0-1.306-.984-2.283-2.324-2.386v-.055a2.176 2.176 0 0 0 1.852-2.14c0-1.51-1.162-2.46-3.014-2.46H3.843V13zM5.908 4.674h1.696c.963 0 1.517.451 1.517 1.244 0 .834-.629 1.32-1.73 1.32H5.908V4.673zm0 6.788V8.598h1.73c1.217 0 1.88.492 1.88 1.415 0 .943-.643 1.449-1.832 1.449H5.907z" />
                                            </svg>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-type-italic"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M7.991 11.674 9.53 4.455c.123-.595.246-.71 1.347-.807l.11-.52H7.211l-.11.52c1.06.096 1.128.212 1.005.807L6.57 11.674c-.123.595-.246.71-1.346.806l-.11.52h3.774l.11-.52c-1.06-.095-1.129-.211-1.006-.806z" />
                                            </svg>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-type-underline"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M5.313 3.136h-1.23V9.54c0 2.105 1.47 3.623 3.917 3.623s3.917-1.518 3.917-3.623V3.136h-1.23v6.323c0 1.49-.978 2.57-2.687 2.57s-2.687-1.08-2.687-2.57zM12.5 15h-9v-1h9z" />
                                            </svg>
                                            <h6>Normal</h6>
                                            <div>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="12"
                                                    height="12"
                                                    fill="currentColor"
                                                    className="bi bi-caret-up-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="12"
                                                    height="12"
                                                    fill="currentColor"
                                                    className="bi bi-caret-down-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                            </div>
                                            <h6>Sans Serif</h6>
                                            <div>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="12"
                                                    height="12"
                                                    fill="currentColor"
                                                    className="bi bi-caret-up-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="12"
                                                    height="12"
                                                    fill="currentColor"
                                                    className="bi bi-caret-down-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                            </div>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-text-left"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5" />
                                            </svg>
                                            <h5 className="italic">T</h5>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-link-45deg"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1 1 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4 4 0 0 1-.128-1.287z" />
                                                <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex mt-[20px]">
                                    <button
                                        className="bg-[#42B4F4] text-white font-bold py-2 px-4 rounded mr-2"
                                    
                                    >
                                        Save Template
                                    </button>
                                    <button
                                        className="border-2 border-[#F5F5F5] text-[grey] font-bold py-2 px-4 rounded"
                                        onClick={() => setIsPopupOpen(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="mb-4 p-4">
                <div className="grid grid-cols-1 gap-4">
                    {folders.map((folder) => (
                        <div key={folder.name} className="bg-white shadow-md rounded-md p-4 ">
                            <div className="flex justify-between">
                                <div className="flex gap-[12px]">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-folder mt-[5px] text-black"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a2 2 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139q.323-.119.684-.12h5.396z" />
                                    </svg>
                                    <span className="text-lg text-black">{folder.name}</span>
                                </div>
                                <svg
                                    onClick={() => toggleDropdownFolder(folder.name)}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className={`bi bi-chevron-down transform ${
                                        openFolders[folder.name] ? 'rotate-180' : 'rotate-0'
                                    } transition-transform duration-200 ease-in-out cursor-pointer`}
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                </svg>
                            </div>
                            {openFolders[folder.name] && (
                                <div className="mt-4">
                                    {folder.name === 'Shared with all' ? (
                                        <div>
                                            <div className="grid grid-cols-[50%_20%_auto_20%] pb-[20px] border-b-[2px] border-[#f5f5f5] ml-[25px]">
                                                <p>{folder.content}</p>
                                                <p>{folder.sent}</p>
                                                <p>{folder.views}</p>
                                                <p>{folder.created}</p>
                                            </div>
                                            <p className="mt-[8px] ml-[25px]">{folder.temp}</p>
                                        </div>
                                    ) : (
                                        <p>Dropdown content for {folder.name}</p>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CreateTemplate;
