import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DeleteModal from '../../utils/deleteModal/deleteModal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import TemplatePrev from './TemplatePrev';
import AddTemplate from './AddTemplate';
import services from '../../services';

const TemplateTable = () => {
    const [templateData, setTemplateData] = useState([]);

    const [showModalDelete, setShowModalDelete] = useState(false);
    const [selectDeleteId, setSelectDeleteId] = useState('');

    const [selectedPrevData, setSelectedPrevData] = useState('');
    const [showModalPrev, setShowModalPrev] = useState(false);

    const [templateAddStatus, setTemplateAddStatus] = useState(false);

    const [EDITVALUE, setEDITVALUE] = useState('');
    const [editId, setEditId] = useState();

    const navigate = useNavigate();

    const handleStatusToggle = async (item) => {
        try {
            await services.template.UPDATE_TEMPLATE(item?.id, { status: !item?.status });
            toast.success('Update Successfully');
            GET_ALL_TEMPLATE();
        } catch (e) {
            console.error(e);
        }
    };

    const handleEdit = (item) => {
        setTemplateAddStatus(true);
        setEDITVALUE(item);
        setEditId(item?.id);
    };
    const handlePrev = (data) => {
        setShowModalPrev(true);
        setSelectedPrevData(data);
    };

    const handleDelete = async () => {
        try {
            await services.template.DELETE_TEMPLATE(selectDeleteId);
            toast.success('Template Delete Successfully');
            GET_ALL_TEMPLATE();
        } catch (e) {
            console.error(e);
        }
    };

    const handleConfirm = () => {
        setShowModalDelete(false);
        handleDelete();
    };

    useEffect(() => {
        GET_ALL_TEMPLATE();
    }, []);

    const GET_ALL_TEMPLATE = async () => {
        try {
            const response = await services.template.GET_ALL_TEMPLATE();
            setTemplateData(
                response &&
                    response?.length > 0 &&
                    response?.map((item) => ({
                        templateName: item?.name,
                        templateData: item?.body,
                        status: item?.status,
                        id: item?.id,
                        createdAt: item?.createdAt,
                        subject: item?.subject,
                    })),
            );
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div style={{ padding: '20px 20px 0px 20px' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                <button
                    className="border-2 border-[#42B4F4]  text-[#42B4F4]  py-1 px-2 rounded"
                    onClick={() => navigate('/app')}
                >
                    Back
                </button>
                <button
                    className="border-2 border-[#42B4F4]  text-[#42B4F4]  py-1 px-2 rounded"
                    onClick={() => {
                        setTemplateAddStatus(true);
                        setEDITVALUE('');
                        setEditId('');
                    }}
                >
                    Add Template
                </button>
            </div>
            <div style={{ marginTop: '10px' }}>
                <table className="table table-hover table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Template Name</th>
                            <th scope="col">Subject</th>
                            <th scope="col">Create Date</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>

                    {templateData && templateData?.length > 0 ? (
                        templateData?.map((item, index) => (
                            <tbody key={item.id}>
                                <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td>{item?.templateName}</td>
                                    <td>{item?.subject}</td>
                                    <td>{moment(item?.createdAt).format('DD MMM YYYY')}</td>
                                    <td>
                                        <div className="form-check form-switch" style={{}}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={item.status}
                                                onChange={() => handleStatusToggle(item)}
                                            />
                                        </div>
                                    </td>

                                    <td>
                                        <button className="btn btn-sm me-1" onClick={() => handlePrev(item)}>
                                            <img src="assets/eye.svg" alt="eye" />
                                        </button>
                                        <button className="btn btn-sm me-1" onClick={() => handleEdit(item)}>
                                            <img src="assets/edit.svg" alt="edit" />
                                        </button>
                                        <button
                                            className="btn btn-sm me-1"
                                            onClick={() => {
                                                setShowModalDelete(true);
                                                setSelectDeleteId(item.id);
                                            }}
                                        >
                                            <img src="assets/delete.svg" alt="delete" />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        ))
                    ) : (
                        <div style={{}}>
                            <h4>Template Not Added</h4>
                        </div>
                    )}
                </table>
            </div>
            {showModalDelete && (
                <DeleteModal
                    handleCloseModal={() => setShowModalDelete(false)}
                    handleConfirm={handleConfirm}
                    showModal={showModalDelete}
                    confirmButtonName={'Delete'}
                />
            )}
            {showModalPrev && (
                <TemplatePrev
                    selectedPrevData={selectedPrevData}
                    showModal={showModalPrev}
                    handleClose={() => setShowModalPrev(false)}
                    handleEditorChange={() => {}}
                />
            )}
            {templateAddStatus && (
                <AddTemplate
                    showModal={templateAddStatus}
                    handleClose={() => setTemplateAddStatus(false)}
                    GET_ALL_TEMPLATE={GET_ALL_TEMPLATE}
                    EDITVALUE={EDITVALUE}
                    editId={editId}
                />
            )}
        </div>
    );
};

export default TemplateTable;
