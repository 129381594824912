import React, { useEffect, useState } from 'react';
import Editor from './Editor';
import './Template.css';
import { toast } from 'react-toastify';
import services from '../../services';

const AddTemplate = ({ showModal, handleClose, GET_ALL_TEMPLATE, EDITVALUE, editId }) => {
    const [value, setValue] = useState({
        name: EDITVALUE?.templateName || '',
        editorData: { templateData: EDITVALUE?.templateData } || '',
        subject: EDITVALUE?.subject || '',
    });

    const handleSubmit = async () => {
        const obj = {
            name: value?.name,
            body: value?.editorData?.templateData ? value?.editorData?.templateData : value?.editorData,
            subject: value?.subject,
        };
        try {
            !editId
                ? await services.template.CREATE_TEMPLATE(obj)
                : await services.template.UPDATE_TEMPLATE(editId, obj);
            !editId ? toast.success('Template Create Successfully.') : toast.success('Template Update Successfully.');
            GET_ALL_TEMPLATE();
            handleClose();
        } catch (e) {
            console.error(e);
        }
    };
    return (
        <div>
            {showModal && (
                <div
                    className="modal fade show"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                    style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close custom-close"
                                    onClick={handleClose}
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div style={{ marginTop: '0px' }}>
                                    <label className="block mb-[0.4rem] text-gray-600 text-sm font-i">
                                        Template Name
                                    </label>
                                    <input
                                        type="text"
                                        className="h-10 w-full border-r  text-gray-900 font-i focus:outline-none p-3 border-i input-border"
                                        value={value.name}
                                        onChange={(e) => {
                                            setValue((prev) => ({
                                                ...prev,
                                                name: e.target.value.trimStart(),
                                            }));
                                        }}
                                    />
                                </div>

                                <div style={{ marginTop: '15px' }}>
                                    <label className="block mb-[0.4rem] text-gray-600 text-sm font-i">Subject</label>
                                    <input
                                        type="text"
                                        className="h-10 w-full border-r  text-gray-900 font-i focus:outline-none p-3 border-i input-border"
                                        value={value.subject}
                                        onChange={(e) => {
                                            setValue((prev) => ({
                                                ...prev,
                                                subject: e.target.value.trimStart(),
                                            }));
                                        }}
                                    />
                                </div>

                                <div style={{ marginTop: '15px' }}>
                                    <label className="block mb-[0.4rem] text-gray-600 text-sm font-i">
                                        Template Data
                                    </label>

                                    <Editor
                                        selectedPrevData={value?.editorData}
                                        handleEditorChange={(data) => {
                                            setValue((prev) => ({
                                                ...prev,
                                                editorData: data,
                                            }));
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleClose}>
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        if (!value.name) {
                                            toast.warning('Please Enter Template Name');
                                            return;
                                        }
                                        if (!value.subject) {
                                            toast.warning('Please Enter Subject');
                                            return;
                                        }
                                        if (!value.editorData) {
                                            toast.warning('Please Enter Template Data');
                                            return;
                                        }
                                        handleSubmit();
                                    }}
                                >
                                    Save changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddTemplate;
