import api from '../api';
import axios from 'axios';

export default {
    CREATE_COLUMN: (data) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.REACT_APP_BACKEND_URL + api.column.CREATE_COLUMN(), data)
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        });
    },

    RENAME_COLUMN: (data, id) => {
        return new Promise((resolve, reject) => {
            axios
                .put(process.env.REACT_APP_BACKEND_URL + api.column.RENAME_COLUMN(id), data)
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        });
    },
};
