import api from '../api';
import axios from 'axios';

export default {
    CREATE_BOARD: (data) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.REACT_APP_BACKEND_URL + api.board.CREATE_BOARD(), data)
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        });
    },
    GETALLBOARD: () => {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
            axios
                .get(process.env.REACT_APP_BACKEND_URL + api.board.GETALLBOARD())
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        });
    },
    SELECTED_BOARD: (id) => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_BACKEND_URL + api.board.SELECTED_BOARD(id))
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        });
    },
    SEQUENCE: (data, id) => {
        return new Promise((resolve, reject) => {
            axios
                .put(process.env.REACT_APP_BACKEND_URL + api.board.SEQUENCE(id), data)
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        });
    },
};
