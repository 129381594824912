export default {
    CREATE_TASK: () => `/task/createTask`,
    GET_TASK: (query) => `/task/getAllTask?${query}`,
    UPDATE_TASK: (id) => `/updateStatusTask/${id}`,
    GET_ALL_DELETED_TASK: () => `/getAllDeletedTask`,
    GET_TASK_BY_ID: (id) => `/getTaskBy/${id}`,
    DELETE_TASK: (id) => `/task/deleteTask/${id}`,

    UPDATE_CHILD_TASK: (parentId) => `/task/updateParentTask/${parentId}`,
};
