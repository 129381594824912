import React, { useEffect, useState } from 'react';
import './Permission.css';
import { toast } from 'react-toastify';
import services from '../../services';

const AddPermission = ({ showModal, handleClose, editData, editId, GET_ALL_PERMISSION }) => {
    const [value, setValue] = useState({
        name: '',
        details: '',
    });
    useEffect(() => {
        if (editData) {
            setValue(() => ({
                name: editData?.name,

                details: editData?.details,
            }));
        }
    }, []);

    const handleSubmit = async () => {
        try {
            !editId
                ? await services.permission.CREATE_PERMISSION(value)
                : await services.permission.UPDATE_PERMISSION(editId, value);
            !editId
                ? toast.success('Permission Create Successfully.')
                : toast.success('Permission Update Successfully.');
            GET_ALL_PERMISSION();
            handleClose();
        } catch (e) {
            console.error(e);
        }
    };
    return (
        <div>
            {showModal && (
                <div
                    className="modal fade show"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                    style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close custom-close"
                                    onClick={handleClose}
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div style={{ marginTop: '0px' }}>
                                    <label className="block mb-[0.4rem] text-gray-600 text-sm font-i">Permission</label>
                                    <input
                                        type="text"
                                        className="h-10 w-full border-r  text-gray-900 font-i focus:outline-none p-3 border-i input-border"
                                        value={value.name}
                                        onChange={(e) => {
                                            setValue((prev) => ({
                                                ...prev,
                                                name: e.target.value.trimStart(),
                                            }));
                                        }}
                                    />
                                </div>
                                <div style={{ marginTop: '0px' }}>
                                    <label className="block mb-[0.4rem] text-gray-600 text-sm font-i">Details</label>
                                    <textarea
                                        className="h-40 w-full border-r  text-gray-900 font-i focus:outline-none p-3 border-i input-border"
                                        value={value.details}
                                        onChange={(e) => {
                                            setValue((prev) => ({
                                                ...prev,
                                                details: e.target.value.trimStart(),
                                            }));
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleClose}>
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        if (!value.name) {
                                            toast.warning('Please Enter Permission Name');
                                            return;
                                        }
                                        if (!value.details) {
                                            toast.warning('Please Enter Details');
                                            return;
                                        }

                                        handleSubmit();
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddPermission;
