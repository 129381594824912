export default {
    CREATE_GROUP: () => '/groupUser/createGroupUser',
    GET_ALL_GROUP: (query) => `/groupUser/getAllGroupUser?${query ? query : ''}`,
    DELETE_GROUP: (id) => `/groupUser/deleteGroupUser/${id}`,
    UPDATE_GROUP: (id) => `/groupUser/updategroupUser/${id}`,
    GET_GROUP_ID: (id) => `/groupUser/getGroupUserBy/${id}`,

    CREATE_GROUP_ASSIGN: () => '/groupBoard/createGroupBoard',
    GET_ALL_GROUP_ASSIGN: (query) => `/groupBoard/getAllGroupBoard?${query ? query : ''}`,
    DELETE_GROUP_ASSIGN: (id) => `/groupBoard/deleteGroupUser/${id}`,
    UPDATE_GROUP_ASSIGN: (id) => `/groupBoard/updategroupBoard/${id}`,
    GET_GROUP_ID_ASSIGN: (id) => `/groupBoard/getGroupBoardBy/${id}`,
};
