import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Task from './Task';
import { TaskContext } from './../context';
import { useDrop, useDrag } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import services from '../services';
import { boardLocal } from '../utils/LocalSt';
function Column(props) {
    const {
        columnTitle,
        limit,
        id,
        borderColor,
        showkey,
        setStatusUpdate,
        statusUpdate,
        taskDetailsPopup,
        setTaskDetailsPopup,
        getTaskMail,
        taskMail,
        taskAssign,
        setSearchTerm,
        index,
        columnMove,
        columnID,
        searchTerm,
        isShiftPressed,
        moveChildTask,
        handleRemoveChild,
        setIsShiftPressed
    } = props;
    const [showForm, setShowForm] = useState(false);
    const [title, setTaskTitle] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [renameMode, setRenameMode] = useState(false);
    const [newTitle, setNewTitle] = useState(columnTitle);

    const [selectedStatus, setSelectedStatus] = useState('');
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const navigate = useNavigate();
    const {
        moduleName,
        moveTaskToColumn,
        removeTask,
        moveTaskUp,
        moveTaskDown,
        updateColunTitleInLocalStorage,
        // getTaskMailInbox,
        getTaskMailIndbox,
    } = React.useContext(TaskContext);

    const createTask = async (selectedStatus, title) => {
        const dataObj = {
            boardListId: selectedStatus,
            title: title,
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/task/createTask`, dataObj);
            if (response) {
                getTaskMail();
            }
        } catch (error) {
            console.error('Error fetching task emails:', error);
        }
    };

    const createTaskMail = async (gmailMessageID, gmailStatus, subjectTitle) => {
        const dataObj = {
            boardListId: gmailStatus,
            gmailMessageId: gmailMessageID,
            title: subjectTitle,
        };
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/task/createTask`, dataObj, {
                headers: {
                    Authorization: `Bearer ${localStorage?.getItem('access_token')}`,
                },
            });

            if (response) {
                getTaskMail();
                getTaskMailIndbox(); // for all emails
            }
        } catch (error) {
            console.error('Error fetching task emails:', error);
        }
    };

    const updateStatusTask = async (id) => {
        const data = {
            boardListId: columnID,
        };
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/task/updateStatusTask/${id}`, data);
            if (response) {
                getTaskMail();
            }
        } catch (error) {
            console.error('Error fetching task emails:', error);
        }
    };

    const updateStatusEmail = async (id) => {
        const data = {
            gmailStatus: columnTitle,
        };

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/email/updateStatusEmail/${id}`,
                data,
            );

            if (response) {
                const gmailMessageId = response?.data?.data?.gmailMessageId;
                const gmailStatus = response?.data?.data?.gmailStatus;
                const subjectTitle = response?.data?.data?.subject;
                createTaskMail(gmailMessageId, gmailStatus, subjectTitle);

                getTaskMailIndbox();
                getTaskMail();
            }
        } catch (error) {
            console.error('Error fetching task emails:', error);
        }
    };

    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'task',
        drop: (item) => {
            item?.taskStatus && setStatusUpdate(!statusUpdate);
            if (item?.gmailMessage) {
                // updateStatusEmail(item?.gmailMessage);
                createTaskMail(item?.gmailMessage, columnID, '');
            }
            updateStatusTask(item?.id);
        },
    }));

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            createTask(selectedStatus, title);
            setShowForm(false);
            setTaskTitle('');
        }
    };

    const handleRenameClick = () => {
        setRenameMode(true);
        setIsDropdownOpen(false);
    };

    const handleColumnKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleRenameColumn();
            setRenameMode(false);
        }
    };

    const [{ isDragging }, dragRef] = useDrag({
        type: 'COLUMN',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, dropRef] = useDrop({
        accept: 'COLUMN',
        drop: (draggedColumn) => {
            if (draggedColumn.index !== index) {
                columnMove(draggedColumn.index, index);

                draggedColumn.index = index;
            }
        },
    });

    const ref = React.useRef(null);
    dragRef(dropRef(ref));

    const handleRenameColumn = async () => {
        const data = { coloum: newTitle, boardId: boardLocal()?.value };
        try {
            await services?.column?.RENAME_COLUMN(data, columnID);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div
            ref={ref}
            style={{
                opacity: isDragging ? 0.5 : 1,

                cursor: 'move',
                textAlign: 'center',
                // border: '1px solid black',
                position: 'relative',
                minWidth: '300px',
            }}
        >
            <div ref={drop} className="droptarget" style={{ backgroundColor: isOver ? 'lightgrey' : 'white' }}>
                <div className="title-contents drop-relative">
                    <div className="header-Flex">
                        <h2 className="title-column title-box" style={{ borderBottomColor: borderColor }}>
                            {renameMode ? (
                                <input
                                    type="text"
                                    value={newTitle}
                                    onChange={(e) => setNewTitle(e.target.value)}
                                    onKeyDown={handleColumnKeyDown}
                                    autoFocus
                                />
                            ) : (
                                <span>{newTitle || columnTitle}</span>
                            )}
                        </h2>
                        {!showkey && (
                            <>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="#000"
                                    onClick={toggleDropdown}
                                    className="bi bi-chevron-down down-arrow-circle"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                                    />
                                </svg>
                            </>
                        )}
                    </div>
                    {isDropdownOpen && (
                        <div>
                            <div onClick={toggleDropdown} className="layout-backdrop"></div>

                            <div className="headtag-card">
                                <ul className="start-list">
                                    {[
                                        'Add a task',
                                        'Add a list',
                                        'Rename this list',
                                        'Delete this list',
                                        'Mark all items in list complete',
                                        'Archieve all items in list',
                                        'Add incomings emails to this list',
                                        'Create automation',
                                    ].map((item, index) => (
                                        <li key={index}>
                                            <a
                                                href="#"
                                                id={item}
                                                className=""
                                                onClick={() => {
                                                    if (item === 'Add a task') {
                                                        setShowForm(!showForm);
                                                        setIsDropdownOpen(false);
                                                    } else if (item === 'Rename this list') {
                                                        handleRenameClick();
                                                    } else if (item === 'Create automation') {
                                                        navigate('/createtemplate');
                                                    }
                                                }}
                                            >
                                                {item === 'Add a task' && <div className=""></div>}
                                                {item === 'Add a list' && <div className=""></div>}
                                                {item === 'Rename this list' && <div className=""></div>}
                                                {item === 'Delete this list' && <div className="underline-list"></div>}
                                                {item === 'Mark all items in list complete' && <div className=""></div>}
                                                {item === 'Archieve all items in list' && (
                                                    <div className="underline-list"></div>
                                                )}
                                                {item === 'Add incomings emails to this list' && (
                                                    <div className=""></div>
                                                )}
                                                {item === 'Create automation' && <div></div>}

                                                <div className="currentDay">
                                                    {item && item.charAt(0).toUpperCase() + item.slice(1)}
                                                    <div>{item.dayName}</div>
                                                </div>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>

                <div className="inbox-Task-scroll">
                    {taskMail
                        ?.filter((item) => item?.BoardList?.id === columnID)
                        ?.map((item, indexId) => (
                            <>
                                <Task
                                    key={item.id}
                                    item={item}
                                    moveTaskToColumn={moveTaskToColumn}
                                    moveTaskUp={moveTaskUp}
                                    moveTaskDown={moveTaskDown}
                                    removeTask={removeTask}
                                    indexId={indexId}
                                    getTaskMail={getTaskMail}
                                    showForm={showForm}
                                    columnTitle={columnTitle}
                                    setTaskDetailsPopup={setTaskDetailsPopup}
                                    taskDetailsPopup={taskDetailsPopup}
                                    taskDeadline={item.deadline}
                                    userAssigneeName={item?.userId}
                                    taskColor={item?.color}
                                    taskAssign={taskAssign}
                                    setSearchTerm={setSearchTerm}
                                    searchTerm={searchTerm}
                                    isShiftPressed={isShiftPressed}
                                    moveChildTask={moveChildTask}
                                    columnID={columnID}
                                    handleRemoveChild={handleRemoveChild}
                                    setIsShiftPressed={setIsShiftPressed}
                                    getTaskMailIndbox={getTaskMailIndbox}
                                />
                            </>
                        ))}
                    {showForm && (
                        <div>
                            <div className="task-crat ">
                                <div className="grid-disp">
                                    <div>
                                        <textarea
                                            placeholder="type your task name here"
                                            className=" input-static1 outline-text !h-auto overflow-hidden resize-none focus:border-none !focus:outline-none placeholder:text-[#000]"
                                            rows={2}
                                            value={title}
                                            onChange={(e) => setTaskTitle(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </div>
                                    <div>
                                        <div className="calendar-dropdown calendar-start">
                                            <div className="date-flex">
                                                <h6 className="text-[10.8px] text-[#000]">4:16pm</h6>
                                                <div className="flex">
                                                    {moduleName === 'board' ? (
                                                        <span className="calendar margin-bot">
                                                            {moment().format('MMM DD')}
                                                        </span>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                                <button
                                                    className={`arrow functionality-open `}
                                                    onClick={() => setShowForm(false)}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="10"
                                                        height="10"
                                                        fill="currentColor"
                                                        className="bi bi-chevron-down down-bottom"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="deadline-align">
                                        <div className="calendar-dropdown master-calendar flex">
                                            <div className="flex gap-1 deadline-boldtext">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="10"
                                                    height="10"
                                                    fill="currentColor"
                                                    className="bi bi-flag flag-deadline"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                                                </svg>
                                                <button className="">deadline</button>
                                            </div>

                                            {moduleName === 'board' ? (
                                                <span className="calendar-child">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="10"
                                                        height="10"
                                                        fill="currentColor"
                                                        className="bi bi-flag flag-deadline"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                                                    </svg>
                                                    <span className={`open deadline-btn`}>deadline</span>
                                                </span>
                                            ) : moduleName === 'datePaln' ? (
                                                <span className="calendar-child ideas-plan">
                                                    <span className={`open deadline-btn`}>Ideas</span>
                                                </span>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="svg-circle">
                                            <span className="circle-user ">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-check-lg"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                                </svg>
                                            </span>
                                            <span className="circle-user ">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                    className="bi bi-person"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="task task-curve">
                        <button
                            style={{ opacity: '0.3' }}
                            onClick={() => {
                                setSelectedStatus(columnID);
                                setShowForm(true);
                            }}
                            className="add-task-button"
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Column;
