import api from '../api';
import axios from 'axios';

export default {
    WHATS_APP_EMAIL_SEND: (data) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.REACT_APP_BACKEND_URL}${api.Whatsapp.WHATS_APP_SEND()}`, data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    GET_WHATSAPP_MESSAGE: () => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_BACKEND_URL}${api.Whatsapp.GET_WHATSAPP_MESSAGE()}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    WHATSAPP_GETTASKBYID: (id) => {
        return new Promise((res, rej) => {
            axios
                .get(process.env.REACT_APP_BACKEND_URL + api.Whatsapp.WHATSAPP_getTaskBy(id))
                .then((response) => res(response))
                .catch((err) => rej(err));
        });
    },
};
